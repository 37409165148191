import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listCirculars } from "../graphql/queries";
import { Spinner, Button } from "@blueprintjs/core";
import draftToHtml from "draftjs-to-html";

export function JobDetails() {
  const { id } = useParams<{ id: string }>();

  let history = useHistory();

  const [singleCircular, setSingleCircular] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    (
      API.graphql(
        graphqlOperation(listCirculars, {
          sortDirection: "DESC",
          limit: 1000,
        })
      ) as Promise<any>
    ).then((result) => {
      let CircularList = result.data.listCirculars.items;
      let allCirculars: any = [];

      CircularList.forEach((circular: any) => {
        if (circular.id === id) {
          allCirculars.push({
            id: circular.id,
            position: circular.position,
            deadline: circular.deadline,
            jobDescription: circular.jobDescription,
          });
        }
      });
      setSingleCircular(allCirculars);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Spinner size={Spinner.SIZE_LARGE} />
  ) : (
    <div>
      {singleCircular.map((p: any, i: number) => (
        <>
          <div className="tc pa3 pa4-m pa4-l f3">
            Job details for the position{" "}
            <span className="b ">{p.position}</span>
          </div>

          <div
            className="pa3 pa4-m pa4-l"
            dangerouslySetInnerHTML={{
              __html: draftToHtml(JSON.parse(p.jobDescription)),
            }}
          />
          <div className="tl pa3 pa4-m pa4-l f5 b">
            Deadline: <span className="b "> {p.deadline} </span>
          </div>
          <div className="tc pa3 pa4-m pa4-l f3 pv4">
            <Button
              text="Apply online"
              icon="application"
              intent="success"
              onClick={() => history.push(`/career/${p.id}`)}
            />
          </div>
          <div className="tc pa3 pa4-m pa4-l pv4"></div>
        </>
      ))}
    </div>
  );
}
