import React, { useEffect } from 'react';
import DocImg from '../assets/images/practicedoc.png';
import PatientIcon from '../assets/images/Patient.svg';
import DoctorPanelIcon from '../assets/images/DoctorPanel.svg';
import DoctorsIcon from '../assets/images/doctors.svg';
import SmsServiceMock from '../assets/images/SmsService.svg';
import PrescriptionMock from '../assets/images/Prescription.svg';
import AppointmentListMock from '../assets/images/AppointmentList.svg';
import AnalyticsDevice from '../assets/images/Report-.png';
import waveImage from '../assets/images/wave.svg';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { grey, red } from '@mui/material/colors';

interface SectionTextProps {
  title: string;
  color: any;
  align?: any;
  display?: any;
}

interface ServiceCardProps {
  image: any;
  altText: string;
  title: string;
}

const SectionTitle = (props: SectionTextProps) => {
  const { color, title, align } = props;
  return (
    <Typography
      variant='h1'
      fontWeight='700'
      fontSize={{ xs: '16px', sm: '26px', md: '46px' }}
      fontFamily='HindiSiliguriRegular'
      textTransform='capitalize'
      textAlign={align}
      color={color}
      letterSpacing={1}
    >
      {title}
    </Typography>
  );
};

const SectionSubtitle = (props: SectionTextProps) => {
  const { color, title, align, display } = props;
  return (
    <Typography
      variant='h1'
      fontWeight='700'
      fontSize={{ xs: '12px', sm: '15px' }}
      fontFamily='HindiSiliguriRegular'
      textTransform='capitalize'
      textAlign={align}
      color={color}
      display={display}
      letterSpacing={1}
    >
      {title}
    </Typography>
  );
};

const ServiceOverviewCard = (props: ServiceCardProps) => {
  const { image, altText, title } = props;

  return (
    <Card
      sx={{
        position: 'relative',
        width: { xs: 100, sm: 180 },
        borderRadius: '13px',
      }}
      elevation={3}
    >
      <CardMedia
        component='img'
        image={image}
        alt={altText}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: { xs: 3, md: 5 },
        }}
      />
      <CardContent sx={{ paddingY: 0, paddingBottom: 1, marginTop: -2 }}>
        <Typography
          variant='h6'
          component='h2'
          color='#474848'
          fontFamily='HindiSiliguriRegular'
          fontWeight='700'
          fontSize={{ xs: '10px', sm: '16px' }}
          textAlign='center'
          textTransform='capitalize'
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export function PracticeProOverview() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${waveImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundSize: 'contain',
        }}
        pt={1}
      >
        <HeroSection />
      </Box>
      <ServiceOverviewSection />
      <Features />
    </>
  );
}

export function HeroSection() {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(253, 253, 253, 0.205)',
        backdropFilter: 'blur(4px)',
      }}
    >
      <Grid
        container
        justifyContent='center'
        p={2}
        alignItems='center'
        paddingBottom={{ xs: 7, md: 6 }}
      >
        <Grid item xs={12} sm={8} xl={6}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: 'row', sm: 'column' }}
                justifyContent={{ xs: 'center', sm: 'none' }}
              >
                <Typography
                  variant='h1'
                  fontWeight='700'
                  fontSize={{ xs: '20px', sm: '26px', md: '46px' }}
                  fontFamily='HindiSiliguriRegular'
                  textTransform='capitalize'
                  color={grey[900]}
                  letterSpacing={1}
                >
                  Run your&nbsp;
                </Typography>
                <Stack direction='row' pb={2}>
                  <Typography
                    variant='h1'
                    fontWeight='700'
                    fontSize={{ xs: '20px', sm: '26px', md: '46px' }}
                    fontFamily='HindiSiliguriRegular'
                    textTransform='capitalize'
                    color={red[400]}
                    letterSpacing={1}
                  >
                    Practice&nbsp;
                  </Typography>
                  <Typography
                    variant='h1'
                    fontWeight='700'
                    fontSize={{ xs: '20px', sm: '26px', md: '46px' }}
                    fontFamily='HindiSiliguriRegular'
                    textTransform='capitalize'
                    color={grey[900]}
                    letterSpacing={1}
                  >
                    with ease
                  </Typography>
                </Stack>
              </Stack>
              <Stack pb={2} display={{ xs: 'none', sm: 'block' }}>
                <SectionSubtitle
                  title='The Best Practice / Clinic'
                  color={grey[900]}
                />
                <SectionSubtitle
                  title='Management Software at your finger tips'
                  color={grey[900]}
                />
              </Stack>
              <SectionSubtitle
                title='The Best Practice / Clinic Management Software at your finger tips'
                color={grey[900]}
                align='center'
                display={{ xs: 'block', sm: 'none' }}
              />
              <Button
                sx={{
                  background:
                    'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
                  width: '180px',
                  height: '40px',
                  borderRadius: '10px',
                  display: { xs: 'none', sm: 'block' },
                }}
                onClick={() => window.open(`https://practice.doctorkoi.com/`)}
              >
                <Typography
                  variant='h1'
                  fontWeight='700'
                  fontSize='18px'
                  fontFamily='HindiSiliguriRegular'
                  color='#fff'
                  height='18px'
                >
                  Doctor Login
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack justifyContent='center' alignItems='center'>
                <img src={DocImg} alt='cart' width='100%' />
                <Button
                  sx={{
                    background:
                      'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
                    width: '150px',
                    height: '35px',
                    borderRadius: '10px',
                    display: { xs: 'block', sm: 'none' },
                  }}
                  onClick={() => window.open(`https://practice.doctorkoi.com/`)}
                >
                  <Typography
                    variant='h1'
                    fontWeight='700'
                    fontSize='15px'
                    fontFamily='HindiSiliguriRegular'
                    color='#fff'
                    height='15px'
                  >
                    Doctor Login
                  </Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export function ServiceOverviewSection() {
  return (
    <Box>
      <Grid
        container
        justifyContent='center'
        p={2}
        alignItems='center'
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography
            variant='h1'
            fontWeight='700'
            fontSize='36px'
            fontFamily='HindiSiliguriRegular'
            textTransform='capitalize'
            color={grey[800]}
            letterSpacing={1}
            textAlign='center'
          >
            Our Services
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h1'
            fontWeight='700'
            fontSize={{ xs: '12px', sm: '16px' }}
            fontFamily='HindiSiliguriRegular'
            textTransform='capitalize'
            color={grey[800]}
            letterSpacing={1}
            textAlign='center'
          >
            We Offer The Most Advanced Practice & Clinic Management Solutions
            For Registered Doctors And Healthcare Providers.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent='center' paddingTop={{ xs: 3, sm: 8 }}>
            <Stack
              direction='row'
              justifyContent='center'
              spacing={{ xs: 2, sm: 5, md: 15 }}
            >
              <ServiceOverviewCard
                image={PatientIcon}
                altText='patient'
                title='Retain Patients With Few Clicks & Long Terms'
              />
              <ServiceOverviewCard
                image={DoctorPanelIcon}
                altText='doctor panel'
                title='Your Every Patient’s Journey, Always At Your Fingertips'
              />
              <ServiceOverviewCard
                image={DoctorsIcon}
                altText='doctors'
                title='One Place For Clinic/Practice Needs'
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export function Features() {
  return (
    <Box pb={3}>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={11} md={8} xl={6}>
          <Grid
            container
            justifyContent={{ xs: 'center', sm: 'space-around' }}
            alignItems='center'
            py={2}
            rowSpacing={{ xs: 3, sm: 0 }}
          >
            <Grid item xs={5} sm={6} pl={{ xs: 2, sm: 0 }}>
              <img
                src={AppointmentListMock}
                alt='appointment list'
                width='70%'
              />
            </Grid>
            <Grid item xs={6}>
              <SectionTitle title='Retain Patients' color={grey[900]} />
              <Stack direction='column' pb={2}>
                <SectionTitle title='Through One Click &' color={red[400]} />
                <SectionTitle title='Reduce No Show' color={red[400]} />
              </Stack>
              <Stack pb={2}>
                <SectionSubtitle title='Easy Scheduling' color={grey[900]} />
                <SectionSubtitle title='One-Click' color={grey[900]} />
                <SectionSubtitle
                  title='follow-up Appointment'
                  color={grey[900]}
                />
                <SectionSubtitle title='Advanced Calendar' color={grey[900]} />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <SectionTitle
                title='Most Advanced'
                color={grey[900]}
                align='right'
              />
              <SectionTitle
                title='Prescription Writing'
                color={red[400]}
                align='right'
              />
              <Stack pt={2} direction='row' justifyContent='flex-end'>
                <SectionSubtitle
                  title='27,000+&nbsp;'
                  color={red[400]}
                  align='right'
                />
                <SectionSubtitle
                  title='Drug Database'
                  color={grey[900]}
                  align='right'
                />
              </Stack>
              <Stack pb={2}>
                <SectionSubtitle
                  title='Patient Charting'
                  color={grey[900]}
                  align='right'
                />
                <SectionSubtitle
                  title='Automation & Easy'
                  color={grey[900]}
                  align='right'
                />
                <SectionSubtitle
                  title='Follow-ups'
                  color={grey[900]}
                  align='right'
                />
              </Stack>
            </Grid>
            <Grid item xs={5} sm={6} pl={{ xs: 2, sm: 0 }}>
              <img src={PrescriptionMock} alt='prescription' width='70%' />
            </Grid>

            <Grid item xs={5} sm={6} pl={{ xs: 2, sm: 0 }}>
              <img src={SmsServiceMock} alt='sms' width='70%' />
            </Grid>
            <Grid item xs={6}>
              <Stack direction='column' pb={2}>
                <SectionTitle title='Consultation Ends' color={grey[900]} />
                <SectionTitle title='Care Goes On' color={red[400]} />
              </Stack>
              <Stack pb={2}>
                <SectionSubtitle title='Follow Up SMSes' color={grey[900]} />
                <SectionSubtitle title='Targeted Campaigns' color={grey[900]} />
                <SectionSubtitle
                  title='Advanced Communication'
                  color={grey[900]}
                />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack pb={2}>
                <SectionTitle
                  title='Understand your'
                  color={grey[900]}
                  align='right'
                />
                <Stack direction='row' justifyContent='flex-end'>
                  <SectionTitle
                    title='practice'
                    color={red[400]}
                    align='right'
                  />
                  <SectionTitle
                    title=', your way'
                    color={grey[900]}
                    align='right'
                  />
                </Stack>
              </Stack>
              <Stack pb={2}>
                <SectionSubtitle
                  title='Real Time Analysis, Accounts & Appointment Reporting'
                  color={grey[900]}
                  align='right'
                />
                <SectionSubtitle
                  title='Trends & Future Projections'
                  color={grey[900]}
                  align='right'
                />
              </Stack>
            </Grid>
            <Grid item xs={5} sm={6} pl={{ xs: 2, sm: 0 }}>
              <img src={AnalyticsDevice} alt='analytics' width='70%' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
