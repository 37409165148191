import React from 'react';
// import { Checkbox } from '@blueprintjs/core';
// import { useHistory } from 'react-router-dom';
// import { Store } from '../Store';
// import { isEqual } from 'lodash';


export function BkashPolicy() {

    return (
        <>
            <div className="dt  pl3-ns flex flex-row-ns flex-column-reverse">
                <div className="fl w-100 w-50-ns bg-white-80 tc db pa2 dtc-ns v-mid-ns">
                    <MobileTheke />
                </div>
                <div className="fl w-100 w-50-ns bg-white-80 tc db pa2 dtc-ns v-mid-ns">
                    <TextMobileApp />
                </div>
            </div>
        </>
    )
}

function MobileTheke() {
    return (
        <div className="w-80-ns w-100  tc" style={{ fontFamily: "HindiSiliguriRegular" }}>
            <h1 className="f3 b tc blue"> <span style={{ color: "#DD2C6A", fontFamily: "GothamBold" }}>bKash</span> মোবাইল ব্যাংকিং পদ্ধতিতে ডক্টর ফি পরিশোধ পদ্ধতিঃ</h1>
            <p className="tl f5">১। যে সকল মোবাইলে bKash মোবাইল এ্যাকাউন্ট রয়েছে, সেই মোবাইল থেকে <span className="b f4"style={{ fontFamily: "GothamBlack" }}>*247#</span>  ডায়ালকরে bKash মোবাইল ব্যাংক মেনুতে যান</p>
            <p className="tl f5">২। <span style={{ fontFamily: "GothamBlack" }}>3 (Payment)</span> চেপে পেমেন্ট সিলেক্ট করুন। </p>
            <p className="tl f5">৩। <span style={{ color: "#DD2C6A", fontFamily: "GothamBold" }}>bKash</span> <span className="b f4" style={{ color: "#E93541", fontFamily: "GothamBlack" }}>01701079966</span> নাম্বার টাইপ করুন। </p>
            <p className="tl f5">৪। ডক্টর ফি বাবদ নির্ধারিত টাকার পরিমান লিখুন  সেন্ড বাটন এ ক্লিক করুন। </p>
            <p className="tl f5">৫। <span style={{ fontFamily: "GothamBlack" }}>Reference Number</span> এর ঘরে আপনার মোবাইল নম্বরটি লিখুন এবং সেন্ড করুন। (রেফারেন্সে মোবাইল নম্বর না লিখলে এই পেমেন্ট গ্রহনযোগ্য বলে বিবেচিত হবে না) </p>
            <p className="tl f5">৬। <span className="b"style={{ fontFamily: "GothamBlack" }}>Counter Number 1</span> দিন এবং “send” বাটনে ট্যাপ করুন।</p>
            <p className="tl f5">৭। আপনার বিকাশ একাউন্ট এর চার ডিজিটের PIN নম্বর টাইপ করুন। </p>
            <p className="tl f5">৮। <span style={{ fontFamily: "GothamBlack" }}>OK/Send</span> বাটন চাপুন। </p>
            <p className="tl f5">৯। লেনদেনটি সফল হলে আপনার কাছে বিকাশ থেকে একটি মেসেজ আসবে। </p>
            <p className="tl f5">১০। আপনি <span className="b" style={{ color: "#DD2C6A", fontFamily: "GothamBold" }}>bKash</span> থেকে একটি <span className="b">TrxID</span> পাবেন সেটি ভবিষ্যৎ ব্যাবহার এর জন্য সংরক্ষণ করুন। </p>
        </div>
    )
}

function TextMobileApp() {
    return (
            <div className="w-80-ns w-100  tc" style={{ fontFamily: "HindiSiliguriRegular" }}>
                <h1 className="f3 b tc blue"> <span style={{ color: "#DD2C6A", fontFamily: "GothamBlack" }}>bKash</span> মোবাইল অ্যাপ থেকে ফি পরিশোধ পদ্ধতিঃ</h1>
                <p className="tl f5">১। আপনার ফোনের বিকাশ অ্যাপ এ লগ ইন করুন। </p>
                <p className="tl f5">২। হোম স্ক্রিন থেকে <span className="b">“Make Payment”</span> অপশনটিতে ট্যাপ করুন। </p>
                <p className="tl f5">৩। পেমেন্ট করার জন্য <span className="b f4" style={{ color: "#E93541", fontFamily: "GothamBlack" }} >01701079966</span> এই নম্বরটি টাইপ করুন। </p>
                <p className="tl f5">৪। ডক্টর ফি বাবদ নির্ধারিত টাকার পরিমান লিখুন  সেন্ড বাটন এ ক্লিক করুন। </p>
                <p className="tl f5">৫। রেফারেন্স <span style={{ fontFamily: "GothamBlack" }}>(Reference)</span> এর ঘরে আপনার বা রোগীর মোবাইল্ নম্বরটি লিখুন। (রেফারেন্সে মোবাইল নম্বর না লিখলে এই পেমেন্ট গ্রহনযোগ্য বলে বিবেচিত হবে না) </p>
                <p className="tl f5">৬। আপনার বিকাশ পিন নম্বরটি টাইপ করুন এবং পরবর্তী ধাপে যান। </p>
                <p className="tl f5">৭। লেনদেনটি সম্পূর্ণ করার জন্য অ্যা পে দেখানো নির্ধারিত জায়গাটি ট্যাপ করে ধরে থাকুন।</p>
                <p className="tl f5">৮। পরবর্তী স্ক্রিনে আপনার পেমেন্টটি সফল হয়েছে কিনা দেখুন।</p>
                <p className="tl f5"> <span className="b f3">***</span> বিঃদ্রঃ রেফারেন্সের ঘরে যদি আপনার মোবাইল নাম্বার না লিখে থাকেন, তাহলে সেই পেমেন্টটি গ্রহনযোগ্য বলে বিবেচিত হবে না।  <span className="b f3">***</span> </p>
            </div>
    )
}