import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import a6 from '../resources/a6.png';
import { API, graphqlOperation } from 'aws-amplify';
import { updateUser } from '../graphql/mutations';
import { Button } from '@blueprintjs/core';
import axios from 'axios';

import { validatePhoneNumber } from '@doctorkoi/utils';
import { Store } from '../Store';
import { AppToaster } from '../AppointmentShowPS/ShowList';

const sendSms = async (phoneNumber: any, code: string) => {
  let body = {
    phoneNumber,
    smsArr: `${code} is your verification code. Do not share it.`,
    request_type: 'OTP',
  };
  await axios
    .post(
      `https://umn6wmnfq6.execute-api.us-east-1.amazonaws.com/prod/send-sms`,
      body
    )
    .then((res: any) => {
      console.log(res);
    });
};

export function SignIn() {
  const globalState = useContext(Store);
  let history = useHistory();
  const [mobile, setMobile] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const signin = async (phone: string, code: string) => {
    try {
      const result: any = await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: phone,
            code: code,
          },
        })
      );

      return result.data.updateUser;
    } catch (e) {
      throw new Error(`${JSON.stringify(e)}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {globalState.state.signedIn ? (
        history.push('/')
      ) : (
        <div
          style={{
            backgroundImage: `url(${a6})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
          }}
        >
          <div
            className='flex justify-center center-l center-m tc w-90-ns w-80'
            style={{ marginLeft: '40px', marginRight: '40px' }}
          >
            <div className='br2 pb3 flex-column shadow-1 mt5-ns mt4 mt0 bg-white w-90 w-30-l w-40-m'>
              <h1
                className='tc f3'
                style={{ fontFamily: 'GothamBold', color: '#3b3b3b' }}
              >
                Sign In
              </h1>
              <form
                className='pa2'
                onSubmit={(e: any) => {
                  e.preventDefault();
                }}
              >
                <div className='flex flex-column mb3 mr1 w-100 w-100-ns'>
                  <label className='pl2 mb2 f5 tl'> Mobile Number</label>
                  <input
                    className='pl3 br3 pa2'
                    type='text'
                    placeholder='Mobile Number'
                    name='mobile'
                    value={mobile}
                    onChange={(e) => {
                      setLoading(false);
                      setMobile(e.target.value);
                    }}
                  />
                </div>
                <div className=' tc'>
                  <Button
                    className='  pointer w-80 w-25-l w-25-m f4 f4-ns no-underline
                pa3 br3 ba border-box b'
                    disabled={validatePhoneNumber(mobile) ? false : true}
                    text={'Sign In'}
                    style={{
                      width: '50%',
                      color: 'white',
                      borderRadius: '8px',
                      fontSize: '1.25rem',
                      fontWeight: 700,
                    }}
                    loading={loading}
                    large={true}
                    type='submit'
                    intent={'danger'}
                    onClick={(e: any) => {
                      localStorage.clear();
                      e.preventDefault();
                      setLoading(true);
                      let code = Math.floor(
                        1000 + Math.random() * 9000
                      ).toString();
                      signin(mobile, code)
                        .then((result) => {
                          sendSms(mobile, code).then((sms) => {
                            setLoading(false);
                            history.push(`/verification/${mobile}`);
                          });
                        })
                        .catch((err) => {
                          setLoading(false);
                          AppToaster.show({
                            message: `Sign Up Required.`,
                            icon: 'cross',
                            intent: 'danger',
                            timeout: 10000,
                          });
                          history.push(`/signup`);
                        });
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            className='flex justify-center center-ns tc w-90-ns w-80 mt4-ns mt4 pb7'
            style={{ marginLeft: '40px', marginRight: '40px' }}
          >
            <p className='f5  tc b'>
              {' '}
              If you haven't registered yet, please
              <Button
                minimal={true}
                onClick={() => history.push(`/signup`)}
                intent={'primary'}
                className='f5 f3-ns b'
                text='Click here'
              />{' '}
              to register
            </p>
          </div>
        </div>
      )}
    </>
  );
}
