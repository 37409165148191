import { Alert } from '@blueprintjs/core';
import React, { useState } from 'react';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';
import digitalrxL from '../resources/digitalrxL.jpg';
import Logo5 from '../resources/Logo5.png';
import { useCurrentDimensions } from '../useCurrentDimensions';
import './DigitalRxDetails.css';
import { DigitalRxDetailsMV } from './DigitalRxDetailsMobileView';

export function DigitalRxDetails() {
  const { height, width } = useCurrentDimensions();
  const isMobileView = height < 900 && width < 500;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return isMobileView ? (
    <DigitalRxDetailsMV />
  ) : (
    <>
      <MetaTags>
        <meta
          name='description'
          content='“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, the best prescription writing software of the country that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally or the online consultation. 
                    DoctorKoi also provides the best online consultation services in Bangladesh for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. 
                    Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception RX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. 
                    During the pandemic Doctor Koi launched its online TELEMEDICINE consultation service through which they have served more than 12000 patients online. This product of online telemedicine has been one of the best online telemedcine consultation of bangladesh. Doctors are serving online telemedicine thruogh doctorkoi'
        />
        <meta property='og:title' content='DoctorKoi' />
        <meta
          property='og:image'
          content='https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg'
        />
      </MetaTags>
      <div className='center-l center-m'>
        <div
          className='img_bg cover center-l center-m '
          style={{
            backgroundImage: `url(${digitalrxL})`,
          }}
        >
          <div
            className=' dt w-100'
          >
            <div
              className='dtc v-mid tc white ph3 ph4-l'
              style={{ opacity: '1.0' }}
            >
              <div className='f6 mt4-ns f2-m tc ' style={{ fontWeight: 600 }}>
                <div className='dg_logo center-l center-m v-mid'>
                  <img
                    src={Logo5}
                    alt="Digital Rx Smart Prescription: Country's no 1 prescription writing software, best prescription writing software of bangladesh which is also helping in the telemedicine sector, another FLAGSHIP Product of Doctorkoi.com. Doctorkoi has been serving the best online telemedicine service in bangladesh."
                    className='classname mw4-ns mw3'
                  />
                </div>
                <div className='center-l center-m tc'>
                  <p className='f2-ns tc ttu v-mid'>Digital Rx</p>
                  <p className='f5-ns tc ttc v-mid mb2-ns mb2'>by doctorkoi</p>
                  <p className='f5-ns tc ttc v-mid mb3-ns mb3'>
                    THE MOST POWERFUL PRESCRIPTION WRITING SOFTWARE WITH HIGHLY
                    CUSTOMIZABLE AND AUTOMATION FEATURES.
                  </p>
                </div>
                <div className='center-l center-m tc w-70-l w-80-m lh w-80'>
                  <p className='f5-ns tc v-mid center-l center-m'>
                    Digital Rx by doctorkoiSMART is a Prescription Writing
                    Software module that enables doctors to generate a
                    prescription within a few clicks. The software has an
                    inventory of 28,000+ drugs with brand and generic details
                    designed in a way that helps doctors to find the drug and
                    prescribe easily. The Software enables the doctors smoothly
                    write patients information and history with diagnosis, print
                    them digitally and record for later purpose.
                  </p>
                </div>
                <div className='center-l center-m tc w-100-ns mt4-ns mt2 mb5-ns'>
                  <p className='w-100-ns tc'>
                    <span className='center-l center-m tc b f4-ns tc ttc v-mid mr3-ns white'>
                      27,000+ MEDICINE DATABASE
                    </span>{' '}
                    <span className='white b'>{' - '}</span>
                    <span className='center-l center-m tc b f4-ns tc ttc v-mid ml3-ns mr3-ns white'>
                      SUGGESTION & PRESETS
                    </span>{' '}
                    <span className='white b'>{' - '}</span>
                    <span className='center-l center-m tc b f4-ns tc ttc v-mid ml3-ns mr3-ns white'>
                      PATIENT HISTORY VIA CHARTING
                    </span>
                  </p>
                  <p className='w-100-ns tc'>
                    <span className='center-l center-m tc b f4-ns tc ttc v-mid ml3-ns mr3-ns white'>
                      EASY FOLLOW UP{' '}
                    </span>{' '}
                    <span className='white b'>{' - '}</span>
                    <span className='center-l center-m tc b f4-ns tc ttc v-mid ml3-ns white'>
                      MULTIPLE DEVICE ACCESSIBILITY
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        confirmButtonText='Okay'
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(!isOpen);
        }}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
      >
        <p className='f4-ns f4 b black'>
          Please contact <strong>+8809666777711 / +8809666788988 </strong>
          for product purchase or service..
        </p>
      </Alert>
    </>
  );
}
