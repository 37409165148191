import React from 'react';
import { Divider } from '@blueprintjs/core';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';

export function RefundPolicy() {
    return (
        <>
            <MetaTags>
                <meta name="description" content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, a prescription writing software that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally. The company also provides online consultation services for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception DRX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online." />
                <meta property="og:title" content="DoctorKoi - Refund Policy" />
                <meta property="og:image" content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg" />
            </MetaTags>
            <div className=" w-90-ns mt2  bg-white-90 pa4">
                <div className="refund_policy mt3-ns mt2  w-90-ns w-90 mb4">
                    <div className="heading_  tc">
                        <h1 className="f3 b" style={{
                            color: "#356CAD"
                        }}>
                            Refund Policy
                            </h1>
                    </div>
                    <Divider />
                    <div className="para_ w-100-ns w-90  mb4">
                        <p className="f5 black">
                            1.	In case of cancelation of appointment patients can place a request for refund.
                        </p>
                        <p className="f5 black">
                            2.	Refund will be processed and made within 3 to 5 working days.
                        </p>
                        <p className="f5 black">
                            3.	Patient will get full refund of the doctor fees only.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}