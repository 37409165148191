import React from 'react';
import './loader.css';
const logo = require('./Logo4.png');

interface ILoaderProps {
    loading: boolean;
}

export default function Loader(Props: ILoaderProps) {
    const { loading } = Props;
    
  return (
    <div className="logo-container">
      {loading && ( 
        <div className="loading-container">
          <div className="loading" style ={{}}></div>
          <img src={logo} alt="logo" className="logo" />
        </div>
      )}
    </div>
  )
}
