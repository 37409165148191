import { Button, Position, Toaster } from '@blueprintjs/core';
import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { updateUser } from '../graphql/mutations';
import { getUser } from '../graphql/queries';
import { Store } from '../Store';
import a6 from '../resources/a6.png';

export const AppToaster = Toaster.create({
  position: Position.TOP,
  className: 'mt5-ns mt5',
});

export function VerifyAccount() {
  const globalState = useContext(Store);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const verifyCode = async () => {
    try {
      const result: any = await API.graphql(
        graphqlOperation(getUser, {
          id: id,
        })
      );

      return result.data.getUser;
    } catch (e) {
      throw new Error(`${JSON.stringify(e)}`);
    }
  };

  const updatedVerificationStatus = async () => {
    try {
      const result: any = await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: id,
            verified: 'TRUE',
            code: null,
          },
        })
      );

      return result.data.updateUser;
    } catch (e) {
      throw new Error(`${JSON.stringify(e)}`);
    }
  };

  return (
    <div
      className='pb7'
      style={{
        backgroundImage: `url(${a6})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
      }}
    >
      <div
        className='flex justify-center'
        style={{ marginLeft: '40px', marginRight: '40px' }}
      >
        <div className=' bg-white pb3 flex-column shadow-1 mt5-ns mt4 w-80 w-30-l w-40-m'>
          <h1
            className='tc f3 '
            style={{ fontFamily: 'GothamBold', color: '#3b3b3b' }}
          >
            Verify Your Account
          </h1>
          <form
            className='pa2'
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            <div className='flex flex-column mb3 mr1 w-100 w-100-ns'>
              <input
                className=' br3 pa2'
                style={{
                  letterSpacing: 'normal',
                  lineHeight: 'unset',
                  fontSize: 15,
                }}
                type={'text'}
                placeholder='Enter your code'
                name='code'
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className='center tc'>
              <Button
                className='center w-90 w-100-ns f4 f4-ns no-underline bg-lightest-blue black bg-animate 
                 inline-flex items-center pa3 br3 ba border-box b mt1 '
                text={'Confirm'}
                disabled={code.length !== 4 ? true : false}
                style={{
                  width: '50%',
                  // backgroundColor: "#cdecfe",
                  color: 'white',
                  // height: "55px",
                  borderRadius: '8px',
                  fontSize: '1.25rem',
                  fontWeight: 700,
                }}
                loading={loading}
                // fill={true}
                large={true}
                type='submit'
                intent={'danger'}
                onClick={(e: any) => {
                  e.preventDefault();
                  setLoading(true);
                  verifyCode().then((result) => {
                    setLoading(false);
                    if (result.code === code) {
                      updatedVerificationStatus().then((verified) => {
                        const { dispatch } = globalState;
                        dispatch({ type: 'SIGN_IN' });
                        localStorage.setItem('signedIn', 'true');
                        localStorage.setItem('name', verified.name);
                        localStorage.setItem('phone', verified.id);
                        const doctorId = localStorage.getItem('doctor');
                        if (isEmpty(doctorId) === false) {
                          history.push(`/appointment/${doctorId}`);
                        } else {
                          history.push(`/`);
                          window.location.reload();
                        }
                      });
                    } else {
                      setLoading(false);
                      AppToaster.show({
                        message: `Wrong Code, Try Again`,
                        // icon: 'tick-circle',
                        intent: 'danger',
                        timeout: 10000,
                      });
                    }
                  });
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
