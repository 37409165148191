import React from 'react';
import { Divider } from '@blueprintjs/core';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';

export function TermsCondition() {
    return (
        <>
        <MetaTags>
            <meta name="description" content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, a prescription writing software that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally. The company also provides online consultation services for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception DRX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online." />
            <meta property="og:title" content="DoctorKoi" />
            <meta property="og:image" content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg" />
        </MetaTags>
            <div className=" w-90-ns mt2  bg-white-90 pa4" >
                <div className="heading_  tc">
                    <h1 className="f3 b" style={{
                        color: "#356CAD"
                    }}>
                        Terms & Conditions of Doctorkoi.com
                        
                    </h1>                    
                </div>
                <Divider />
                <div className="para_ w-90-ns w-90  mb4">
                    <p className="f5 black">
                        1.	One person can book only one appointment on the same day with the same mobile number
                    </p>
                    <p className="f5 black">
                        2.	Appointment timings are subject to doctor’s availability, in case of cancelation we will reschedule the appointment on the next available time slot.
                    </p>
                    <p className="f5 black">
                        3.	Patient can place request for reschedule his or her appointment but in order to that they must let us know at least 3 hour before the scheduled time. No extra charges will occur for that.
                    </p>
                    <p className="f5 black">
                        4.	Patient must write their mobile number in the reference section while making the payment using bKash. Any payment without a valid reference will not be accepted.
                    </p>
                    <p className="f5 black">
                        5.	We will confirm the appointment within 24 hours after the payment verification.
                    </p>
                    <p className="f5 black">
                        6.	Patient must download and install the “ZOOM” application from Google Play Store or Apple Store prior to his or her visitation.
                    </p>
                </div>
                <Divider />                
            </div>
        </>
    )
}