import { IconName, Intent, Toaster } from "@blueprintjs/core";
import { API, graphqlOperation } from "aws-amplify";
import { assign, get, isEmpty, isEqual } from "lodash";
import * as uuid from "uuid";
// eslint-disable-next-line
const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailValidation = ( email: string ) => {
    if (isEmpty(email) || !EmailRegex.test(email)) {
        return false
    } else {
        return true
    }
}

export function randomUUID(): string {
    return uuid.v4();
}
export function showToast(msg: string, icon: IconName, intent: Intent): void {
    Toaster.create({
      autoFocus: true,
      position: 'top',
    }).show({
      icon: icon,
      message: msg,
      intent: intent,
      timeout: 5000,
    });
  }
  export const toBasepdf = (file:any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let pdf:any = new Image();
      pdf.src = reader.result;
      pdf.onload = () => {
        resolve({
          result:reader.result,
        })
      }
    };
    reader.onerror = error => reject(error);
  });

  export const toBase64 = (file:any) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({
        result:reader.result,
      });
      reader.onerror = error => reject(error);
    });
  
  export const fileValidation = (file:any) => {
    if ( 
        ((isEqual(file.type, 'application/pdf')) || 
        (isEqual(file.type, 'application/msword')))
        && file.size <= 2*1024*1024
      ) {
      return (undefined);
    } else{
      return (`File must be in pdf or docx or doc format
      File size must be less than 2MB`);
    }
  };
  
export async function listItems(query: string, prefix: string, variables?: object) {
    const items: object[] = [];
    let result: any = await API.graphql(graphqlOperation(query, variables));
    let nextToken = get(result.data!, `${prefix}.nextToken`, null);
    items.push(...get(result.data!, `${prefix}.items`, []));
    while (nextToken !== null) {
      result = await API.graphql(graphqlOperation(query,
        assign({ nextToken }, variables ? variables : {})));
        nextToken = get(result.data!, `${prefix}.nextToken`, null);
        items.push(...get(result.data!, `${prefix}.items`, []));
    }
    return items;
  }

export function appointmentSerialID(): string {
    let now = new Date();

    let month = formatNumber(now.getMonth()+1),
        date = formatNumber(now.getDate()),
            hour = formatNumber(now.getHours()),
                minute = formatNumber(now.getMinutes()),
                    second = formatNumber(now.getSeconds());

    let serial_id:string = `${month}${date}${hour}${minute}${second}`;
    return serial_id;
}

function formatNumber(value:number) {
    return value < 10 ? `0${value}` : value;
}