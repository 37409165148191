import React from 'react';
import digitalrxMbg from '../resources/digitalrxMbg.jpg';
import Logo5 from '../resources/Logo5.png';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';

export function DigitalRxDetailsMV() {

  return (
    <>
      <MetaTags>
        <meta
          name='description'
          content='“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, the best prescription writing software of the country that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally or the online consultation. 
                    DoctorKoi also provides the best online consultation services in Bangladesh for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. 
                    Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception RX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. 
                    During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online.'
        />
        <meta property='og:title' content='DoctorKoi' />
        <meta
          property='og:image'
          content='https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg'
        />
      </MetaTags>
      <header className='_main'>
        <div
          className='cover bg-left bg-center-l'
          style={{
            backgroundImage: `url(${digitalrxMbg})`,
          }}
        >
          <div
            className='w-100'
          >
            <div className='tc-l mt1 mt5-m mt6-l ph3'>
              <div className='f2 f1-l fw2 white-90 mb0 lh-title tc'>
                <img
                  src={Logo5}
                  alt="DigitalRx Smart Prescription: Country's no 1 prescription writing software"
                  className='mw3-ns mw3'
                />
              </div>
              <div className='f3 f3-ns b white mt3 mt3-ns tc ttu'>
                DIGITAL RX
                <p className='f5 f5-ns b white mt3 tc ttu mb4'>by doctorkoi</p>
              </div>

              <p
                className='f5 f5-ns white mt3 w-100 tc mb4 pa4'
                style={{ fontFamily: 'GothamBook' }}
              >
                Digital Rx by doctorkoiSMART is a Prescription Writing Software
                module that enables doctors to generate a prescription within a
                few clicks. The software has an inventory of 28,000+ drugs with
                brand and generic details designed in a way that helps doctors
                to find the drug and prescribe easily. The Software enables the
                doctors smoothly write patients information and history with
                diagnosis, print them digitally and record for later purpose.
              </p>
            </div>

            <div
              className=' ba b--white w-100  flex-column 
                            justify-center white bw2-ns bw2  tc'
            >
              <p className='f5-ns f5 pa3-ns pa3 bb white ttu b'>
                27,000+ MEDICINE DATABASE
              </p>
              <p className='f5-ns f5 pa3-ns pa3 bb white ttu b'>
                SUGGESTION & PRESETS
              </p>
              <p className='f5-ns f5 pa3-ns pa3 bb white ttu b'>
                PATIENT HISTORY VIA CHARTING
              </p>
              <p className='f5-ns f5 pa3-ns pa3 bb white ttu b'>
                patient follow-up
              </p>
              <p className='f5-ns f5 pa3-ns pa3 bb white ttu b'>
                prescription setup
              </p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
