/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const s3PutObject = /* GraphQL */ `
  mutation S3PutObject($input: S3PutObjectInput) {
    s3PutObject(input: $input) {
      result
    }
  }
`;
export const updateApplicantCv = /* GraphQL */ `
  mutation UpdateApplicantCv($input: UpdateApplicantCVInput) {
    updateApplicantCV(input: $input) {
      info
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      serialID
      patientId
      digitalRxPatientId
      patientName
      doctorId
      doctorName
      doctorUsername
      dateTime
      status
      info
      createdFrom
      createdAt
      updatedAt
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      serialID
      patientId
      digitalRxPatientId
      patientName
      doctorId
      doctorName
      doctorUsername
      dateTime
      status
      info
      createdFrom
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      serialID
      patientId
      digitalRxPatientId
      patientName
      doctorId
      doctorName
      doctorUsername
      dateTime
      status
      info
      createdFrom
      createdAt
      updatedAt
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      id
      name
      speciality
      score
      status
      info
      file
      payment {
        appointmentId
        amount
        date
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      id
      name
      speciality
      score
      status
      info
      file
      payment {
        appointmentId
        amount
        date
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor(
    $input: DeleteDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    deleteDoctor(input: $input, condition: $condition) {
      id
      name
      speciality
      score
      status
      info
      file
      payment {
        appointmentId
        amount
        date
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCircular = /* GraphQL */ `
  mutation CreateCircular(
    $input: CreateCircularInput!
    $condition: ModelCircularConditionInput
  ) {
    createCircular(input: $input, condition: $condition) {
      id
      position
      deadline
      status
      jobDescription
      createdAt
      updatedAt
    }
  }
`;
export const updateCircular = /* GraphQL */ `
  mutation UpdateCircular(
    $input: UpdateCircularInput!
    $condition: ModelCircularConditionInput
  ) {
    updateCircular(input: $input, condition: $condition) {
      id
      position
      deadline
      status
      jobDescription
      createdAt
      updatedAt
    }
  }
`;
export const deleteCircular = /* GraphQL */ `
  mutation DeleteCircular(
    $input: DeleteCircularInput!
    $condition: ModelCircularConditionInput
  ) {
    deleteCircular(input: $input, condition: $condition) {
      id
      position
      deadline
      status
      jobDescription
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      code
      verified
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      code
      verified
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      code
      verified
      createdAt
      updatedAt
    }
  }
`;
export const createRegistrationForm = /* GraphQL */ `
  mutation CreateRegistrationForm(
    $input: CreateRegistrationFormInput!
    $condition: ModelRegistrationFormConditionInput
  ) {
    createRegistrationForm(input: $input, condition: $condition) {
      id
      name
      phone
      bmdc
      info
      createdAt
      updatedAt
    }
  }
`;
export const updateRegistrationForm = /* GraphQL */ `
  mutation UpdateRegistrationForm(
    $input: UpdateRegistrationFormInput!
    $condition: ModelRegistrationFormConditionInput
  ) {
    updateRegistrationForm(input: $input, condition: $condition) {
      id
      name
      phone
      bmdc
      info
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegistrationForm = /* GraphQL */ `
  mutation DeleteRegistrationForm(
    $input: DeleteRegistrationFormInput!
    $condition: ModelRegistrationFormConditionInput
  ) {
    deleteRegistrationForm(input: $input, condition: $condition) {
      id
      name
      phone
      bmdc
      info
      createdAt
      updatedAt
    }
  }
`;
export const createCvForm = /* GraphQL */ `
  mutation CreateCvForm(
    $input: CreateCVFormInput!
    $condition: ModelCVFormConditionInput
  ) {
    createCVForm(input: $input, condition: $condition) {
      id
      name
      phone
      email
      position
      info
      createdAt
      updatedAt
    }
  }
`;
export const updateCvForm = /* GraphQL */ `
  mutation UpdateCvForm(
    $input: UpdateCVFormInput!
    $condition: ModelCVFormConditionInput
  ) {
    updateCVForm(input: $input, condition: $condition) {
      id
      name
      phone
      email
      position
      info
      createdAt
      updatedAt
    }
  }
`;
export const deleteCvForm = /* GraphQL */ `
  mutation DeleteCvForm(
    $input: DeleteCVFormInput!
    $condition: ModelCVFormConditionInput
  ) {
    deleteCVForm(input: $input, condition: $condition) {
      id
      name
      phone
      email
      position
      info
      createdAt
      updatedAt
    }
  }
`;
export const createPublicQuery = /* GraphQL */ `
  mutation CreatePublicQuery(
    $input: CreatePublicQueryInput!
    $condition: ModelPublicQueryConditionInput
  ) {
    createPublicQuery(input: $input, condition: $condition) {
      id
      name
      phone
      email
      message
      info
      createdAt
      updatedAt
    }
  }
`;
export const updatePublicQuery = /* GraphQL */ `
  mutation UpdatePublicQuery(
    $input: UpdatePublicQueryInput!
    $condition: ModelPublicQueryConditionInput
  ) {
    updatePublicQuery(input: $input, condition: $condition) {
      id
      name
      phone
      email
      message
      info
      createdAt
      updatedAt
    }
  }
`;
export const deletePublicQuery = /* GraphQL */ `
  mutation DeletePublicQuery(
    $input: DeletePublicQueryInput!
    $condition: ModelPublicQueryConditionInput
  ) {
    deletePublicQuery(input: $input, condition: $condition) {
      id
      name
      phone
      email
      message
      info
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      transactionId
      appointmentId
      status
      info
      createdAt
      updatedAt
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      transactionId
      appointmentId
      status
      info
      createdAt
      updatedAt
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      transactionId
      appointmentId
      status
      info
      createdAt
      updatedAt
    }
  }
`;
