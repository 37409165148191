import React from 'react';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';

export function AboutUs() {
  return (
    <>
      <MetaTags>
        <meta
          name="description"
          content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, a prescription writing software that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally. The company also provides online consultation services for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception DRX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online."
        />
        <meta property="og:title" content="DoctorKoi" />
        <meta
          property="og:image"
          content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg"
        />
      </MetaTags>
      <div className="w-100 pa4-ns pa3">
        <div
          className="heading_ mb2"
          style={{
            background: '#E8545C',
            padding: '10px',
            borderRadius: '8px',
            marginTop: -20,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <h2
            className="f2-ns f4 b"
            style={{
              color: 'white',
              textAlign: 'center',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            }}
          >
            <span style={{color: 'inherit'}}>
              About Renssoft Solutions Ltd.
            </span>
          </h2>
        </div>

        <div className="about-us f4-ns f6 black para_">
          <div className="section">
            <h2 className="section-title pt2">Our Vision and Mission</h2>
            <p className="section-text tj pv2">
              Established in 2017, <b>Renssoft Solutions Ltd.</b> is a visionary
              healthcare startup dedicated to reshaping the healthcare landscape
              in Bangladesh through digital innovation. Our mission is to lead
              the digital transformation of healthcare communication, bridging
              the gap between doctors and patients with cutting-edge technology.
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">
              Digital RX: Revolutionizing Healthcare
            </h2>
            <p className="section-text tj pv2">
              At the heart of our journey is our flagship product,{' '}
              <b>Digital RX</b>, an advanced prescription-writing software
              designed to empower healthcare professionals. It streamlines the
              process of creating prescriptions while helping practitioners
              efficiently manage their medical practices in a digital format.
              With Digital RX, healthcare providers can deliver higher quality
              care with ease.
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">A Partner for Progress</h2>
            <p className="section-text tj pv2">
              Collaboration is key to achieving our mission. We've proudly
              partnered with industry leaders, including pharmaceutical giants
              such as ESKAYEF, ACI Healthcare, and Incepta, to facilitate our
              transformative journey. These partnerships enable us to offer
              innovative solutions that benefit the entire healthcare ecosystem.
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">Connecting Patients and Doctors</h2>
            <p className="section-text tj pv2">
              We're committed to providing accessible and convenient healthcare
              solutions. Our online consultation platform,{' '}
              <span className="blue b">Doctorkoi.com</span>, serves as a bridge
              between patients and some of the country's top medical experts.
              Patients can easily book appointments and access expert medical
              advice, all from the comfort of their homes.
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">During Challenging Times</h2>
            <p className="section-text tj pv2">
              In response to the challenges posed by the global pandemic, we
              introduced online consultation services, extending our reach to
              serve over 12,000 patients from across the country when many
              hospitals faced restrictions. We're committed to supporting our
              community in times of need.
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">
              Join Us in the Healthcare Revolution
            </h2>
            <p className="section-text tj pv2">
              Patients can easily book appointments through our helpline at
              09666788988 / 09666777711 (10am - 10pm) or by reaching out to our
              official Facebook page:{' '}
              <a
                href="https://www.facebook.com/DoctorKoiOfficial"
                className="blue b"
              >
                DoctorKoiOfficial
              </a>
              . We invite you to join us on this mission to enhance and digitize
              healthcare in Bangladesh. Together, we're shaping a healthier and
              more connected future.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
