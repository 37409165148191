import { isEmpty } from "lodash";

const EmailRegex = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/;
const UserNameRegex = /^[a-zA-Z0-9_]*$/;
const PhoneRegex = /^[0][1-9]\d{9}$|[1-9]\d{11}$/;
const PasswordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
const NumberRegex = /^\d+$/;

const stringValidation = ( name: string | undefined ) => {
  if (isEmpty(name)) {
    return false
  } else {
    return true
  }
}

const UserNameValidation = ( userName: string ) => {
  if (isEmpty(userName) || !UserNameRegex.test(userName)) {
    return false
  } else {
    return true
  }
}

const emailValidation = ( email: string ) => {
  if (isEmpty(email) || !EmailRegex.test(email)) {
    return false
  } else {
    return true
  }
}

const passwordValidation = ( password: string ) => {
  if (isEmpty(password) || !PasswordRegex.test(password)) {
    return false
  } else {
    return true
  }
}

const phoneValidation = ( phone: string ) => {
  if (isEmpty(phone) || !PhoneRegex.test(phone)) {
    return false
  } else {
    return true
  }
}

const numberValidation = ( number: string ) => {
  if (isEmpty(number) || !NumberRegex.test(number)) {
    return false
  } else {
    return true
  }
}

export {
  stringValidation,
  UserNameValidation,
  emailValidation,
  passwordValidation,
  phoneValidation,
  numberValidation
}