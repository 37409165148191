import { GraphQLResult } from '@aws-amplify/api';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CallIcon from '@mui/icons-material/Call';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import PushPinIcon from '@mui/icons-material/PushPin';
import SchoolIcon from '@mui/icons-material/School';
import VerifiedIcon from '@mui/icons-material/Verified';
import VideocamIcon from '@mui/icons-material/Videocam';
import WorkIcon from '@mui/icons-material/Work';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Stack,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { API, graphqlOperation } from 'aws-amplify';
import Loader from 'common/Loader';
import MuiLayout from 'common/MuiLayout';
import { getDoctor } from 'graphql/queries';
import { toString } from 'lodash';
import { IDoctor } from 'model';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import a6 from '../resources/a6.png';
import dummyImg from '../resources/dummy.png';

interface DoctorItem {
  id: string;
  name: string;
  degrees: string;
  designation: string;
  image: string;
  speciality: string;
  profileSummary: string;
  chamber: any;
  visitHour: any;
  consultationDay: any;
  bmdc: string;
  visitFee: any;
  specialization: string;
  yearsOfExperience: string;
  showVisitFee: boolean;
  status: any;
  institution: string;
  newPatientServiceCharge: any;
  oldPatientServiceCharge: any;
  reportShowPatientServiceCharge: any;
}

export function DoctorProfile() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();
  const [doctor, setDoctor] = useState<IDoctor>();
  const [loading, setLoading] = useState(false);
  const [doctorSpeciality, setDoctorSpeciality] = useState<string>();

  const handleCall = () => {
    window.location.href = 'tel:09666777711';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        setLoading(true);
        const result = (await API.graphql(
          graphqlOperation(getDoctor, {id: id})
        )) as GraphQLResult;
        if (result.errors) {
          throw new Error(
            `Error found in mutation getDoctor: ${JSON.stringify(
              result.errors
            )}`
          );
        } else {
          const doctor = result.data['getDoctor'];
          setDoctorSpeciality(doctor.speciality);
          setDoctor({...doctor, ...JSON.parse(doctor?.info)});
        }
        setLoading(false);
      } catch (err) {
        throw new Error(
          `Exception found in mutation getDoctor: ${JSON.stringify(err)}`
        );
      }
    }
    fetchData();
  }, [id]);

  return (
    <div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div
          style={{
            backgroundImage: `url(${a6})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
          }}
        >
          {doctor && (
            <MuiLayout>
              <Helmet>
                <title>{doctor.name}</title>
                <link rel="canonical" href={`${window.location.href}`} />
                <link rel="og:url" href={`${window.location.href}`} />
                <meta
                  name="description"
                  content={`${doctor.name}, ${doctor.designation}, ${
                    doctor.institution ? doctor.institution : ' '
                  }. Having ${
                    doctor.yearsOfExperience ? doctor.yearsOfExperience : ' N/A'
                  } years of experience. Specializations in : - ${
                    doctor.specialization ? doctor.specialization : 'N/A'
                  }.`}
                />
                <meta
                  name="og:description"
                  content={`${doctor.name}, ${doctor.designation}, ${
                    doctor.institution ? doctor.institution : ' '
                  }. Having ${
                    doctor.yearsOfExperience ? doctor.yearsOfExperience : 'N/A '
                  } years of experience. Specializations in : - ${
                    doctor.specialization ? doctor.specialization : 'N/A'
                  }.`}
                />
                <meta name="og:title" content={doctor.name} />
                <meta
                  name="og:image"
                  content={doctor.image ? doctor.image : dummyImg}
                />
                <meta name="og:image:alt" content={doctor.name} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Doctor Koi" />
                <meta name="og:url" content={`${window.location.href}`} />
              </Helmet>
              <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                <Grid container padding={2} direction="row">
                  <Grid item md={4} sm={6}>
                    <Box width={300}>
                      <Typography
                        variant="button"
                        align="center"
                        sx={{
                          color: 'white',
                          backgroundColor: '#E93541',
                          padding: '1px 10px',
                          borderRadius: '5px 40px 0px 0px',
                          display: 'block',
                          width: 'fit-content',
                          minWidth: '60%',
                          fontWeight: 900,
                        }}
                      >
                        {doctor?.speciality}
                      </Typography>
                      <Card
                        sx={{
                          backgroundColor: '#F4F4F4',
                          borderRadius: '5px',
                          boxShadow: '0px 0px 5px #5f5b5b',
                          marginBottom: '5px',
                        }}
                      >
                        <Grid container sx={{paddingTop: 2}}>
                          <Avatar
                            alt={doctor?.name}
                            src={doctor?.image || dummyImg}
                            sx={{
                              width: 150,
                              height: 150,
                              borderRadius: '100%',
                              margin: '0 auto',
                            }}
                          />
                        </Grid>
                        <Grid item xs={8} sx={{marginTop: 2}}>
                          <Stack direction="row" marginLeft={1}>
                            <Grid>
                              <PersonIcon sx={{color: '#f25449'}} />
                            </Grid>
                            <Grid>
                              <Typography
                                marginLeft={1}
                                fontWeight={900}
                                className="black-80"
                                component="h6"
                              >
                                {doctor?.name || 'Doctor Name'}
                              </Typography>
                            </Grid>
                          </Stack>
                          <Stack direction="row" marginLeft={1}>
                            <Grid className="">
                              <WorkIcon sx={{color: '#f25449'}} />
                            </Grid>
                            <Grid>
                              <Typography
                                fontSize={12}
                                marginLeft={1}
                                fontWeight={900}
                                className="black-80"
                                mt={0.5}
                                variant="body2"
                                component="p"
                              >
                                {doctor?.designation || 'N/A'}
                              </Typography>
                            </Grid>
                          </Stack>
                          <Stack direction="row" marginLeft={1}>
                            <Grid className="">
                              <CorporateFareOutlinedIcon
                                sx={{color: '#f25449'}}
                              />
                            </Grid>
                            <Grid>
                              <Typography
                                fontSize={11}
                                className="black-80"
                                fontWeight={900}
                                marginLeft={1}
                                mt={0.5}
                                variant="body2"
                                component="p"
                              >
                                {doctor?.department || 'N/A'}
                              </Typography>
                            </Grid>
                          </Stack>
                          <Stack direction="row" marginLeft={1}>
                            <Grid className="">
                              <LocationOnOutlinedIcon sx={{color: '#f25449'}} />
                            </Grid>
                            <Grid>
                              <Typography
                                fontSize={11}
                                className="black-80"
                                fontWeight={900}
                                marginLeft={1}
                                mt={0.5}
                                variant="body2"
                                component="p"
                              >
                                {doctor?.institution || 'N/A'}
                              </Typography>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={6}>
                    <Grid
                      container
                      direction="row"
                      marginTop={3}
                      spacing={2}
                      alignItems="flex-start"
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={6} spacing={2}>
                        <Grid paddingBottom={1}>
                          <Stack direction="row">
                            <SchoolIcon sx={{color: '#f71000'}} />
                            <Typography
                              marginLeft={1}
                              fontWeight={900}
                              component="h6"
                            >
                              Degrees and Trainings
                            </Typography>
                          </Stack>
                          <Typography
                            fontSize={12}
                            fontWeight={900}
                            marginLeft={4}
                            mt={0.5}
                            component="p"
                          >
                            {doctor?.degrees || 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid paddingBottom={1}>
                          <Stack direction="row">
                            <WorkspacePremiumIcon sx={{color: '#f71000'}} />
                            <Typography
                              marginLeft={1}
                              fontWeight={900}
                              component="h6"
                            >
                              Specialization
                            </Typography>
                          </Stack>
                          <Typography
                            fontSize={12}
                            fontWeight={900}
                            marginLeft={4}
                            mt={0.5}
                            component="p"
                          >
                            {doctor?.specialization || 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid paddingBottom={1}>
                          <Stack direction="row">
                            <WorkHistoryIcon sx={{color: '#f71000'}} />
                            <Typography
                              marginLeft={1}
                              fontWeight={900}
                              component="h6"
                            >
                              Years of Experience
                            </Typography>
                          </Stack>
                          <Typography
                            fontSize={12}
                            fontWeight={900}
                            marginLeft={4}
                            mt={0.5}
                            component="p"
                          >
                            {doctor?.yearsOfExperience
                              ? `${doctor?.yearsOfExperience} years of experience`
                              : 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Stack direction="row">
                            <VerifiedIcon sx={{color: '#f71000'}} />
                            <Typography
                              marginLeft={1}
                              fontWeight={900}
                              component="h6"
                            >
                              BMDC Number
                            </Typography>
                          </Stack>
                          <Typography
                            fontSize={12}
                            fontWeight={900}
                            marginLeft={4}
                            mt={0.5}
                            component="p"
                          >
                            {doctor?.bmdc || 'N/A'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} spacing={2}>
                        <Grid paddingBottom={1}>
                          <Stack direction="row">
                            <LocationOnIcon sx={{color: '#f71000'}} />
                            <Typography
                              marginLeft={1}
                              fontWeight={900}
                              component="h6"
                            >
                              Chamber Location
                            </Typography>
                          </Stack>
                          <Typography
                            fontSize={12}
                            fontWeight={900}
                            marginLeft={4}
                            mt={0.5}
                            component="p"
                          >
                            {doctor?.chamber &&
                              (doctor?.chamber[0]?.address || 'N/A')}
                            <br />
                            {doctor?.chamber &&
                              (doctor?.chamber[0]?.hospital || 'N/A')}
                          </Typography>
                        </Grid>
                        <Grid paddingBottom={1}>
                          <Stack direction="row">
                            <EventAvailableIcon sx={{color: '#f71000'}} />
                            <Typography
                              marginLeft={1}
                              fontWeight={900}
                              component="h6"
                            >
                              Visitation Day
                            </Typography>
                          </Stack>
                          <Typography
                            fontSize={12}
                            fontWeight={900}
                            marginLeft={4}
                            mt={0.5}
                            component="p"
                          >
                            {toString(
                              doctor?.consultationDay
                                ?.map((day: string) =>
                                  moment(day, 'dddd').format('ddd')
                                )
                                .join(', ')
                            ) || 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid paddingBottom={2}>
                          <Stack direction="row">
                            <MonetizationOnIcon sx={{color: '#f71000'}} />
                            <Typography
                              marginLeft={1}
                              fontWeight={900}
                              component="h6"
                            >
                              Visitation Fee
                            </Typography>
                          </Stack>
                          <Typography
                            fontSize={12}
                            fontWeight={900}
                            marginLeft={4}
                            mt={0.5}
                            component="p"
                          >
                            {doctor?.visitFee.length > 0 ? (
                              <>
                                {doctor?.visitFee[0]?.fee
                                  ? `New Patient BDT ${doctor?.visitFee[0]?.fee}`
                                  : undefined}
                                <br />
                                {doctor?.visitFee[1]?.fee
                                  ? `Old Patient BDT ${doctor?.visitFee[1]?.fee}`
                                  : undefined}
                                <br />
                                {doctor?.visitFee[2]?.fee
                                  ? `Report BDT ${doctor?.visitFee[2]?.fee}`
                                  : undefined}
                              </>
                            ) : (
                              'N/A'
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <Stack
                      direction={{sm: 'row', md: 'column'}}
                      m={{sm: 2, md: 10}}
                      justifyContent="center"
                      alignItems="center"
                      spacing={{sm: 6, md: 2}}
                    >
                      {/* <a href='tel:+8809666777711' style={{ color: '#ffffff' }}>
                        <Box
                          sx={{
                            color: 'white',
                            backgroundColor: '#EE3E43',
                            padding: '10px 30px',
                            borderRadius: '5px 5px 5px 5px',
                            width: '100%',
                            display: 'block',
                            '&:hover': {
                              backgroundColor: '#EE3E43',
                            },
                          }}
                        >
                          <Typography
                            fontWeight='900'
                            textAlign='center'
                            fontSize={{ sm: 10 }}
                          >
                            Call for Appointment
                          </Typography>
                          <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                          >
                            <CallIcon sx={{ fontSize: { sm: 20 } }} />
                            <Typography
                              fontWeight='900'
                              textAlign='center'
                              fontSize={{ sm: 10 }}
                            >
                              +8809666777711
                            </Typography>
                          </Stack>
                        </Box>
                      </a> */}
                      <Button
                        onClick={handleCall}
                        variant="contained"
                        startIcon={
                          <CallIcon fontSize="small" sx={{color: '#ffffff'}} />
                        }
                        sx={{
                          background: '#EE3E43',
                          borderRadius: '5px',
                          width: '193.56px',
                          '&:hover': {
                            backgroundColor: '#EE3E43',
                          },
                          boxShadow: '0px 0px 20px #c4c4c4',
                        }}
                      >
                        <Typography
                          variant="h1"
                          fontWeight="700"
                          fontSize="14px"
                          fontFamily="HindiSiliguriRegular"
                          color="#ffffff"
                        >
                          +8809666777711
                        </Typography>
                      </Button>
                      <Button
                        onClick={() => history.push(`/appointment/${id}`)}
                        variant="outlined"
                        startIcon={
                          <VideocamIcon fontSize="small" sx={{color: '#000'}} />
                        }
                        sx={{
                          borderRadius: '5px',
                          width: '193.56px',
                          boxShadow: '0px 0px 20px #c4c4c4',
                        }}
                      >
                        <Typography
                          variant="h1"
                          fontWeight="700"
                          fontSize="14px"
                          fontFamily="HindiSiliguriRegular"
                          color="#000000"
                        >
                          Video Consultation
                        </Typography>
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => history.push(`/appointment/${id}`)}
                        startIcon={
                          <AssignmentIcon
                            fontSize="small"
                            sx={{color: '#ffffff'}}
                          />
                        }
                        sx={{
                          backgroundColor: '#EE3E43',
                          borderRadius: '5px',
                          width: '193.56px',
                          boxShadow: '0px 0px 20px #c4c4c4',
                          '&:hover': {
                            backgroundColor: '#EE3E43',
                          },
                        }}
                      >
                        <Typography
                          variant="h1"
                          fontWeight="700"
                          fontSize="14px"
                          fontFamily="HindiSiliguriRegular"
                          color="#fff"
                        >
                          Book Appointment
                        </Typography>
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack paddingLeft={2} marginTop={1} paddingBottom={2}>
                  <Grid container>
                    <PushPinIcon sx={{color: '#f71000'}} />
                    <Typography marginLeft={1} fontWeight={900} component="h6">
                      Profile Summary
                    </Typography>
                  </Grid>
                  {doctor?.profileSummary || 'Not Available'}
                </Stack>
              </Box>
              <Grid sx={{display: {xs: 'block', sm: 'none'}}}>
                <Grid
                  marginBottom={4}
                  marginRight={1}
                  marginLeft={1}
                  item
                  xs={4}
                >
                  <Typography
                    variant="button"
                    sx={{
                      color: 'white',
                      backgroundColor: '#E93541',
                      padding: '1px 10px',
                      borderRadius: '3px 30px 0px 0px',
                      display: 'block',
                      width: 'fit-content',
                      minWidth: '60%',
                      fontWeight: 900,
                      fontSize: 13,
                    }}
                  >
                    {' '}
                    {doctor?.speciality}
                  </Typography>
                  <Card
                    sx={{
                      backgroundColor: '#F4F4F4',
                      borderRadius: '5px',
                      boxShadow: '0px 0px 5px #504f4f',
                      marginBottom: '5px',
                    }}
                  >
                    <Grid container sx={{paddingTop: 2}}>
                      <Avatar
                        alt={doctor?.name}
                        src={doctor?.image || dummyImg}
                        sx={{
                          width: 150,
                          height: 150,
                          borderRadius: '100%',
                          margin: '0 auto',
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} sx={{marginTop: 2}}>
                      <Stack direction="row" sx={{marginLeft: 1}}>
                        <Grid>
                          <PersonIcon sx={{color: '#f25449'}} />
                        </Grid>
                        <Grid>
                          <Typography
                            marginLeft={1}
                            fontWeight={900}
                            className="black-80"
                            component="h6"
                          >
                            {doctor?.name || 'Doctor Name'}
                          </Typography>
                        </Grid>
                      </Stack>
                      <Stack direction="row" sx={{marginLeft: 1}}>
                        <Grid className="">
                          <WorkIcon sx={{color: '#f25449'}} />
                        </Grid>
                        <Grid>
                          <Typography
                            fontSize={14}
                            marginLeft={1}
                            fontWeight={900}
                            className="black-80"
                            mt={0.5}
                            variant="body2"
                            component="p"
                          >
                            {doctor?.designation || 'N/A'}
                          </Typography>
                        </Grid>
                      </Stack>
                      <Stack direction="row" sx={{marginLeft: 1}}>
                        <Grid className="">
                          <CorporateFareOutlinedIcon sx={{color: '#f25449'}} />
                        </Grid>
                        <Grid>
                          <Typography
                            fontSize={14}
                            className="black-80"
                            fontWeight={900}
                            marginLeft={1}
                            mt={0.5}
                            variant="body2"
                            component="p"
                          >
                            {doctor?.department || 'N/A'}
                          </Typography>
                        </Grid>
                      </Stack>
                      <Stack direction="row" sx={{marginLeft: 1}}>
                        <Grid className="">
                          <LocationOnOutlinedIcon sx={{color: '#f25449'}} />
                        </Grid>
                        <Grid>
                          <Typography
                            fontSize={12}
                            className="black-80"
                            fontWeight={900}
                            marginLeft={1}
                            mt={0.5}
                            variant="body2"
                            component="p"
                          >
                            {doctor?.institution || 'N/A'}
                          </Typography>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Card>
                </Grid>
                <Grid xs={3}>
                  <Stack
                    spacing={1}
                    marginLeft={5}
                    marginRight={5}
                    marginBottom={2}
                  >
                    <Button
                      variant="contained"
                      onClick={() => history.push(`/appointment/${id}`)}
                      sx={{
                        color: 'white',
                        backgroundColor: '#EE3E43',
                        padding: '5px 20px',
                        borderRadius: '5px 5px 5px 5px',
                        width: '100%',
                        display: 'block',
                        fontWeight: 900,
                        fontSize: 18,
                        boxShadow: '0px 0px 20px #c4c4c4',
                        margin: '0px 50px 0px 0px',
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: '#EE3E43',
                        },
                      }}
                    >
                      Book Appointment
                    </Button>
                    <a href="tel:+8809666777711" style={{color: '#ffffff'}}>
                      <Box
                        sx={{
                          color: 'white',
                          backgroundColor: '#EE3E43',
                          padding: '10px 30px',
                          borderRadius: '5px 5px 5px 5px',
                          width: '100%',
                          display: 'block',
                          fontWeight: 900,
                          fontSize: 18,
                          textAlign: 'center',
                          // boxShadow: "0px 0px 20px #c4c4c4",
                          '&:hover': {
                            backgroundColor: '#EE3E43',
                          },
                        }}
                      >
                        Call for Appointment
                        <br />
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CallIcon sx={{fontSize: 20}} />
                          +8809666777711
                        </Grid>
                      </Box>
                    </a>
                    <Button
                      onClick={() => history.push(`/appointment/${id}`)}
                      variant="outlined"
                      startIcon={<VideocamIcon fontSize="small" />}
                      sx={{
                        color: 'black',
                        // backgroundColor: "#EE3E43",
                        padding: '5px 20px',
                        borderRadius: '5px 5px 5px 5px',
                        width: '100%',
                        fontWeight: 900,
                        fontSize: 15,
                        boxShadow: '0px 0px 20px #c4c4c4',
                        margin: '20px 0px 0px 0px',
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: '#EE3E43',
                        },
                      }}
                    >
                      Video Consultation
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} sx={{height: 'auto'}} pb={5}>
                  <Accordion
                    sx={{
                      boxShadow: '0px 0px 5px #000000',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography sx={{fontWeight: 900, fontSize: 17}}>
                          DOCTOR'S DETAILS
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="row">
                        <Stack spacing={2}>
                          <Grid>
                            <Stack direction="row">
                              <SchoolIcon sx={{color: '#f71000'}} />
                              <Typography
                                marginLeft={0.5}
                                fontWeight={900}
                                component="h6"
                              >
                                Degrees and Trainings
                              </Typography>
                            </Stack>
                            <Typography
                              fontSize={12}
                              fontWeight={900}
                              marginLeft={4}
                              mt={0.5}
                              component="p"
                            >
                              {doctor?.degrees || 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Stack direction="row">
                              <WorkspacePremiumIcon sx={{color: '#f71000'}} />
                              <Typography
                                marginLeft={0.5}
                                fontWeight={900}
                                component="h6"
                              >
                                Specialization
                              </Typography>
                            </Stack>
                            <Typography
                              fontSize={12}
                              fontWeight={900}
                              marginLeft={4}
                              mt={0.5}
                              component="p"
                            >
                              {doctor?.specialization || 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Stack direction="row">
                              <WorkHistoryIcon sx={{color: '#f71000'}} />
                              <Typography
                                marginLeft={0.5}
                                fontWeight={900}
                                component="h6"
                              >
                                Years of Experience
                              </Typography>
                            </Stack>
                            <Typography
                              fontSize={12}
                              fontWeight={900}
                              marginLeft={4}
                              mt={0.5}
                              component="p"
                            >
                              {doctor?.yearsOfExperience
                                ? `${doctor?.yearsOfExperience} years of experience`
                                : 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Stack direction="row">
                              <VerifiedIcon sx={{color: '#f71000'}} />
                              <Typography
                                marginLeft={0.5}
                                fontWeight={900}
                                component="h6"
                              >
                                BMDC Number
                              </Typography>
                            </Stack>
                            <Typography
                              fontSize={12}
                              fontWeight={900}
                              marginLeft={4}
                              mt={0.5}
                              component="p"
                            >
                              {doctor?.bmdc || 'N/A'}
                            </Typography>
                          </Grid>
                        </Stack>
                        <Stack spacing={2}>
                          <Grid>
                            <Stack direction="row">
                              <LocationOnIcon sx={{color: '#f71000'}} />
                              <Typography
                                marginLeft={0.5}
                                fontWeight={900}
                                component="h6"
                                noWrap
                              >
                                Chamber Location
                              </Typography>
                            </Stack>
                            <Typography
                              fontSize={12}
                              fontWeight={900}
                              marginLeft={4}
                              mt={0.5}
                              component="p"
                            >
                              {doctor?.chamber &&
                                (doctor?.chamber[0]?.address || 'N/A')}
                              <br />
                              {doctor?.chamber &&
                                (doctor?.chamber[0]?.hospital || 'N/A')}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Stack direction="row">
                              <EventAvailableIcon sx={{color: '#f71000'}} />
                              <Typography
                                marginLeft={0.5}
                                fontWeight={900}
                                component="h6"
                              >
                                Visitation Day
                              </Typography>
                            </Stack>
                            <Typography
                              fontSize={12}
                              fontWeight={900}
                              marginLeft={4}
                              mt={0.5}
                              component="p"
                            >
                              {toString(
                                doctor?.consultationDay
                                  ?.map((day: string) =>
                                    moment(day, 'dddd').format('ddd')
                                  )
                                  .join(', ')
                              ) || 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Stack direction="row">
                              <MonetizationOnIcon sx={{color: '#f71000'}} />
                              <Typography
                                marginLeft={0.5}
                                fontWeight={900}
                                component="h6"
                              >
                                Visitation Fee
                              </Typography>
                            </Stack>
                            <Typography
                              fontSize={12}
                              fontWeight={900}
                              marginLeft={4}
                              mt={0.5}
                              component="p"
                            >
                              {doctor?.visitFee.length > 0 ? (
                                <>
                                  {doctor?.visitFee[0]?.fee
                                    ? `New Patient BDT ${doctor?.visitFee[0]?.fee}`
                                    : undefined}
                                  <br />
                                  {doctor?.visitFee[1]?.fee
                                    ? `Old Patient BDT ${doctor?.visitFee[1]?.fee}`
                                    : undefined}
                                  <br />
                                  {doctor?.visitFee[2]?.fee
                                    ? `Report BDT ${doctor?.visitFee[2]?.fee}`
                                    : undefined}
                                </>
                              ) : (
                                'N/A'
                              )}
                            </Typography>
                          </Grid>
                        </Stack>
                      </Stack>
                      <Stack marginTop={3}>
                        <Grid container>
                          <PushPinIcon sx={{color: '#f71000'}} />
                          <Typography
                            marginLeft={1}
                            fontWeight={900}
                            component="h6"
                          >
                            Profile Summary
                          </Typography>
                        </Grid>
                        {doctor?.profileSummary || 'Not Available'}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <Grid
                container
                paddingBottom={5}
                alignItems="center"
                justifyContent="center"
                item
                xs={12}
              >
                <Button
                  onClick={() =>
                    history.push(`/speciality=${doctorSpeciality}`)
                  }
                  sx={{
                    color: 'white',
                    backgroundColor: '#EE3E43',
                    borderRadius: '5px',
                    fontWeight: 900,
                    fontSize: 18,
                    boxShadow: '0px 0px 20px #c4c4c4',
                    '&:hover': {
                      backgroundColor: '#EE3E43',
                    },
                  }}
                >
                  See Similar Speicality Doctors
                </Button>
              </Grid>
            </MuiLayout>
          )}
        </div>
      )}
    </div>
  );
}
