import React, { useState } from "react";
import {
  Button,
  Icon,
  InputGroup,
  Position,
  TextArea,
  Toaster,
} from "@blueprintjs/core";
import { API, graphqlOperation } from "aws-amplify";
import { createPublicQuery } from "../graphql/mutations";
import { emailValidation } from "../util";
import { validatePhoneNumber } from "@doctorkoi/utils";

export const AppToaster = Toaster.create({
  position: Position.TOP,
});

export function Form() {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  function sendEmail(e: any) {
    e.preventDefault();
    setLoading(true);

    (
      API.graphql(
        graphqlOperation(createPublicQuery, {
          input: {
            name,
            phone,
            email,
            message,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        })
      ) as Promise<any>
    ).then((result) => {
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setLoading(false);

      AppToaster.show({
        message: `Your query has been successfully submitted.`,
        icon: "tick-circle",
        intent: "success",
        timeout: 5000,
      });
    });
  }

  const disableSubmitButton = () => {
    if (name && email && phone && message) {
      if (emailValidation(email) && validatePhoneNumber(phone)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <div
      className="cf w-100 pt4"
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <div className="fl w-100 w-40-ns h5-l h5-m h-auto tc black">
        <div className="tl pa2 ">
          <div className="f5  flex-m flex-column justify-start mb3">
            <Icon icon="phone" iconSize={40} intent="danger" className="" />
            <div className="b "> Give us a call </div> <br />
            <div className="b ">
              <a
                href="tel:+8809666777711"
                style={{ textDecoration: "none", color: "black" }}
              >
                +880 9666 7777 11
              </a>
            </div>
            <div className="b ">
              <a
                href="tel:+8809666788988"
                style={{ textDecoration: "none", color: "black" }}
              >
                +880 9666 788 988
              </a>
            </div>
          </div>
          <div className="f5  flex-m flex-column justify-start mb3">
            <Icon icon="envelope" iconSize={40} intent="danger" />
            <div className="b"> Send us an email </div>
            <div className="b"> info@doctorkoi.com </div>
          </div>
        </div>
      </div>
      <div className="fl w-100 w-40-ns h5 tc mb5">
        <form className="cf" onSubmit={sendEmail}>
          <div className="fl w-100">
            <InputGroup
              fill={false}
              name="name"
              type="text"
              // intent="danger"
              required={true}
              large={true}
              placeholder="Your Name"
              value={name}
              onChange={(e: any) => {
                setName(e.target.value);
              }}
              className="w-100 br4 tc mb1 pa2"
            />
          </div>
          <div className="fl w-100">
            <InputGroup
              fill={false}
              intent={
                email
                  ? emailValidation(email)
                    ? undefined
                    : "danger"
                  : undefined
              }
              required={true}
              name="email"
              type="email"
              large={true}
              placeholder="Email Address"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              className="w-100 br4 tc mb1 pa2"
            />
          </div>
          <div className="fl w-100">
            <InputGroup
              fill={false}
              required={true}
              name="phone"
              type="text"
              large={true}
              placeholder="Phone Number"
              className="w-100 br4 tl mb1 pa2"
              intent={
                phone
                  ? validatePhoneNumber(phone)
                    ? undefined
                    : "danger"
                  : undefined
              }
              value={phone}
              onChange={(e: any) => setPhone(e.target.value)}
            />
          </div>
          <div className="fl w-100 pa2">
            <TextArea
              growVertically={true}
              large={true}
              name="message"
              placeholder="Message"
              className="w-100 br4 tl mb1 pa2"
              // intent="danger"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className=" w-100 tc pa2 mb5">
            <Button
              intent="danger"
              loading={loading}
              className=" b"
              text="Submit"
              type="submit"
              disabled={disableSubmitButton()}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
