import { Button, Intent, Spinner } from '@blueprintjs/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { listCirculars } from '../graphql/queries';
import './Career.css';

export function CareerOpening() {
  let history = useHistory();
  const [loading, setLoading] = useState<boolean>(true)
  const [circulars, setCirculars] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    (API.graphql(
      graphqlOperation(listCirculars, { 
        sortDirection: 'DESC',
        limit: 1000,
      })
    ) as Promise<any>).then(result => {
      let CircularList = result.data.listCirculars.items;
      let allCirculars: any = [];

      CircularList.forEach((circular: any) => {
        if (circular.status === 'Active') {
          allCirculars.push({
            id: circular.id,
            position: circular.position,
            deadline: circular.deadline,
            jobDescription: circular.jobDescription,
          });
        }
      });
      setCirculars(allCirculars);
      setLoading(false)
    });
  }, []);

  return loading ? <Spinner size={Spinner.SIZE_LARGE} /> : (
    <>
    <section className=" dt w-100 mb4-l mb3-m mb2" >
      <div className="dtc v-mid tc black ph3 ph4-l">
        <h1 className="headline lh-copy underline"> Current Vacancies at Renssoft Solutions Ltd. </h1>
      </div>
    </section>
    {circulars.length ? 
      circulars.map((circular: any, index: number) =>
        <ol className="pl0">
          <section className="_lists_ flex-l flex-m items-center cf  mb5-l mb5-m mb4">
            <div className="fl w-100 w-40-l w-40-m tc">
              <div className="cf flex items-center headline f4">
                <div className="fl dtc-l dtc-m dtc w-10-l w-10-m w-10">
                  {index + 1}
                </div>
                <div className="fl dtc-l dtc-m dtc w-50-l w-70-m w-100 tl black b">
                {circular.position}
                </div>
              </div>
            </div>
            <div className="fl w-100 w-60-l w-60-m tc">
              <div className="flex items-center cf">
                <div className="fl w-50 pl5-l pl4-m pl0 tl-l tl-m tc">
                  <Button className="tc "intent={Intent.WARNING} text="See Details"
                    onClick={() => history.push(`/jobdetails/${circular.id}`)}
                  />
                </div>
                <div className="fl w-50 tl">
                  <Button text="Apply Online" intent="success" onClick={() => history.push(`/career/${circular.id}`)} />
                </div>
              </div>  
            </div>
          </section>
        </ol>
      ) 
      : 
      <div className="tc center">
        <span className="tc f4 red fw5 center"> "Sorry, there is no vacancy right now." </span>
      </div>
    }
    
    <section className="pv4 pv3-l pv4-m"></section>       
    </>
  )
}