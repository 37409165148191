import React from "react";
import { Button, Position, Toaster } from "@blueprintjs/core";
import { Google } from "./Gmap";
import { Form } from "./form";
// @ts-ignore
import { MetaTags } from "react-meta-tags";

export const AppToaster = Toaster.create({
  position: Position.TOP,
});

export function ContactUs() {
  return (
    <div>
      <MetaTags>
        <meta
          name="description"
          content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, a prescription writing software that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally. The company also provides online consultation services for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception DRX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online."
        />
        <meta property="og:title" content="DoctorKoi" />
        <meta
          property="og:image"
          content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg"
        />
      </MetaTags>
      <section className="vh-100 dt w-100 ">
        <div className="dtc v-mid tc white ph3 ph4-l ">
          <div
            className="cf w-100 bp3-card-l bp3-card-m"
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="fl w-100 w-40-ns h5 tl black mb4-ns mb0-l mb0-m">
              <div className="tl pa2 ">
                <h1> Renssoft Solutions Ltd.</h1>
                <h3> Dhaka</h3>
                <h3> Address</h3>
                <p className="f5 b">
                  Renssoft Solutions Ltd. House 16/A, Road 07, Dhanmondi,
                  Dhaka-1209.
                </p>
                <div className="flex justify-start ">
                  <Button
                    text="Get Direction"
                    icon="map-marker"
                    minimal={true}
                    intent="danger"
                    className="b tl f4-l f4-m f5 items-center"
                    style={{ marginLeft: " -1rem" }}
                    onClick={() =>
                      window.open(
                        "https://www.google.com/maps/place/Doctorkoi.com/@23.742892,90.3688748,17z/data=!3m1!4b1!4m5!3m4!1s0x3755bfb46d21e23b:0xb8cba9b5fd3c8c2!8m2!3d23.742894!4d90.3710827"
                      )
                    }
                  />
                  <a
                    href="tel:+8809666777711"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      text="Contact Us"
                      icon="locate"
                      className="b tl f4-l f4-m f5 items-center"
                      minimal={true}
                      intent="danger"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="fl w-100 w-40-ns h5 tc mt4-ns mt0-l mt0-m">
              <div>{<Google />}</div>
            </div>
          </div>
          <Form />
        </div>
      </section>
    </div>
  );
}
