import { Button, Classes, Intent } from '@blueprintjs/core';
import { PasswordField, TextField } from '@doctorkoi/components';
import { isEmpty } from 'lodash';
import React from 'react';

interface LoginComponentProps {
  onLogin: (username: string, password: string) => Promise<void>;
}

interface LoginComponentInternalProps extends LoginComponentProps {
  loginError?: string;
  updateError: (loginError: string) => void;
  userName?: string;
  password?: string;
  updateUserName: (name: string) => void;
  updatePassword: (name: string) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  login: (e: any) => void;
}

export const LoginDigitalRx = (props: LoginComponentInternalProps) => (
  <article className="vh-100 dt w-100 bg-dark-grey white cover">
    <div className="dtc v-mid tc ph3 ph4-l">
      <form
        className={`${Classes.CONTROL_GROUP} ${Classes.VERTICAL} ${Classes.FILL}`}
      >
        {props.loginError ? (
          <div className={`f6 db mv1 ${Classes.INTENT_DANGER} ${Classes.TAG}`}>
            {props.loginError}
          </div>
        ) : undefined}
        <TextField
          id="username"
          leftIcon="person"
          error={props.loginError}
          value={props.userName}
          placeholder="Enter Username"
          large={true}
          onChange={props.updateUserName}
        />
        <PasswordField
          id="password"
          placeholder="Enter Password or Activation code"
          error={props.loginError}
          value={props.password}
          large={true}
          onChange={props.updatePassword}
        />
        <Button
          className={`${Classes.LARGE}`}
          icon="log-in"
          text="Login"
          loading={props.loading}
          disabled={isEmpty(props.password) || isEmpty(props.userName)}
          intent={props.loginError ? Intent.DANGER : Intent.PRIMARY}
          type="submit"
          onClick={props.login}
        />
      </form>
    </div>
  </article>
);
