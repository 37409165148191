import {
  Card,
  Grid,
  Stack,
  Typography,
  Box,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Tick from '../assets/images/tickIcon.svg';
import React, { useEffect } from 'react';

interface PackageCardProps {
  cardColor: any;
  textColor: any;
  price: string;
  packageName: string;
  packageInfo: string;
  packageFeatureList: any;
  children?: JSX.Element;
}

interface FaqAccordionProps {
  question: string;
  answer: string;
}

interface TextProps {
  color: any;
  title: string;
}

const DigitalRxFeatures = [
  'Unlimited Prescription Writing',
  '27000+ Medicine Database With Dosages',
  'Patient Record with History Charting',
  'Easy Follow-up in few clicks',
  'Prescription Template and completed customization for any pad',
  'Real time Reports',
  'Cloud Backup ( Your data has multiple encrypted backup )',
];

const DrxPracticeFeatures = [
  'Advance Calendar',
  'Unlimited Prescription Writing',
  '27000+ Medicine Database With Dosages',
  'Professional Billing',
  'Patient Record with History Charting',
  'Easy Follow-up in few clicks',
  'SMS center for advance communication up-to 1,000 SMS',
  'Practice Charting',
  'Real time practice Reports',
  'Business health Analytics and visitation forecasting',
  'Storage & easy sharing for up-to 1,000 patient',
  'Cloud backup ( Your data has multiple encrypted backup )',
];

const PracticeProFeatures = [
  'Advance Calendar',
  'Professional Billing',
  'Practice Charting',
  'SMS center for advance communication up-to 1,000 SMS',
  'Real time practice Reports',
  'Storage & easy sharing for up-to 1,000 patients',
  'Multiple Stakeholder ( Up to 2 users ) for End to end workflow management',
  'Business health Analytics and visitation forecasting',
  'Cloud backup ( Your data has multiple encrypted backup )',
];

const FaqQuestions = [
  {
    question: 'Will Practice Pro help me get more patients?',
    answer:
      'No. Practice Pro is a practice management software, not an advertising product. It aids in better managing your practice and providing a consistent experience for your patients.',
  },
  {
    question: 'Will Digital Rx customize the software for me?',
    answer:
      'Digital Rx is a feature-rich software that was developed with extensive research and input from doctors around the country. A great deal of effort has gone into ensuring that Digital Rx has features that meet the demands of all practices. Even while we do not sell customized software to specific doctors, our team is always adding new features depending on popular demand.',
  },
  {
    question: 'What about updates? Will I have to purchase the software again?',
    answer:
      'Once you subscribe to the software, you will instantly be eligible to all free updates.',
  },
  {
    question: 'How much time do I need to invest to get started?',
    answer:
      'Digital Rx and Practice Pro are quite simple to learn and use. To assist our customers, we offer three in-depth training sessions as well as a large collection of assistance articles and training videos that may be used as a quick reference by you and your compounder when learning any feature. If you require any additional assistance, please contact our support team.',
  },
  {
    question:
      'What about my compounder? Will I have to teach them how to use Practice Pro?',
    answer:
      'No, you are not required to train your compounder. We are here to assist with training. Your compounder can refer to the enormous collection of support articles or view training videos at their leisure to learn any feature. They can also contact our support team for additional assistance and training.',
  },
  {
    question: 'What if I am stuck? Who will help me?',
    answer:
      'Our customer support team is here to assist you at any time. You can call +880 9666 788 988 or send an email to user.doctorkoiofficial@gmail.com to reach the support team with any questions related to software.',
  },
];

const SectionTitle = (props: TextProps) => {
  const { title, color } = props;
  return (
    <Typography
      variant='h1'
      fontWeight='700'
      fontSize={{ xs: '26px', sm: '30px', md: '46px' }}
      fontFamily='HindiSiliguriRegular'
      textTransform='capitalize'
      color={color}
      letterSpacing={1}
    >
      {title}
    </Typography>
  );
};

const FaqText = (props: TextProps) => {
  const { title, color } = props;
  return (
    <Typography
      fontSize={{ xs: '17px' }}
      fontFamily='HindiSiliguriRegular'
      color={color}
    >
      {title}
    </Typography>
  );
};

const PackageCard = (props: PackageCardProps) => {
  const {
    cardColor,
    textColor,
    packageName,
    packageInfo,
    price,
    packageFeatureList,
    children,
  } = props;
  return (
    <Grid item xs={12} sm={4} p={{ xs: 3, sm: 1 }}>
      <Card
        sx={{
          position: 'relative',
          borderRadius: '13px',
          p: 2,
          backgroundColor: cardColor,
        }}
        elevation={3}
      >
        <Typography
          variant='h1'
          fontWeight='700'
          fontSize={{ xs: '17px', sm: '18px', md: '23px' }}
          fontFamily='HindiSiliguriRegular'
          textTransform='capitalize'
          color={textColor}
          letterSpacing={1}
          pb={1}
        >
          {packageName}
        </Typography>
        <Typography
          variant='h1'
          fontSize={{ xs: '13px', sm: '17px' }}
          fontFamily='HindiSiliguriRegular'
          textTransform='capitalize'
          color={textColor}
          pb={1}
        >
          {packageInfo}
        </Typography>
        <Stack direction='row' alignItems='center' pb={2}>
          {children}
          <Typography
            variant='h1'
            fontWeight='700'
            fontSize={{ xs: '19px', sm: '20px', md: '30px' }}
            fontFamily='HindiSiliguriRegular'
            textTransform='capitalize'
            color={textColor}
            letterSpacing={1}
          >
            ৳{price}&nbsp;
          </Typography>
          <Typography
            variant='h1'
            fontSize={{ xs: '13px', sm: '17px' }}
            fontFamily='HindiSiliguriRegular'
            textTransform='capitalize'
            color={textColor}
          >
            /month
          </Typography>
        </Stack>
        <List>
          {packageFeatureList.map((value: any) => (
            <ListItem disableGutters alignItems='flex-start'>
              <img src={Tick} alt='tick' width='7%' />
              <Typography
                variant='h1'
                fontSize={{ xs: '13px', sm: '17px' }}
                fontFamily='HindiSiliguriRegular'
                textTransform='capitalize'
                color={textColor}
                pl={1}
              >
                {value}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Card>
    </Grid>
  );
};

export function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Packages />
      <FAQ />
    </>
  );
}

export function Packages() {
  return (
    <>
      <Box pt={2}>
        <Grid container justifyContent='center' alignItems='center'>
          <SectionTitle title='Pricing' color={grey[900]} />
          <Grid item xs={12} pt={2} px={{ md: 8, xl: 10 }}>
            <Grid container justifyContent='center'>
              <PackageCard
                cardColor={grey[50]}
                textColor={grey[900]}
                price='999'
                packageName='Digital Rx'
                packageInfo='Ideal for individuals who only want to Write Digital Prescription'
                packageFeatureList={DigitalRxFeatures}
              />
              <PackageCard
                cardColor={red[600]}
                textColor={grey[50]}
                price='1,999'
                packageName='Digital Rx + Practice Pro'
                packageInfo='Ideal for individuals who only want to manage their Chambers Digitally'
                packageFeatureList={DrxPracticeFeatures}
                children={
                  <Typography
                    variant='h1'
                    fontWeight='700'
                    fontSize={{ xs: '19px', sm: '20px', md: '30px' }}
                    fontFamily='HindiSiliguriRegular'
                    textTransform='capitalize'
                    color='rgba(253, 253, 253, 0.521)'
                    letterSpacing={1}
                    sx={{
                      position: 'relative',
                      '&::after': {
                        content: "''",
                        position: 'absolute',
                        bottom: '50%',
                        left: '15%',
                        right: '5%',
                        height: '5px',
                        backgroundColor: 'white',
                        transform: 'rotate(15deg)',
                        transformOrigin: 'center',
                      },
                    }}
                  >
                    ৳ 2,498&nbsp;
                  </Typography>
                }
              />
              <PackageCard
                cardColor={grey[50]}
                textColor={grey[900]}
                price='1,499'
                packageName='Practice Pro'
                packageInfo='Ideal for individuals who only want to manage their Chambers Digitally'
                packageFeatureList={PracticeProFeatures}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export function FAQ() {
  const FaqAccordion = (props: FaqAccordionProps) => {
    const { question, answer } = props;
    return (
      <>
        <Accordion
          sx={{
            '&:hover': {
              backgroundColor: grey[200],
            },
            width: '100%',
            boxShadow: 'none',
          }}
          disableGutters
        >
          <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <ExpandCircleDownIcon fontSize='small' />
              <FaqText title={question} color={grey[900]} />
            </Stack>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: '.7rem' }}>
            <FaqText title={answer} color={grey[900]} />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const FaqList = () => {
    return (
      <>
        <List sx={{ width: '100%' }}>
          {FaqQuestions.map((faqs: any, index: number) => (
            <ListItem key={index} disableGutters disablePadding>
              <FaqAccordion question={faqs.question} answer={faqs.answer} />
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  return (
    <>
      <Box py={5}>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={11}>
            <SectionTitle title='FAQs' color={grey[900]} />
          </Grid>
          <Grid item xs={11}>
            <FaqList />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
