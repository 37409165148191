import { Call, Close, Search } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Loader from 'common/Loader';
import MuiLayout from 'common/MuiLayout';
import { uniqBy } from 'lodash';
import { IDoctor } from 'model';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import SliderProfile from '../PublicSiteNewDesign/LandingPage/SliderProfile';
import { Store } from '../Store';
import CartIcon from '../assets/images/CartIcon.png';
import ChamberLocationIcon from '../assets/images/ChamberLocation_icon.png';
import CreateAccountIcon from '../assets/images/CreateAccountIcon.png';
import CrounIcon from '../assets/images/CrownIcon.png';
import DigitalPrescriptionIcon from '../assets/images/DigitalPrescriptionIcon.png';
import DoctorConsultIcon from '../assets/images/DoctorConsultIcon.png';
import DplusIcon from '../assets/images/DplusIcon.png';
import EntIcon from '../assets/images/ENT.png';
import Dot from '../assets/images/Ellipse.png';
import MedIcon from '../assets/images/Medicine.png';
import MedicineDeliveryIcon from '../assets/images/MedicineDelivery_icon.png';
import NutritonistIcon from '../assets/images/Nutritionist.png';
import OncologyIcon from '../assets/images/Oncology.png';
import OrthopaedicIcon from '../assets/images/Orthopaedic.png';
import RheumatologyIcon from '../assets/images/Rheumatology.png';
import SelectSpecialityIcon from '../assets/images/SelectSpecialityIcon.png';
import SurgeryIcon from '../assets/images/Surgery.png';
import NeurologyIcon from '../assets/images/neurology.png';
// import EntIcon from '../assets/images/ENT.png';
// import NutritonistIcon from '../assets/images/Nutritionist.png';
import PediatricsIcon from '../assets/images/Pediatric  Child Health.png';
// import Dot from '../assets/images/Ellipse.png';
// import ChamberLocationIcon from '../assets/images/ChamberLocation_icon.png';
import DrugLogo from '../assets/images/drug_brand.png';
import RadiantLogo from '../assets/images/radiant.png';
import AciLogo from '../assets/images/aci_brand.png';
import AdnLogo from '../assets/images/adnlogo.png';
import BdangelsLogo from '../assets/images/bdangelslogo.png';
// import BdangelsLogo from '../assets/images/bdangelslogo.png';
import BdcomLogo from '../assets/images/bdcomlogo.png';
import LabsampleIcon from '../assets/images/blood-sample.png';
import DocIcon from '../assets/images/doctor_icon.png';
import DrugsIcon from '../assets/images/drugs.png';
import InceptaLogo from '../assets/images/inceptalogo.png';
// import NeurologyIcon from '../assets/images/neurology.png';
import SbkLogo from '../assets/images/sbklogo.png';
import SfkLogo from '../assets/images/skflogo.png';
import UrologyIcon from '../assets/images/urology.png';
import { listDoctors } from '../graphql/queries';
import { listItems, randomUUID } from '../util';

interface SearchDoctorComponentProps {
  doctorList: Array<IDoctor>;
}

interface IDoctorSearch {
  id: string;
  category: string;
  title: string;
}

const root = {
  fontSize: 10,
  '& :hover': {
    color: 'black',
    fontWeight: 'bold',
  },
  '& li': {
    //list item specific styling
    border: '2px solid red',
    borderRadius: 18,
    fontFamily: 'HindiSiliguriRegular',
    fontWeight: 'bold',
    color: 'black',
  },
};

const videoIds = ['w6BxDdBsNlI', 'z3aCF_gIpdA', '8jgUgW_wLkQ'];

const featuredDoctors = [
  {
    id: 'aec7e572-73fc-41cd-ab0e-87ca0f7a4afd',
    name: 'Dr. Indrajit Prasad',
    speciality: 'Endocrinology',
    specialization: 'Diabetes, Thyroid, Hormone Diseases (Endocrinology)',
    designation: 'Professor',
    department: 'Diabetes & Endocrinology',
    chamber: [{ hospital: 'Labaid Specialized Hospital, Dhanmondi' }],
    yearsOfExperience: 23,
    consultationDay: [
      'Saturday',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
    ],
    image: require(`../resources/featuredDoctors/Dr.-Indrajit-Prasad.png`),
    institution: 'Dhaka Medical College & Hospital',
  },
  {
    id: 'c8fe50d9-49da-4fb2-a68d-eabac4d30ee1',
    name: 'Dr. Joysree Saha',
    speciality: 'Gynaecology & Obstetrics.',
    specialization:
      'Gynaecology and Obstetrics (Pregnancy, Menstrual, Uterus, Female)',
    designation: 'Associate Professor',
    department: 'Gynaecology and Obstetrics',
    chamber: [{ hospital: 'Popular Diagnostic Center Ltd. (DHANMONDI Branch)' }],
    yearsOfExperience: 18,
    consultationDay: [
      'Saturday',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
    ],
    image: require(`../resources/featuredDoctors/joyosree.png`),
    institution: 'Popular Medical College & Hospital, Dhaka',
  },
  {
    id: '17f406bd-583f-498f-987e-52bea5680086',
    name: 'Dr. Khwaja Nazim Uddin',
    speciality: 'Medicine',
    specialization: 'Internal Medicine, Diabetology & Endocrinology',
    designation: 'Professor',
    department: 'Internal Medicine',
    chamber: [{ hospital: 'Labaid Specialized Hospital, Dhanmondi' }],
    yearsOfExperience: 22,
    consultationDay: [
      'Saturday',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
    ],
    image: require(`../resources/featuredDoctors/Prof. Dr. Khwaja Nazim Uddin.png`),
    institution: 'BIRDEM General Hospital.',
  },
  {
    id: '70d36a60-7945-4604-8439-aa6df272b6cd',
    name: 'Dr. Abdus Shakur Khan',
    speciality: 'Medicine & Chest Disease',
    specialization: 'Respiratory Medicine & Chest Diseases',
    designation: 'Assistant Professor',
    department: 'Respiratory Medicine',
    chamber: [{ hospital: 'Anwer Khan Modern Medical College Hospital' }],
    yearsOfExperience: 32,
    consultationDay: ['Saturday', 'Sunday', 'Monday', 'Wednesday', 'Thursday'],
    image: require(`../resources/featuredDoctors/sakur-sir.png`),
    institution: 'National Institute of Diseases of the Chest and Hospital',
  },
  {
    id: '0be750c5-624c-41f4-9001-5d5152da5167',
    name: 'Dr. Mohsin Ali Shah',
    speciality: 'Psychiatry',
    specialization: 'Psychiatry (Mental Health, Drug Abuse, Depression, etc.)',
    designation: 'Professor',
    department: 'Psychiatry',
    chamber: [{ hospital: 'Bangladesh Psychiatric Care Limited' }],
    yearsOfExperience: 19,
    consultationDay: ['Wednesday'],
    image: require(`../resources/featuredDoctors/Profile Picture of Dr. Mohsin Ali Shah (2).jpeg`),
    institution: 'Bangabandhu Sheikh Mujib Medical University',
  },
  {
    id: 'db092d19-badc-4bca-9c66-054c6f65c3c7',
    name: 'Dr. Mejor (Retd.) Anwar Hussain',
    speciality: 'Dermatology',
    specialization:
      'Skin, STD & Allergy Specialist & Laser & Cosmetics Surgeon',
    designation: 'Professor',
    department: 'Dermatology & VD',
    chamber: [{ hospital: 'Ibn Sina Medical College Hospital' }],
    yearsOfExperience: 33,
    consultationDay: [
      'Saturday',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
    ],
    image: require(`../resources/featuredDoctors/Dr.-Mejor-(Retd.)-Anwar-Hussain.png`),
    institution: 'Ibn Sina Medical College Hospital',
  },
  {
    id: '481b5a39-b262-4137-a909-d93826754d62',
    name: 'Dr. Rezwanur Rahman',
    speciality: 'Nephrology',
    specialization:
      'Kidney Disease, Dialysis and Kidney Transplantation, glomerulonephritis and renal transplantation',
    designation: 'Associate Professor',
    department: 'Nephrology',
    chamber: [{ hospital: 'Popular Consultation Centre-3, Dhanmondi' }],
    yearsOfExperience: 12,
    consultationDay: ['Sunday', 'Monday', 'Tuesday', 'Wednesday'],
    image: require(`../resources/featuredDoctors/Dr.-Rezwanur-Rahman.png`),
    institution: 'Bangladesh Medical College and Hospital',
  },
  {
    id: '1c748074-b678-4523-a7a2-f7dfd87da7b0',
    name: 'Dr. M. A. Khan',
    speciality: 'Haematology',
    specialization: 'Hematology, Hemato-Oncology & Bone Marrow transplants ',
    designation: 'Professor',
    department: 'Haematology & BMT Unit',
    chamber: [{ hospital: 'Labaid Specialized Hospital, Dhanmondi' }],
    yearsOfExperience: 22,
    consultationDay: [
      'Saturday',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
    ],
    image: require(`../resources/featuredDoctors/Prof.-Dr.-M.-A-Khan.png`),
    institution: 'Dhaka Medical College & Hospital',
  },
];

const settings = {
  className: '_centerAlign_ ',
  centerMode: false,
  infinite: true,
  autoplay: true,
  touchMove: true,
  pauseOnHover: true,
  autoPlaySpeed: 5000, //slide hold time
  speed: 3000, //slide shifting time
  dots: true,
  mute: true,
  slidesToShow: 3,
  arrows: false,
  slidesToScroll: 1,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    // {
    //   breakpoint: 750,
    //   settings: {
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //   },
    // },
    // {
    //   breakpoint: 700,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //   },
    // },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const CardSettings = {
  className: '_centerAlign_ ',
  centerMode: false,
  infinite: true,
  autoplay: false,
  dots: true,
  mute: true,
  slidesToShow: 2,
  arrows: false,
  slidesToScroll: 1,
  swipeToSlide: true,
  cssEase: 'linear',
  swipe: true,
  touchThreshold: 10,
  useCss: true,
};

export function NewLandingPage() {
  const [doctorList, setDoctorList] = useState<Array<IDoctor>>([]);
  const [loading, setLoading] = useState(true);

  //TODO: fix this function on search
  async function fetchData() {
    try {
      const doctorList = await listItems(listDoctors, 'listDoctors', {
        limit: 500,
      });

      setDoctorList(
        doctorList.filter((doctor: IDoctor) => doctor.status === 'active')
      );
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="description"
          content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, the best prescription writing software of the country that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally or the online consultation. 
                    DoctorKoi also provides the best online consultation services in Bangladesh for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. 
                    Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception RX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. 
                    During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online."
        />
        <meta property="og:title" content="DoctorKoi" />
        <meta
          property="og:image"
          content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg"
        />
      </Helmet>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          <TopContent />
          <DoctorSearch doctorList={doctorList} />
          <SupportOptions />
          <SpecialityCards />
          <MiddleContent />
          <DoctorsSlider />
          <Offers />
          <Testimonials />
          <Partners />
          <CallandConnect />
          <BottomContent />
        </>
      )}
    </HelmetProvider>
  );
}

export function TopContent() {
  return (
    <>
      <Grid container justifyContent="center" paddingTop={2}>
        <Typography
          variant="h1"
          fontWeight="700"
          fontSize={{ xs: '17px', sm: '26px', md: '46px' }}
          fontFamily="HindiSiliguriRegular"
          textTransform="capitalize"
          color="#1E1E1E"
          letterSpacing={1}
        >
          Your Digital Healthcare Service Provider!
        </Typography>
      </Grid>
    </>
  );
}

export function DoctorSearch(props: SearchDoctorComponentProps) {
  const history = useHistory();

  const doctors = props.doctorList?.map((doctor: IDoctor) => ({
    id: doctor.id,
    category: 'Doctors',
    title: doctor.name,
  }));
  const speciality = props.doctorList?.map((doctor: IDoctor) => ({
    id: randomUUID(),
    category: 'Speciality',
    title: doctor.speciality,
  }));
  const uniqueSpeciality = uniqBy(speciality, 'title');
  const specialityList: Array<string> = uniqueSpeciality?.map(
    (item: IDoctorSearch) => item.title
  );

  const searchHandle = (value: IDoctorSearch) => {
    const formatedLowerCaseSpeciality = specialityList?.map((item: string) =>
      item.toLowerCase()
    );
    return formatedLowerCaseSpeciality.includes(value.title.toLowerCase())
      ? history.push(`/speciality=${value.title}`)
      : history.push(`/doctor/${value.id}`);
  };
  const options = [...uniqueSpeciality, ...doctors].map((option: any) => {
    let firstLetter = '';
    if (option.category !== '') {
      firstLetter = option?.title?.toUpperCase();
    }
    return {
      firstLetter,
      ...option,
    };
  });

  return (
    <MuiLayout>
      <Grid container justifyContent="center" paddingTop={2}>
        <Grid item xs={10.9} sm={7} xl={5} direction="row">
          <Autocomplete
            id="grouped-demo"
            options={options.sort(
              (a: any, b: any) =>
                b.title?.toLowerCase() - a.title?.toLowerCase()
            )}
            groupBy={(option) => option.category}
            getOptionLabel={(option) => {
              return option.title;
            }}
            onChange={(_, value) => {
              _.preventDefault();
              if (value) {
                searchHandle(value);
              }
            }}
            noOptionsText="Nothing Found!!!!"
            sx={root}
            loading={props.doctorList ? false : true}
            loadingText={props.doctorList ? 'Loading...' : 'No Results Found'}
            popupIcon={<></>}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Search sx={{ marginRight: 0, color: '#EF5B5F' }} />
                    ),
                    placeholder: 'Search By Speciality or Doctor Name',
                    style: {
                      color: '#000000',
                      fontFamily: 'HindiSiliguriRegular',
                      background: '#f5f5f5',
                    },
                    sx: {
                      borderRadius: '12px',
                      height: '38px',
                      fontSize: '15px',
                    },
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </MuiLayout>
  );
}

export function SupportOptions() {
  const history = useHistory();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showMedicineDialog, setShowMedicineDialog] = useState(false);
  const [showChamberAppointmentDialog, setShowChamberAppointmentDialog] =
    useState(false);

  const handleButtonClick = () => {
    setShowSnackbar(true);
    setShowMedicineDialog(false);
  };

  const handleMedicineClick = () => {
    setShowMedicineDialog(true);
    setShowSnackbar(false);
  };

  const SupportOptionCard = ({
    image,
    altText,
    title,
    subtitle,
    hoverText,
    onClick,
  }: {
    image: any;
    altText: string;
    title: string;
    subtitle: string;
    hoverText: string;
    onClick: () => void;
  }) => {
    return (
      <Tooltip title={hoverText} arrow>
        <Card
          sx={{
            position: 'relative',
            width: { sm: 150, md: 200, lg: 210, xl: 250 },
            borderRadius: '13px',
          }}
          elevation={3}
        >
          <CardActionArea onClick={(e) => onClick()}>
            <CardMedia
              component="img"
              image={image}
              alt={altText}
              sx={{
                width: { sm: 150, md: 200, lg: 210, xl: 250 },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { xs: 3 },
              }}
            />
            <CardContent sx={{ paddingY: 0, paddingBottom: 1, marginTop: -2 }}>
              <Typography
                variant="h6"
                component="h2"
                color="#474848"
                fontFamily="HindiSiliguriRegular"
                fontWeight="700"
                fontSize={{ xs: '12px', md: '18px' }}
              >
                {title}
              </Typography>
              <Stack
                direction="row"
                spacing={{ xs: 0.2, md: 1 }}
                alignItems="center"
              >
                <img src={Dot} alt="dot" width="10px" height="10px" />
                <Typography
                  variant="body2"
                  component="p"
                  color="#474848"
                  fontFamily="HindiSiliguriRegular"
                  fontWeight="700"
                  fontSize={{ xs: '10px', md: '12px' }}
                  height={{ lg: '14px' }}
                >
                  {subtitle}
                </Typography>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Tooltip>
    );
  };

  const SupportOptionSliderCard = ({
    image,
    altText,
    title,
    subtitle,
    hoverText,
    onClick,
  }: {
    image: any;
    altText: string;
    title: string;
    subtitle: string;
    hoverText: string;
    onClick: () => void;
  }) => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          minHeight: '220px',
          maxHeight: '300px',
          margin: '0 10px',
        }}
      >
        <Tooltip title={hoverText} arrow>
          <Card
            sx={{
              position: 'relative',
              borderRadius: '13px',
            }}
            elevation={3}
          >
            <CardActionArea onClick={(e) => onClick()}>
              <CardMedia
                component="img"
                image={image}
                alt={altText}
                sx={{
                  width: 250,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: { xs: 3 },
                }}
              />
              <CardContent sx={{ paddingY: 0, paddingBottom: 1, marginTop: -2 }}>
                <Typography
                  variant="h6"
                  component="h2"
                  color="#474848"
                  fontFamily="HindiSiliguriRegular"
                  fontWeight="700"
                  fontSize="13px"
                >
                  {title}
                </Typography>
                <Stack
                  direction="row"
                  spacing={0.8}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <img
                    src={Dot}
                    alt="dot"
                    width="13px"
                    height="13px"
                    style={{ margin: 0 }}
                  />
                  <Typography
                    variant="body2"
                    component="p"
                    color="#474848"
                    fontFamily="HindiSiliguriRegular"
                    fontWeight="700"
                    fontSize="10px"
                  >
                    {subtitle}
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            paddingTop={{ xs: 3, sm: 8 }}
            display={{ xs: 'none', sm: 'block' }}
          >
            <Stack direction="row" justifyContent="center" spacing={2}>
              <SupportOptionCard
                image={DocIcon}
                altText="Video Consultaion"
                title="Video Consultaion"
                subtitle="Online appointment"
                hoverText="Book Online Appointment"
                onClick={() => history.push('/speciality=Medicine')}
              />
              <SupportOptionCard
                image={ChamberLocationIcon}
                altText="Chamber Consultaion"
                title="Chamber Consultaion"
                subtitle="Chamber appointment"
                hoverText="Book Chamber Appointment"
                onClick={() => setShowChamberAppointmentDialog(true)}
              />
              <SupportOptionCard
                image={DrugsIcon}
                altText="Medicine"
                title="Medicine"
                subtitle="Order your medicine"
                hoverText="Order Your Medicine"
                onClick={handleMedicineClick}
              />
              <SupportOptionCard
                image={LabsampleIcon}
                altText="Lab Test Booking"
                title="Lab Test"
                subtitle="Coming Soon"
                hoverText="Coming Soon"
                onClick={handleButtonClick}
              />
            </Stack>
          </Grid>
          <Grid
            container
            justifyContent="center"
            paddingTop={3}
            display={{ xs: 'block', sm: 'none' }}
          >
            <Slider
              {...CardSettings}
              className="w-100-l w-100-m w-100 dt dt--fixed "
            >
              <SupportOptionSliderCard
                image={DocIcon}
                altText="Video Consultaion"
                title="Video Consultaion"
                subtitle="Online appointment"
                hoverText="Book Online Appointment"
                onClick={() => history.push('/speciality=Medicine')}
              />
              <SupportOptionSliderCard
                image={ChamberLocationIcon}
                altText="Chamber Consultaion"
                title="Chamber Consultaion"
                subtitle="Chamber appointment"
                hoverText="Book Chamber Appointment"
                onClick={() => setShowChamberAppointmentDialog(true)}
              />
              <SupportOptionSliderCard
                image={DrugsIcon}
                altText="Medicine"
                title="Medicine"
                subtitle="Order your medicine"
                hoverText="Order Your Medicine"
                onClick={handleMedicineClick}
              />
              <SupportOptionSliderCard
                image={LabsampleIcon}
                altText="Lab Test Booking"
                title="Lab Test"
                subtitle="Coming Soon"
                hoverText="Coming Soon"
                onClick={handleButtonClick}
              />
            </Slider>
          </Grid>
        </Grid>
        {showSnackbar && (
          <CustomSnackbar
            message="Service Is Coming Soon..."
            show={showSnackbar}
            setShow={setShowSnackbar}
          />
        )}
        {showMedicineDialog && (
          <MedicineServiceDialog
            show={showMedicineDialog}
            setShow={setShowMedicineDialog}
          />
        )}
        {showChamberAppointmentDialog && (
          <ChamberAppointmentDialog
            show={showChamberAppointmentDialog}
            setShow={setShowChamberAppointmentDialog}
          />
        )}
      </Grid>
    </>
  );
}

export function SpecialityCards() {
  const history = useHistory();

  const SpecialityCard = ({
    image,
    altText,
    title,
    onClick,
    display = {},
  }: {
    image?: any;
    altText?: string;
    title: string;
    onClick: () => void;
    display?: any;
  }) => {
    return (
      <Grid item sx={{ display }}>
        <Card
          sx={{
            position: 'relative',
            width: { xs: 140, xl: 180 },
            border: '1px solid #f88286',
            borderRadius: '16px',
          }}
          elevation={3}
        >
          <CardActionArea onClick={(e) => onClick()}>
            <CardMedia
              component="img"
              image={image}
              alt={altText}
              sx={{
                width: { xs: 140, xl: 180 },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { xs: 3 },
              }}
            />
            <CardContent sx={{ paddingY: 0, textAlign: 'center' }}>
              <Typography
                variant="h6"
                component="h2"
                color="#EC1E24"
                fontFamily="HindiSiliguriRegular"
                fontWeight="700"
                fontSize={{ xs: '15px', sm: '18px', xl: '20px' }}
                textTransform="capitalize"
              >
                {title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <Grid container justifyContent="center" paddingTop={{ xs: 8, sm: 10 }}>
        <Typography
          variant="h1"
          fontWeight="700"
          fontSize={{ xs: '23px', sm: '26px', md: '46px' }}
          fontFamily="HindiSiliguriRegular"
          color="#3B3B3B"
          textAlign="center"
          letterSpacing={1.8}
        >
          Specialities
        </Typography>
      </Grid>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12} md={10} lg={8}>
          <Grid
            container
            justifyContent="center"
            paddingTop={3}
            spacing={2}
            paddingX={{ sm: 8, lg: 5 }}
          >
            <SpecialityCard
              image={EntIcon}
              altText="ENT"
              title="ENT"
              onClick={() => history.push('/speciality=ENT')}
            />
            <SpecialityCard
              image={MedIcon}
              altText="Medicine"
              title="Medicine"
              onClick={() => history.push('/speciality=Medicine')}
            />
            <SpecialityCard
              image={UrologyIcon}
              altText="Urology"
              title="Urology"
              onClick={() => history.push('/speciality=Urology')}
            />
            <SpecialityCard
              image={SurgeryIcon}
              altText="Surgery"
              title="Surgery"
              onClick={() => history.push('/speciality=Surgery')}
            />
            <SpecialityCard
              image={RheumatologyIcon}
              altText="Rheumatology"
              title="Rheumatology"
              onClick={() => history.push('/speciality=Rheumatology')}
            />
            <SpecialityCard
              image={NutritonistIcon}
              altText="Nutritionist"
              title="Nutritionist"
              onClick={() => history.push('/speciality=Food & Nutrition')}
              display={{ xs: 'none', sm: 'block' }}
            />
            <SpecialityCard
              image={OrthopaedicIcon}
              altText="Orthopaedic"
              title="Orthopaedic"
              onClick={() => history.push('/speciality=Orthpaedic')}
              display={{ xs: 'none', sm: 'block' }}
            />
            <SpecialityCard
              image={OncologyIcon}
              altText="Oncology"
              title="Oncology"
              onClick={() => history.push('/speciality=Oncology')}
              display={{ xs: 'none', md: 'block' }}
            />
            <SpecialityCard
              image={NeurologyIcon}
              altText="Neurology"
              title="Neurology"
              onClick={() => history.push('/speciality=Neurology')}
              display={{ xs: 'none', md: 'block' }}
            />
            <SpecialityCard
              image={PediatricsIcon}
              altText="Pediatrics"
              title="Pediatrics"
              onClick={() => history.push('/speciality=Pediatrics')}
            />
            {/* <Grid item xs={6} sm={3} md={2.4}>
              <Card
                sx={{
                  position: 'relative',
                  width: {xs: 140, lg: 138, xl: 180},
                  height: {
                    xs: 162,
                    sm: 168.8,
                    xl: 212,
                  },
                  border: '1px solid #f88286',
                  borderRadius: '16px',
                }}
                elevation={3}
              >
                <CardActionArea onClick={() => history.push('/speciality')}>
                  <CardContent
                    sx={{
                      paddingY: {xs: 9, xl: 11},
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="h2"
                      color="#EC1E24"
                      fontFamily="HindiSiliguriRegular"
                      fontWeight="700"
                      fontSize={{xs: '15px', sm: '18px', xl: '20px'}}
                      textTransform="capitalize"
                    >
                      See More
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        pt={3}
        paddingBottom={{ xs: 7, sm: 10 }}
      >
        <Button
          sx={{
            background: 'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
            width: '180px',
            height: '40px',
            borderRadius: '16px',
          }}
          onClick={() => history.push('/speciality')}
        >
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize="18px"
            fontFamily="HindiSiliguriRegular"
            color="#fff"
            height="18px"
          >
            See More
          </Typography>
        </Button>
      </Grid>
    </>
  );
}

export function MiddleContent() {
  const history = useHistory();
  const globalState = useContext(Store);

  const handleClick = () => {
    globalState.state.signedIn
      ? history.push(`/speciality=Medicine`)
      : history.push(`/signin`);
  };

  const WorkFlowCard = ({ image, title }: { image: any; title: string }) => {
    return (
      <Grid item xs={3} sm="auto">
        <Card
          sx={{
            position: 'relative',
            width: { xs: 70, sm: 150, lg: 170 },
            backgroundColor: '#F7F7F7',
            boxShadow:
              'inset 4px 4px 4px rgba(0, 0, 0, 0.21), inset -4px -4px 4px #FFFFFF',
            borderRadius: '12px',
          }}
          elevation={3}
        >
          <CardMedia
            component="img"
            image={image}
            sx={{
              width: { xs: 70, sm: 150, lg: 170 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: { xs: 1 },
            }}
          />
        </Card>
        <Typography
          variant="h6"
          component="h2"
          color="#3B3B3B"
          fontFamily="HindiSiliguriRegular"
          fontWeight="700"
          fontSize={{ xs: '12px', md: '15px' }}
          textAlign="center"
          display={{ xs: 'none', sm: 'block' }}
          paddingTop={1}
        >
          {title}
        </Typography>
      </Grid>
    );
  };

  return (
    <Box sx={{ backgroundColor: '#f7f3f3' }}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize={{ xs: '23px', sm: '26px', md: '46px' }}
            fontFamily="HindiSiliguriRegular"
            color="#3B3B3B"
            textAlign="center"
            letterSpacing={1.8}
          >
            How it works...
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize={{ xs: '13px', sm: '16px' }}
            fontFamily="HindiSiliguriRegular"
            color="#3B3B3B"
            textAlign="center"
          >
            You can get an appointment of the best doctors with any specialist
            from anywhere in the country using just a smartphone, tablet, or
            laptop and any device, without any hassle
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            paddingTop={4}
            spacing={{ xs: 2, sm: 5 }}
            paddingX={2}
          >
            <WorkFlowCard image={CreateAccountIcon} title="Create Account" />
            <WorkFlowCard
              image={SelectSpecialityIcon}
              title="Select Speciality"
            />
            <WorkFlowCard
              image={DoctorConsultIcon}
              title="Consultation with a Doctor"
            />
            <WorkFlowCard
              image={DigitalPrescriptionIcon}
              title="Get Digital Prescripion"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" paddingY={3}>
        <Button
          sx={{
            background: 'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
            width: '180px',
            height: '40px',
            borderRadius: '16px',
          }}
          onClick={handleClick}
        >
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize="18px"
            fontFamily="HindiSiliguriRegular"
            color="#fff"
            height="18px"
          >
            Lets Start
          </Typography>
        </Button>
      </Grid>
    </Box>
  );
}

export function DoctorsSlider() {
  const history = useHistory();

  return (
    <>
      <Box
        paddingX={{ xs: 1, sm: 5, lg: 7, xl: 35 }}
        paddingTop={{ xs: 3, sm: 10 }}
      >
        <Slider {...settings} className="w-100-l w-100-m w-100 dt dt--fixed">
          {featuredDoctors.map((doctor, index) => (
            <SliderProfile doctor={doctor} />
          ))}
        </Slider>
      </Box>
      <Grid
        container
        justifyContent="center"
        paddingTop={4}
        paddingBottom={{ xs: 3, sm: 5 }}
      >
        <Button
          sx={{
            background: 'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
            width: '180px',
            height: '40px',
            borderRadius: '16px',
          }}
          onClick={() => history.push(`/speciality=Medicine`)}
        >
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize="18px"
            fontFamily="HindiSiliguriRegular"
            color="#fff"
            height="18px"
          >
            All Doctors
          </Typography>
        </Button>
      </Grid>
    </>
  );
}

export function Offers() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showMedicineDialog, setShowMedicineDialog] = useState(false);

  const handleButtonClick = () => {
    setShowSnackbar(true);
    setShowMedicineDialog(false);
  };

  const handleMedicineClick = () => {
    setShowMedicineDialog(true);
    setShowSnackbar(false);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        paddingTop={{ xs: 3, sm: 10 }}
        paddingBottom={{ xs: 3, sm: 5 }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize={{ xs: '23px', sm: '26px', md: '46px' }}
            fontFamily="HindiSiliguriRegular"
            color="#3B3B3B"
            textAlign="center"
            letterSpacing={1.8}
          >
            Offers
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={10.5} sm={7.3} lg={5.8} xl={4.6}>
              <Grid container justifyContent="center">
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="center"
                  spacing={{ xs: 1, sm: 5 }}
                >
                  <Card
                    sx={{
                      position: 'relative',
                      width: { xs: 280, lg: 300, xl: 350 },
                      height: {
                        xs: 259.67,
                        lg: 279.67,
                        xl: 329.67,
                      },
                    }}
                    elevation={3}
                  >
                    <CardActionArea onClick={handleButtonClick}>
                      <CardContent>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="h1"
                            fontWeight="700"
                            fontSize={{
                              xs: '17px',
                              lg: '21px',
                              xl: '25px',
                            }}
                            fontFamily="HindiSiliguriRegular"
                            color="#474848"
                            textAlign="center"
                          >
                            Get Doctor Koi Plus
                          </Typography>
                          <img src={CrounIcon} alt="crown" width="15%" />
                        </Stack>
                        <Stack>
                          <Typography
                            variant="body1"
                            fontWeight="700"
                            fontSize={{ xs: '12px', md: '16px' }}
                            fontFamily="HindiSiliguriRegular"
                            color="#EF5357"
                            textAlign="center"
                          >
                            1999{'\u09F3'}/month
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight="700"
                            fontSize={{ xs: '12px', md: '16px' }}
                            fontFamily="HindiSiliguriRegular"
                            color="#474848"
                            textAlign="center"
                          >
                            Unlimited Consultation & 10% Discount on Medcine
                          </Typography>
                        </Stack>
                      </CardContent>
                      <Box width="180" paddingLeft={{ xs: 10, lg: 11, xl: 14 }}>
                        <CardMedia
                          component="img"
                          image={DplusIcon}
                          sx={{
                            width: {
                              xs: '57%',
                              lg: '65%',
                              xl: '60%',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        />
                      </Box>
                    </CardActionArea>
                  </Card>
                  <Card
                    sx={{
                      position: 'relative',
                      width: { xs: 280, lg: 300, xl: 350 },
                      height: {
                        xs: 259.67,
                        lg: 279.67,
                        xl: 329.67,
                      },
                    }}
                    elevation={3}
                  >
                    <CardActionArea onClick={handleMedicineClick}>
                      <CardContent>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="h1"
                            fontWeight="700"
                            fontSize={{
                              xs: '17px',
                              lg: '21px',
                              xl: '25px',
                            }}
                            fontFamily="HindiSiliguriRegular"
                            color="#474848"
                            textAlign="center"
                          >
                            Express Medicine Delivery
                          </Typography>
                          <img src={CartIcon} alt="cart" width="15%" />
                        </Stack>
                      </CardContent>
                      <Box width="180" paddingLeft={{ xs: 5, sm: 5 }}>
                        <CardMedia
                          component="img"
                          image={MedicineDeliveryIcon}
                          sx={{
                            width: { xs: '80%' },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        />
                      </Box>
                    </CardActionArea>
                  </Card>
                  {showSnackbar && (
                    <CustomSnackbar
                      message="Service Is Coming Soon..."
                      show={showSnackbar}
                      setShow={setShowSnackbar}
                    />
                  )}
                  {showMedicineDialog && (
                    <MedicineServiceDialog
                      show={showMedicineDialog}
                      setShow={setShowMedicineDialog}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export function Testimonials() {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = videoIds.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const VideoCards = ({ vidId }: { vidId: any }) => {
    return (
      <Grid item xs={12}>
        <iframe
          style={{ borderRadius: '16px' }}
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${vidId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        paddingTop={{ xs: 3, sm: 5 }}
        paddingBottom={{ xs: 5, sm: 10 }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize={{ xs: '23px', sm: '26px', md: '46px' }}
            fontFamily="HindiSiliguriRegular"
            color="#3B3B3B"
            textAlign="center"
            letterSpacing={1.8}
          >
            Testimonials
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={{ xs: 0, sm: 2 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              size="large"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft
                sx={{ color: activeStep === 0 ? '#707070' : '#EC1E24' }}
                fontSize="large"
              />
            </IconButton>
            <Box sx={{ width: { xs: '70%', sm: '75%', lg: '50%', xl: '40%' } }}>
              <VideoCards vidId={videoIds[activeStep]} />
            </Box>
            <IconButton
              size="large"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <KeyboardArrowRight
                sx={{
                  color: activeStep === maxSteps - 1 ? '#707070' : '#EC1E24',
                }}
                fontSize="large"
              />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export function Partners() {
  const PartnerLogoCard = ({ logo }: { logo: any }) => {
    return (
      <Grid item xs={4} padding={1}>
        <Card sx={{ borderRadius: '12px', height: '100%' }} elevation={3} >
          <CardMedia
            component="img"
            image={logo}
            sx={{
              width: { xs: '80%' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: { xs: 3, sm: 4, lg: 6, xl: 8 },
              padding: 1,
            }}
          />
        </Card>
      </Grid>
    );
  };
  return (
    <Box sx={{ backgroundColor: '#f7f3f3' }} paddingBottom={2}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize={{ xs: '23px', sm: '26px', md: '46px' }}
            fontFamily="HindiSiliguriRegular"
            color="#3B3B3B"
            textAlign="center"
            letterSpacing={1.8}
          >
            Our Partners
          </Typography>
        </Grid>
        <Grid item xs={11} sm={7}>
          <Card
            sx={{
              position: 'relative',
              border: '1px solid #E92B2E',
              borderRadius: '23px',
              padding: 1,
            }}
            elevation={3}
          >
            <Grid container justifyContent="center" spacing={{ xs: 1, sm: 2 }}>
              <PartnerLogoCard logo={AdnLogo} />
              <PartnerLogoCard logo={BdangelsLogo} />
              <PartnerLogoCard logo={BdcomLogo} />
              <PartnerLogoCard logo={InceptaLogo} />
              <PartnerLogoCard logo={SbkLogo} />
              <PartnerLogoCard logo={SfkLogo} />
              <PartnerLogoCard logo={DrugLogo} />
              <PartnerLogoCard logo={AciLogo} />
              <PartnerLogoCard logo={RadiantLogo} />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export function CallandConnect() {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        paddingTop={{ xs: 3, sm: 10 }}
        paddingBottom={{ sm: 5 }}
        spacing={2}
      >
        <Grid item xs={12} sm={9} lg={6.5} xl={4.5}>
          <Grid container justifyContent="center" alignItems="center">
            <Stack
              direction="row"
              justifyContent="center"
              spacing={1}
              alignItems="center"
            >
              <Button
                sx={{
                  background: '#E92B2E',
                  borderRadius: '16px',
                  '&:hover': {
                    background: '#E92B2E',
                  },
                  height: { xs: 80, sm: 100, lg: 120 },
                  width: { xs: 120, sm: 180, lg: 200 },
                }}
                href="tel:+8809666777711"
              >
                <Stack>
                  <Typography
                    variant="h1"
                    fontWeight="700"
                    fontSize={{ xs: '12px', sm: '20px', lg: '23px' }}
                    fontFamily="HindiSiliguriRegular"
                    color="#ffffff"
                    textAlign="center"
                    textTransform="uppercase"
                  >
                    Call us now
                  </Typography>
                  <Typography
                    variant="h1"
                    fontWeight="700"
                    fontSize={{ xs: '12px', sm: '20px', lg: '23px' }}
                    fontFamily="HindiSiliguriRegular"
                    color="#ffffff"
                    textAlign="center"
                  >
                    +8809666777711
                  </Typography>
                </Stack>
              </Button>
              <Stack alignItems="center">
                <Button
                  style={{ margin: 5 }}
                  sx={{
                    background: '#ffffff',
                    border: '1px solid #E92B2E',
                    borderRadius: '16px',
                    height: { xs: 35, sm: 45, lg: 55 },
                    width: { xs: 150, sm: 280, lg: 310 },
                  }}
                  href="https://www.facebook.com/doctorkoiofficial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    variant="h1"
                    fontWeight="700"
                    fontSize={{ xs: '12px', sm: '18px', lg: '23px' }}
                    fontFamily="HindiSiliguriRegular"
                    color="#E92B2E"
                    textAlign="center"
                    textTransform="capitalize"
                  >
                    join our FB COMMUNITY
                  </Typography>
                </Button>
                <Button
                  style={{ margin: 5 }}
                  sx={{
                    background: '#ffffff',
                    border: '1px solid #E92B2E',
                    borderRadius: '16px',
                    height: { xs: 35, sm: 45, lg: 55 },
                    width: { xs: 150, sm: 280, lg: 310 },
                  }}
                  href="https://www.youtube.com/@doctorkoiofficial5972"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    variant="h1"
                    fontWeight="700"
                    fontSize={{ xs: '12px', sm: '18px', lg: '23px' }}
                    fontFamily="HindiSiliguriRegular"
                    color="#E92B2E"
                    textAlign="center"
                    textTransform="capitalize"
                  >
                    our YouTube Channel
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export function BottomContent() {
  const Stats = ({ numbers, title }: { numbers: string; title: string }) => {
    return (
      <Stack>
        <Typography
          variant="h1"
          fontWeight="700"
          fontSize={{ xs: '12px', sm: '16px', lg: '18px', xl: '20px' }}
          fontFamily="HindiSiliguriRegular"
          color="#EF5B5F"
          textAlign="center"
          textTransform="capitalize"
        >
          {numbers}
        </Typography>
        <Typography
          variant="h1"
          fontWeight="700"
          fontSize={{ xs: '12px', sm: '16px', lg: '18px', xl: '20px' }}
          fontFamily="HindiSiliguriRegular"
          color="#000000"
          textAlign="center"
          textTransform="capitalize"
        >
          {title}
        </Typography>
      </Stack>
    );
  };
  return (
    <>
      <Grid
        container
        justifyContent="center"
        paddingTop={{ xs: 3, sm: 5 }}
        paddingBottom={5}
        spacing={2}
      >
        <Grid item xs={11} sm={10} lg={7}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card
                sx={{
                  position: 'relative',
                  border: '1px solid #00000025',
                  borderRadius: '13px',
                }}
                elevation={3}
              >
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  spacing={{ xs: 2, sm: 5 }}
                  padding={{ xs: 1, sm: 3 }}
                >
                  <Stats numbers={'25000+'} title={'Happy Users'} />
                  <Stats numbers={'70+'} title={'Finest Specialist Doctors'} />
                  <Stats numbers={'20+'} title={'Specialities'} />
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export function ChamberAppointmentDialog(props: {
  show: boolean;
  setShow: (value: boolean) => void;
}): JSX.Element {
  const { show, setShow } = props;
  const phoneNumber = '09666777711';
  const handleCall = () => {
    window.location.href = 'tel:09666777711';
  };

  return (
    <Dialog
      open={show}
      keepMounted
      onClose={() => setShow(false)}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(253, 253, 253, 0.842)',
          backdropFilter: 'blur(5px)',
          borderRadius: '13px',
          padding: 2,
        },
      }}
    >
      <DialogContent>
        <Stack justifyContent="center" alignItems="center">
          <Typography
            fontFamily="HindiSiliguriRegular"
            textAlign="center"
            fontWeight="400"
            fontSize={{ xs: '15px', sm: '21px', p: 2 }}
          >
            To Book Chamber Appointment
            <br />
            Contact Our Helpline (9am - 8pm):&nbsp;
            <a
              href={`tel:${phoneNumber}`}
              style={{ color: 'black', fontWeight: 700 }}
            >
              {phoneNumber}
            </a>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            background: 'linear-gradient(180deg, #239614 0%, #55b430 100%)',
            width: '70px',
            height: '30px',
            borderRadius: '13px',
          }}
          onClick={handleCall}
          startIcon={<Call sx={{ color: '#fff', marginRight: -1 }} />}
        >
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize="14px"
            fontFamily="HindiSiliguriRegular"
            color="#fff"
          >
            Call
          </Typography>
        </Button>
        <Button
          sx={{
            background: 'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
            width: '70px',
            height: '30px',
            borderRadius: '13px',
          }}
          onClick={() => setShow(false)}
          startIcon={<Close sx={{ color: '#fff', marginRight: -1 }} />}
        >
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize="14px"
            fontFamily="HindiSiliguriRegular"
            color="#fff"
          >
            Close
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const MedicineServiceDialog = (props: {
  show: boolean;
  setShow: (value: boolean) => void;
}) => {
  const { show, setShow } = props;
  const phoneNumber = '09666777711';

  const handleCall = () => {
    window.location.href = 'tel:09666777711';
  };

  return (
    <>
      <Dialog
        open={show}
        keepMounted
        onClose={() => setShow(false)}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(253, 253, 253, 0.842)',
            backdropFilter: 'blur(5px)',
            borderRadius: '13px',
          },
        }}
      >
        <DialogContent>
          <Stack justifyContent="center" alignItems="center">
            <img src={MedicineDeliveryIcon} alt="cart" width="50%" />
            <Typography
              fontFamily="HindiSiliguriRegular"
              textAlign="center"
              fontWeight="400"
              fontSize={{ xs: '15px', sm: '21px' }}
            >
              To Order Medicine
              <br />
              Contact Our Helpline (9am - 8pm):&nbsp;
              <a
                href={`tel:${phoneNumber}`}
                style={{ color: 'black', fontWeight: 700 }}
              >
                {phoneNumber}
              </a>
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: 'linear-gradient(180deg, #239614 0%, #55b430 100%)',
              width: '70px',
              height: '30px',
              borderRadius: '13px',
            }}
            onClick={handleCall}
            startIcon={<Call sx={{ color: '#fff', marginRight: -1 }} />}
          >
            <Typography
              variant="h1"
              fontWeight="700"
              fontSize="14px"
              fontFamily="HindiSiliguriRegular"
              color="#fff"
            >
              Call
            </Typography>
          </Button>
          <Button
            sx={{
              background: 'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
              width: '70px',
              height: '30px',
              borderRadius: '13px',
            }}
            onClick={() => setShow(false)}
            startIcon={<Close sx={{ color: '#fff', marginRight: -1 }} />}
          >
            <Typography
              variant="h1"
              fontWeight="700"
              fontSize="14px"
              fontFamily="HindiSiliguriRegular"
              color="#fff"
            >
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const CustomSnackbar = (props: {
  show: boolean;
  setShow: (value: boolean) => void;
  message: string;
}) => {
  const { show, setShow, message } = props;
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={show}
        autoHideDuration={3000}
        onClose={() => setShow(false)}
        message={message}
      >
        <Alert
          variant="filled"
          severity="info"
          sx={{ width: '100%', fontWeight: 'bold' }}
          action={
            <IconButton
              size="small"
              color="inherit"
              onClick={() => setShow(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <Typography fontFamily="HindiSiliguriRegular" letterSpacing={1}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};
