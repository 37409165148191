import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';
import DkLogoWhite from '../assets/images/Dk logo.png';
import RenssoftLogo from '../assets/images/RenssoftLogo.png';
import SslBanner from '../assets/images/ssl commraze white.png';
import SslBannerMobile from '../assets/images/sslcommerzmobile.png';

export function Footer() {
  return (
    <Box>
      {/* foooter top section */}
      <Box sx={{ backgroundColor: '#3B3B3B' }}>
        <Grid container justifyContent='center' padding={1}>
          <Grid item xs={12}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              paddingX={{ sm: 5, lg: 20 }}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography
                  variant='h1'
                  fontSize={{ xs: '12px', sm: '15px' }}
                  fontFamily='HindiSiliguriRegular'
                  color='#ffffff'
                  textAlign='center'
                  textTransform='capitalize'
                >
                  Powred By
                </Typography>
                <img src={RenssoftLogo} alt='Renssoft Logo' width='40%' />
              </Stack>
              <Stack direction='row'>
                <IconButton
                  href='https://www.facebook.com/doctorkoiofficial'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FacebookIcon sx={{ color: '#ffffff' }} />
                </IconButton>
                <IconButton
                  href='https://www.linkedin.com/company/doctor-koi/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <LinkedInIcon sx={{ color: '#ffffff' }} />
                </IconButton>
                <IconButton
                  href='https://www.youtube.com/@doctorkoiofficial5972'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <YouTubeIcon sx={{ color: '#ffffff' }} />
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {/* footer middle section */}
      <Box sx={{ backgroundColor: '#454545' }}>
        <Grid container justifyContent='center' padding={1}>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                <Stack
                  direction='row'
                  justifyContent='space-around'
                  alignItems='flex-start'
                  paddingX={{ sm: 2, lg: 2 }}
                  spacing={{ xs: 1, sm: 2 }}
                >
                  <img src={DkLogoWhite} alt='DoktorKoi Logo' width='15%' />
                  <Stack alignItems='flex-start'>
                    <Stack paddingBottom={1}>
                      <Typography
                        variant='h1'
                        fontSize={{ xs: '10px', md: '18px' }}
                        fontFamily='HindiSiliguriRegular'
                        color='#ffffff'
                        textAlign='start'
                        textTransform='capitalize'
                      >
                        Useful links
                      </Typography>
                      <Divider
                        sx={{
                          backgroundColor: 'red',
                          height: 3,
                          margin: '1px 0',
                          border: 'none',
                        }}
                      />
                    </Stack>
                    <Stack alignItems='flex-start' spacing={{ sm: 1 }}>
                      <a style={{ color: 'white' }} href='/'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          Home
                        </Typography>
                      </a>
                      <a style={{ color: 'white' }} href='/aboutCompany'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          About Us
                        </Typography>
                      </a>
                      <a style={{ color: 'white' }} href='/careers'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          Career
                        </Typography>
                      </a>
                      <a style={{ color: 'white' }} href='/contactdoctorkoi'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          Contact
                        </Typography>
                      </a>
                    </Stack>
                  </Stack>
                  <Stack alignItems='flex-start'>
                    <Stack paddingBottom={1}>
                      <Typography
                        variant='h1'
                        fontSize={{ xs: '10px', md: '18px' }}
                        fontFamily='HindiSiliguriRegular'
                        color='#ffffff'
                        textAlign='start'
                        textTransform='capitalize'
                      >
                        Legal
                      </Typography>
                      <Divider
                        sx={{
                          backgroundColor: 'red',
                          height: 3,
                          margin: '1px 0',
                          border: 'none',
                        }}
                      />
                    </Stack>
                    <Stack alignItems='flex-start' spacing={{ sm: 1 }}>
                      <a style={{ color: 'white' }} href='/privacypolicy'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          Privacy Policy
                        </Typography>
                      </a>
                      <a style={{ color: 'white' }} href='/termsandconditions'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          Terms & Condition
                        </Typography>
                      </a>
                      <a style={{ color: 'white' }} href='/refundpolicy'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          Refund Policy
                        </Typography>
                      </a>
                      <a style={{ color: 'white' }} href='/datasecurity'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          Security
                        </Typography>
                      </a>
                    </Stack>
                  </Stack>
                  <Stack alignItems='flex-start'>
                    <Stack paddingBottom={1}>
                      <Typography
                        variant='h1'
                        fontSize={{ xs: '10px', md: '18px' }}
                        fontFamily='HindiSiliguriRegular'
                        color='#ffffff'
                        textAlign='start'
                        textTransform='capitalize'
                      >
                        Contact Us
                      </Typography>
                      <Divider
                        sx={{
                          backgroundColor: 'red',
                          height: 3,
                          margin: '1px 0',
                          border: 'none',
                        }}
                      />
                    </Stack>
                    <Stack alignItems='flex-start' spacing={{ sm: 1 }}>
                      <a style={{ color: 'white' }} href='tel:+8809666777711'>
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                        >
                          Hotline: +8809666777711
                        </Typography>
                      </a>
                      <a
                        style={{ color: 'white' }}
                        href='mailto:info@doctorkoi.com'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                        >
                          Email: info@Doctorkoi.com
                        </Typography>
                      </a>
                      <a
                        style={{ color: 'white' }}
                        href='https://goo.gl/maps/JJxfPKmENH5BSEsX6'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <Typography
                          variant='h1'
                          fontSize={{ xs: '10px', md: '16px' }}
                          fontFamily='HindiSiliguriRegular'
                          color='#ffffff'
                          textAlign='start'
                          textTransform='capitalize'
                          width={{ xs: '100px', sm: '200px', md: '280px' }}
                        >
                          Address: Flat - 8B, House - 16/A, Road - 7, Dhanmondi
                          R/A, Dhaka-1209. (Behind Anwar khan modern medical
                          college)
                        </Typography>
                      </a>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                marginBottom={1}
                display={{ xs: 'none', sm: 'block' }}
              >
                <img src={SslBanner} alt='Ssl Commerz Banner' />
              </Grid>
              <Grid
                item
                xs={12}
                display={{ xs: 'block', sm: 'none' }}
                paddingBottom={2}
                paddingLeft={12}
              >
                <img
                  src={SslBannerMobile}
                  width='60%'
                  alt='Ssl Commerz Banner'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* footer bottom section */}
      <Box
        sx={{
          backgroundColor: '#E92B2E',
          position: { xs: 'fixed', sm: 'absolute' },
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Grid container justifyContent='center'>
          <Grid item xs={12} padding={1}>
            <Stack direction='row' justifyContent='center'>
              <Typography
                variant='h1'
                fontSize={{ xs: '14px', sm: '16px' }}
                fontFamily='HindiSiliguriRegular'
                color='#ffffff'
                textAlign='center'
                textTransform='capitalize'
              >
                Copyright © 2023 |&nbsp;
              </Typography>
              <a style={{ color: 'white' }} href='/'>
                <Typography
                  variant='h1'
                  fontSize={{ xs: '14px', sm: '16px' }}
                  fontFamily='HindiSiliguriRegular'
                  color='#ffffff'
                  textAlign='center'
                  textTransform='capitalize'
                >
                  DOCTOR KOI
                </Typography>
              </a>
              <Typography
                variant='h1'
                fontSize={{ xs: '14px', sm: '16px' }}
                fontFamily='HindiSiliguriRegular'
                color='#ffffff'
                textAlign='center'
                textTransform='capitalize'
              >
                &nbsp;| RENSSOFT SOLUTIONS
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
