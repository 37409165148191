import React, { useEffect, useState } from 'react';
import Loader from 'common/Loader';
import { API, graphqlOperation } from 'aws-amplify';
import { listDoctors } from '../graphql/queries';
import { randomUUID } from '../util';
import { sortBy, uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import EntIcon from '../assets/images/ENT.png';
import UrologyIcon from '../assets/images/urology.png';
import SurgeryIcon from '../assets/images/Surgery.png';
import RheumatologyIcon from '../assets/images/Rheumatology.png';
import OncologyIcon from '../assets/images/Oncology.png';
import OrthopaedicIcon from '../assets/images/Orthopaedic.png';
import NeurologyIcon from '../assets/images/neurology.png';
import MedIcon from '../assets/images/Medicine.png';
import NutritonistIcon from '../assets/images/Nutritionist.png';
import CardiologyIcon from '../assets/images/Cardiology.png';
import ChestDiseaseIcon from '../assets/images/Chest Disease.png';
import DermatologyIcon from '../assets/images/Dermatology.png';
import GynocologistObstetricianIcon from '../assets/images/Gynecologist & Obstetrician.png';
import EndocrinologyIcon from '../assets/images/Hormone & Endocrinology.png';
import LiverGastrointestinalIcon from '../assets/images/Liver and Gastrointestinal.png';
import NephrologyIcon from '../assets/images/Nephrology  Kidney Diseases.png';
import PediatricsIcon from '../assets/images/Pediatric  Child Health.png';
import PsychiatryIcon from '../assets/images/Psychiatry.png';
import DeafultSpacialityIcon from '../assets/images/default.png';
import HormoneEndocrinologyIcon from '../assets/images/Hormone & Endocrinology.png';

export interface IDoctor {
  id?: string;
  name?: string;
  speciality?: string;
  score?: number;
  status?: string;
  info?: string;
  department?: string;
  designation?: string;
  chamber?: any;
  consultationDay?: any;
  visitFee?: any;
  institution?: string;
  degrees?: string;
  specialization?: string;
  yearsOfExperience?: string;
  bmdc?: string;
  profileSummary?: string;
  image?: string;
  file?: string;
  payment?: {
    appointmentId?: string;
    amount?: number;
    date?: string;
  };
  createdAt?: string;
  updatedAt?: string;
}
interface SearchDoctorComponentProps {
  doctorList: Array<IDoctor>;
}

const iconMap = {
  ENT: EntIcon,
  Medicine: MedIcon,
  medicine: MedIcon,
  Urology: UrologyIcon,
  Surgery: SurgeryIcon,
  Rheumatology: RheumatologyIcon,
  Nutritionist: NutritonistIcon,
  Orthopaedic: OrthopaedicIcon,
  Orthopedic: OrthopaedicIcon,
  Oncology: OncologyIcon,
  oncology: OncologyIcon,
  Neurology: NeurologyIcon,
  Cardiology: CardiologyIcon,
  ChestDiseases: ChestDiseaseIcon,
  Dermatology: DermatologyIcon,
  GynocologyObstetrician: GynocologistObstetricianIcon,
  GynocologySurgery: GynocologistObstetricianIcon,
  GynecologySurgery: GynocologistObstetricianIcon,
  Gynecology: GynocologistObstetricianIcon,
  Endocrinology: EndocrinologyIcon,
  LiverGastrointestinal: LiverGastrointestinalIcon,
  Gastronelogy: LiverGastrointestinalIcon,
  NephrologyKidneyDisease: NephrologyIcon,
  Kidney: NephrologyIcon,
  Nephrology: NephrologyIcon,
  Neprology: NephrologyIcon,
  Endocrinologists: HormoneEndocrinologyIcon,
  Pediatrics: PediatricsIcon,
  Psychiatry: PsychiatryIcon,
};

const defaultIcon = DeafultSpacialityIcon;

export function Speciality() {
  const [doctorList, setDoctorList] = useState<Array<IDoctor>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        const doctors = await API.graphql(
          graphqlOperation(listDoctors, { limit: 500 })
        );
        const { items } = doctors['data'].listDoctors;
        setDoctorList(
          items.filter((doctor: IDoctor) => doctor.status === 'active')
        );
        setLoading(false);
      } catch (err) {}
    }
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <SpecialityList doctorList={doctorList} />
      )}
    </>
  );
}

export function SpecialityList(props: SearchDoctorComponentProps) {
  const history = useHistory();

  const speciality = props.doctorList?.map((doctor: IDoctor) => ({
    id: randomUUID(),
    category: 'Speciality',
    title: doctor.speciality,
  }));

  const uniqueSpeciality = uniqBy(speciality, 'title');
  const sortedSpeciality = sortBy(uniqueSpeciality, 'title');

  const SpecialityCard = ({
    altText,
    title,
    onClick,
    hoverText,
  }: {
    altText: string;
    title: string;
    hoverText: string;
    onClick: () => void;
  }) => {
    const icon = iconMap[title] || defaultIcon;

    return (
      <Grid item >
        <Tooltip title={hoverText} arrow>
          <Card
            sx={{
              position: 'relative',
              width: { xs: 140, xl: 180 },
              border: '1px solid #f88286',
              borderRadius: '16px',
            }}
            elevation={3}
          >
            <CardActionArea onClick={(e) => onClick()}>
              <CardMedia
                component='img'
                image={icon}
                alt={altText}
                sx={{
                  width: { xs: 140, xl: 180 },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: { xs: 3 },
                }}
              />
              <CardContent sx={{ paddingY: 0, textAlign: 'center' }}>
                <Typography
                  variant='h6'
                  component='h2'
                  color='#EC1E24'
                  fontFamily='HindiSiliguriRegular'
                  fontWeight='700'
                  fontSize={{ xs: '15px', sm: '18px', xl: '20px' }}
                  textTransform='capitalize'
                  textAlign='center'
                  noWrap
                >
                  {title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        container
        justifyContent='center'
        spacing={4}
        paddingTop={5}
        paddingBottom={{ xs: 7, sm: 10 }}
      >
        <Grid item xs={12} xl={8}>
          <Grid
            container
            justifyContent='center'
            spacing={2}
            paddingX={{ sm: 3, md: 5, lg: 30, xl: 15 }}
          >
            {sortedSpeciality &&
              sortedSpeciality.map((d) => {
                const specialityName = d.title;
                return (
                  <SpecialityCard
                    title={specialityName}
                    altText={specialityName}
                    hoverText={specialityName}
                    onClick={() =>
                      history.push(`/speciality=${specialityName}`)
                    }
                  />
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
