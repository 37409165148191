import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import Profile from 'PublicSiteNewDesign/LandingPage/Profile';
import SearchDoctor from 'PublicSiteNewDesign/LandingPage/SearchDoctor';
import Loader from 'common/Loader';
import { isEqual } from 'lodash';
import { IDoctor } from 'model';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { listDoctors } from '../graphql/queries';
import a6 from '../resources/a6.png';

export function DoctorList() {
  const {id} = useParams<{id: string}>();
  const [allDoctorList, setAllDoctorList] = useState<Array<IDoctor>>([]);
  const [loading, setLoading] = useState(true);
  const [filteredDoctors, setFilteredDoctors] = useState<IDoctor[]>([]);

  const fetchDoctors = async (token?: string) => {
    try {
      const res = (await API.graphql(
        graphqlOperation(listDoctors, {limit: 5000, nextToken: token})
      )) as GraphQLResult;
      const items = res.data?.['listDoctors']?.items || [];
      const nextToken = res.data?.['listDoctors']?.nextToken;
      return {items, nextToken};
    } catch (err) {
      console.error('Exception Found while fetching doctors', err);
    }
  };

  async function fetchData(paramId: string) {
    let doctors = [];
    let res = await fetchDoctors();
    let nextToken = res.nextToken;
    doctors.push(...res.items);

    while (nextToken) {
      res = await fetchDoctors(nextToken);
      doctors.push(...res.items);
      nextToken = res.nextToken;
    }
    
    const activeDoctors = doctors.filter((d) => d.status === 'active');
    setAllDoctorList(activeDoctors);

    const filteredDoctorList = activeDoctors?.filter((doctor) =>
      isEqual(doctor.speciality, paramId)
    );
    setFilteredDoctors(filteredDoctorList);
    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div
      className="cf pb0 mb0 pb5-ns"
      style={{
        backgroundImage: `url(${a6})`,
        backgroundSize: 'auto',
        height: '100%',
      }}
    >
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <main className="center_img">
          <div className="mb2-ns mb0">
            <Box
              sx={{
                position: 'fixed',
                top: {xs: 60, sm: 90},
                width: '100%',
                zIndex: 999,
                backgroundColor: 'rgba(253, 253, 253, 0.226)',
                backdropFilter: 'blur(5px)',
              }}
            >
              {allDoctorList && (
                <SearchDoctor doctorList={allDoctorList || []} />
              )}
            </Box>
            <Grid container justifyContent="center" paddingTop={{xs: 3, sm: 8}}>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  paddingX={{lg: 3, xl: 25}}
                >
                  {filteredDoctors &&
                    filteredDoctors.map((d) => {
                      const doctor = {...d, ...JSON.parse(d.info)};
                      return (
                        <Stack>
                          <Profile {...doctor} />
                        </Stack>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </main>
      )}
    </div>
  );
}
