import {
  Button,
  Position,
  Spinner,
  Toaster,
} from '@blueprintjs/core';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { appointmentByPatienId } from '../graphql/queries';
import { Store } from '../Store';
import { randomUUID } from '../util';

// @ts-ignore
import { MetaTags } from 'react-meta-tags';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AppToaster = Toaster.create({
  position: Position.TOP,
  className: 'mt5-ns mt5',
});

interface Appointment {
  patientName: string;
  patientId: string;
  doctorId: string;
  doctorName: string;
  doctorUsername: string;
  dateTime: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  info: string;
  id: string;
}

interface ButtonGrp {
  id: string;
  isClicked: boolean;
}

export function AppointmentList() {
  let history = useHistory();
  const globalState = useContext(Store);
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[] | undefined>(
    undefined
  );
  const env = localStorage.getItem('env');

  const initiatePayment = async (appointment: Appointment) => {
    try {
      localStorage.removeItem('Appointment');
      localStorage.removeItem('paymentDetails');
      localStorage.removeItem('payLaterAppointment');
      const url = window.location.href.split('/');
      url[url.length - 1] = 'confirmappointment';
      const apiUrl = isEqual(env, 'dev')
        ? `https://afhm3tjzu9.execute-api.us-east-1.amazonaws.com/dev/api/initiatepayment`
        : `https://z25c1u45gh.execute-api.us-east-1.amazonaws.com/prod/api/initiatepayment`;

      const parsedAppointmentInfo = JSON.parse(appointment.info);
      const data = {
        totalAmount: parsedAppointmentInfo.fee,
        tranID: randomUUID(),
        clientName: appointment.patientName,
        clientPhone: appointment.patientId,
      };

      const res = await axios.post(apiUrl, {
        bodyData: data,
        env: env,
        url: url.join('/'),
      });

      return res;
    } catch (error) {
      console.log(error);
      throw new Error(`${JSON.stringify(error)}`);
    }
  };


  function differ(bookedTime: any) {
    let currentTime = moment();
    let dateA = moment(currentTime, 'YYYY-MM-DD HH:mm:ss'),
      dateB = moment(bookedTime, 'YYYY-MM-DD HH:mm:ss');

    let diffDays = dateB.diff(dateA, 'days');

    return diffDays;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    setLoading(true);
    const patientId = localStorage.getItem('phone');

    if (isEmpty(patientId) === false) {
      (
        API.graphql(
          graphqlOperation(appointmentByPatienId, {
            patientId: patientId,
            limit: 1200,
          })
        ) as Promise<any>
      ).then((result) => {
        let appointment = result.data.appointmentByPatienId.items;
        let sorted = appointment.sort(function (a: any, b: any) {
          let dateA: any = new Date(a.dateTime),
            dateB: any = new Date(b.dateTime);
          return dateB - dateA;
        });
        setAppointments(sorted);
        setLoading(false);
      });
    } else {
      history.push(`/signin`);
    }
  }, [globalState, history]);

  return (
    <>
      <MetaTags>
        <meta
          name='description'
          content='“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, a prescription writing software that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally. The company also provides online consultation services for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception DRX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online.'
        />
        <meta property='og:title' content='DoctorKoi' />
        <meta
          property='og:image'
          content='https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg'
        />
      </MetaTags>
      {loading ? (
        <Spinner size={Spinner.SIZE_LARGE} />
      ) : appointments?.length ? (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          py={5}
          px={2}
        >
          <Typography
            variant='h4'
            fontWeight='700'
            fontFamily='HindiSiliguriRegular'
            textTransform='capitalize'
            color='#E93541'
            letterSpacing={1}
            textAlign='center'
          >
            Your Appointments in Doctorkoi.com
          </Typography>
          {appointments.map((item) => (
            <>
              <Paper
                sx={{
                  bgcolor: 'background.paper',
                  boxShadow: 1,
                  borderRadius: 2,
                  p: 1,
                  m: 1,
                  height: 'auto',
                  width: '100%',
                }}
                elevation={3}
              >
                <Box
                  sx={{
                    borderRadius: 1,
                    p: 0.5,
                    bgcolor: grey[300],
                    width: '100%',
                  }}
                >
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Typography
                      fontWeight='700'
                      fontFamily='HindiSiliguriRegular'
                      textTransform='capitalize'
                      color={grey[900]}
                      letterSpacing={1}
                    >
                      {item.doctorName}
                    </Typography>
                    <Typography
                      fontFamily='HindiSiliguriRegular'
                      textTransform='capitalize'
                      color={grey[900]}
                      letterSpacing={1}
                    >
                      Appointment date:&nbsp;
                      <b>{moment(item.dateTime).format('ddd, DD MMM')}</b>
                    </Typography>
                    <Typography
                      fontFamily='HindiSiliguriRegular'
                      textTransform='capitalize'
                      color={grey[900]}
                      letterSpacing={1}
                    >
                      Time Left:&nbsp;
                      <b>
                        {differ(item.dateTime) < 0 ? (
                          'This Appointment Time is Over'
                        ) : (
                          <span>{`${differ(item.dateTime)} days`} </span>
                        )}
                      </b>
                    </Typography>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    color: 'text.primary',
                    width: '100%',
                  }}
                >
                  <Stack
                    display={{ xs: 'none', sm: 'block' }}
                    spacing={2}
                    pt={1}
                  >
                    <Stack direction='row' spacing={2}>
                      <Typography
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        color={grey[900]}
                        letterSpacing={1}
                      >
                        Appointment Created Time:&nbsp;
                        <b>{moment(item.createdAt).format('ddd, DD MMM')}</b>
                      </Typography>
                      <Typography
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        color={grey[900]}
                        letterSpacing={1}
                      >
                        Patient Number: <b>{item.patientId}</b>
                      </Typography>
                      {item.info ? (
                        JSON.parse(item.info).age ? (
                          <>
                            {JSON.parse(item.info).age.years ||
                            JSON.parse(item.info).age.days ||
                            JSON.parse(item.info).age.months ? (
                              <Typography
                                fontFamily='HindiSiliguriRegular'
                                textTransform='capitalize'
                                color={grey[900]}
                                letterSpacing={1}
                                textAlign='center'
                              >
                                Age:
                                <b>
                                  {JSON.parse(item.info).age.years
                                    ? ` ${
                                        JSON.parse(item.info).age.years
                                      } years`
                                    : null}
                                  {JSON.parse(item.info).age.months
                                    ? ` ${
                                        JSON.parse(item.info).age.months
                                      } months`
                                    : null}
                                  {JSON.parse(item.info).age.days
                                    ? ` ${JSON.parse(item.info).age.days} days`
                                    : null}
                                </b>
                              </Typography>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      <Typography
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        color={grey[900]}
                        letterSpacing={1}
                      >
                        Appointment Fee:{' '}
                        <b>
                          {isEmpty(item.info && JSON.parse(item.info).fee) ? (
                            `N/A`
                          ) : (
                            <span className='b'>{`${
                              JSON.parse(item.info).fee
                            } BDT`}</span>
                          )}
                        </b>
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                      <Typography
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        color={grey[900]}
                        letterSpacing={1}
                      >
                        Appointment Time:
                        <b>
                          {isEmpty(item.info && JSON.parse(item.info).time) ? (
                            `N/A`
                          ) : (
                            <>{`${JSON.parse(item.info).time}`}</>
                          )}
                        </b>
                      </Typography>
                      <Typography
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        color={grey[900]}
                        letterSpacing={1}
                      >
                        Appointment Status:{' '}
                        <>
                          {isEqual(item.status, 'CONFIRMED') ||
                          isEqual(item.status, 'PENDING') ||
                          isEqual(item.status, 'COMPLETED') ||
                          isEqual(item.status, 'RX_SENT') ? (
                            <Chip
                              label={
                                isEqual(item.status, 'CONFIRMED')
                                  ? 'CONFIRMED'
                                  : isEqual(item.status, 'PENDING')
                                  ? 'PENDING'
                                  : isEqual(item.status, 'COMPLETED')
                                  ? 'COMPLETED'
                                  : 'RX_SENT'
                              }
                              color={
                                isEqual(item.status, 'CONFIRMED')
                                  ? 'warning'
                                  : isEqual(item.status, 'PENDING')
                                  ? 'error'
                                  : isEqual(item.status, 'COMPLETED')
                                  ? 'success'
                                  : 'default'
                              }
                              style={{
                                fontFamily: 'HindiSiliguriRegular',
                                textTransform: 'capitalize',
                                letterSpacing: 1,
                                textAlign: 'center',
                                fontWeight: 'bold',
                              }}
                              size='small'
                            />
                          ) : undefined}
                        </>
                      </Typography>
                      {isEmpty(
                        item.info && JSON.parse(item.info).paymentFromSite
                      ) === false ? (
                        <>
                          {isEqual(
                            JSON.parse(item.info).paymentFromSite,
                            'No'
                          ) ? (
                            <>
                              Payment Status:{' '}
                              {differ(item.dateTime) >= 0 ? (
                                <Button
                                  disabled={false}
                                  intent='primary'
                                  text='Pay Now'
                                  onClick={async () => {
                                    const result = await initiatePayment(item);
                                    const { GatewayPageURL } = result.data.data;
                                    if (
                                      isEqual(
                                        result.data.data.status,
                                        'SUCCESS'
                                      )
                                    ) {
                                      localStorage.setItem(
                                        'payLaterAppointment',
                                        JSON.stringify(item)
                                      );
                                      window.location.assign(GatewayPageURL);
                                    } else {
                                      AppToaster.show({
                                        message: `Payment Initiation Failed! Please try again.`,
                                        icon: 'cross',
                                        intent: 'danger',
                                        timeout: 2000,
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <Button
                                  intent='primary'
                                  disabled={true}
                                  text='Pay Now'
                                  onClick={async () => {
                                    const result = await initiatePayment(item);
                                    const { GatewayPageURL } = result.data.data;
                                    if (
                                      isEqual(
                                        result.data.data.status,
                                        'SUCCESS'
                                      )
                                    ) {
                                      localStorage.setItem(
                                        'payLaterAppointment',
                                        JSON.stringify(item)
                                      );
                                      window.location.assign(GatewayPageURL);
                                    } else {
                                      AppToaster.show({
                                        message: `Payment Initiation Failed! Please try again.`,
                                        icon: 'cross',
                                        intent: 'danger',
                                        timeout: 2000,
                                      });
                                    }
                                  }}
                                />
                              )}{' '}
                            </>
                          ) : (
                            <>
                              <Typography
                                fontFamily='HindiSiliguriRegular'
                                textTransform='capitalize'
                                color={grey[900]}
                              >
                                Payment Status:{' '}
                                <Chip
                                  size='small'
                                  color='success'
                                  label='PAID'
                                  style={{
                                    fontFamily: 'HindiSiliguriRegular',
                                    textTransform: 'capitalize',
                                    letterSpacing: 1,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                  }}
                                />
                              </Typography>
                            </>
                          )}
                        </>
                      ) : undefined}
                    </Stack>
                  </Stack>
                  <Accordion sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        color={grey[900]}
                        fontWeight={700}
                      >
                        Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={2} pt={1}>
                        <Typography
                          fontFamily='HindiSiliguriRegular'
                          textTransform='capitalize'
                          color={grey[900]}
                        >
                          Appointment Created Date:&nbsp;
                          <b>{moment(item.createdAt).format('ddd, DD MMM')}</b>
                        </Typography>
                        <Typography
                          fontFamily='HindiSiliguriRegular'
                          textTransform='capitalize'
                          color={grey[900]}
                        >
                          Patient Number: <b>{item.patientId}</b>
                        </Typography>
                        {item.info ? (
                          JSON.parse(item.info).age ? (
                            <>
                              {JSON.parse(item.info).age.years ||
                              JSON.parse(item.info).age.days ||
                              JSON.parse(item.info).age.months ? (
                                <Typography
                                  fontFamily='HindiSiliguriRegular'
                                  textTransform='capitalize'
                                  color={grey[900]}
                                >
                                  Age:
                                  <b>
                                    {JSON.parse(item.info).age.years
                                      ? ` ${
                                          JSON.parse(item.info).age.years
                                        } years`
                                      : null}
                                    {JSON.parse(item.info).age.months
                                      ? ` ${
                                          JSON.parse(item.info).age.months
                                        } months`
                                      : null}
                                    {JSON.parse(item.info).age.days
                                      ? ` ${
                                          JSON.parse(item.info).age.days
                                        } days`
                                      : null}
                                  </b>
                                </Typography>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                        <Typography
                          fontFamily='HindiSiliguriRegular'
                          textTransform='capitalize'
                          color={grey[900]}
                        >
                          Appointment Fee:{' '}
                          <b>
                            {isEmpty(item.info && JSON.parse(item.info).fee) ? (
                              `N/A`
                            ) : (
                              <span className='b'>{`${
                                JSON.parse(item.info).fee
                              } BDT`}</span>
                            )}
                          </b>
                        </Typography>
                        <Typography
                          fontFamily='HindiSiliguriRegular'
                          textTransform='capitalize'
                          color={grey[900]}
                        >
                          Appointment Time:{' '}
                          <b>
                            {isEmpty(
                              item.info && JSON.parse(item.info).time
                            ) ? (
                              `N/A`
                            ) : (
                              <>{`${JSON.parse(item.info).time}`}</>
                            )}
                          </b>
                        </Typography>
                        <Typography
                          fontFamily='HindiSiliguriRegular'
                          textTransform='capitalize'
                          color={grey[900]}
                        >
                          Appointment Status:{' '}
                          <>
                            {isEqual(item.status, 'CONFIRMED') ||
                            isEqual(item.status, 'PENDING') ||
                            isEqual(item.status, 'COMPLETED') ||
                            isEqual(item.status, 'RX_SENT') ? (
                              <Chip
                                label={
                                  isEqual(item.status, 'CONFIRMED')
                                    ? 'CONFIRMED'
                                    : isEqual(item.status, 'PENDING')
                                    ? 'PENDING'
                                    : isEqual(item.status, 'COMPLETED')
                                    ? 'COMPLETED'
                                    : 'RX_SENT'
                                }
                                color={
                                  isEqual(item.status, 'CONFIRMED')
                                    ? 'warning'
                                    : isEqual(item.status, 'PENDING')
                                    ? 'error'
                                    : isEqual(item.status, 'COMPLETED')
                                    ? 'success'
                                    : 'default'
                                }
                                style={{
                                  fontFamily: 'HindiSiliguriRegular',
                                  textTransform: 'capitalize',
                                  textAlign: 'center',
                                  fontWeight: 'bold',
                                }}
                                size='small'
                              />
                            ) : undefined}
                          </>
                        </Typography>
                        {isEmpty(
                          item.info && JSON.parse(item.info).paymentFromSite
                        ) === false ? (
                          <>
                            {isEqual(
                              JSON.parse(item.info).paymentFromSite,
                              'No'
                            ) ? (
                              <>
                                Payment Status:{' '}
                                {differ(item.dateTime) >= 0 ? (
                                  <Button
                                    disabled={false}
                                    intent='primary'
                                    text='Pay Now'
                                    onClick={async () => {
                                      const result = await initiatePayment(
                                        item
                                      );
                                      const { GatewayPageURL } =
                                        result.data.data;
                                      if (
                                        isEqual(
                                          result.data.data.status,
                                          'SUCCESS'
                                        )
                                      ) {
                                        localStorage.setItem(
                                          'payLaterAppointment',
                                          JSON.stringify(item)
                                        );
                                        window.location.assign(GatewayPageURL);
                                      } else {
                                        AppToaster.show({
                                          message: `Payment Initiation Failed! Please try again.`,
                                          icon: 'cross',
                                          intent: 'danger',
                                          timeout: 2000,
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <Button
                                    intent='primary'
                                    disabled={true}
                                    text='Pay Now'
                                    onClick={async () => {
                                      const result = await initiatePayment(
                                        item
                                      );
                                      const { GatewayPageURL } =
                                        result.data.data;
                                      if (
                                        isEqual(
                                          result.data.data.status,
                                          'SUCCESS'
                                        )
                                      ) {
                                        localStorage.setItem(
                                          'payLaterAppointment',
                                          JSON.stringify(item)
                                        );
                                        window.location.assign(GatewayPageURL);
                                      } else {
                                        AppToaster.show({
                                          message: `Payment Initiation Failed! Please try again.`,
                                          icon: 'cross',
                                          intent: 'danger',
                                          timeout: 2000,
                                        });
                                      }
                                    }}
                                  />
                                )}{' '}
                              </>
                            ) : (
                              <>
                                <Typography
                                  fontFamily='HindiSiliguriRegular'
                                  textTransform='capitalize'
                                  color={grey[900]}
                                >
                                  Payment Status:{' '}
                                  <Chip
                                    size='small'
                                    color='success'
                                    label='PAID'
                                    style={{
                                      fontFamily: 'HindiSiliguriRegular',
                                      textTransform: 'capitalize',
                                      letterSpacing: 1,
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                    }}
                                  />
                                </Typography>
                              </>
                            )}
                          </>
                        ) : undefined}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Paper>
            </>
          ))}
        </Grid>
      ) : (
        <Typography
          variant='h4'
          fontWeight='700'
          fontFamily='HindiSiliguriRegular'
          textTransform='capitalize'
          color='#E93541'
          letterSpacing={1}
          textAlign='center'
        >
          No appointments found
        </Typography>
      )}
    </>
  );
}
