import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { ButtonGroup } from '@mui/material';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import {
  AppointmentIcon,
  SpecializationIcon,
  LocationIcon,
  ExperienceIcon,
} from '../../common/SvgIcons';
import MuiLayout from 'common/MuiLayout';
import { toString } from 'lodash';
import moment from 'moment';
import { useCurrentDimensions } from 'useCurrentDimensions';

export default function DoctorCard(props: any) {
  const doctor = props.doctor ? props.doctor : props;
  const {
    id,
    name,
    speciality,
    designation,
    image,
    department,
    consultationDay,
    institution,
    yearsOfExperience,
    specialization,
    chamber,
  } = doctor;
  const history = useHistory();
  const { height, width } = useCurrentDimensions();
  const isMobileView = height < 900 && width < 500;

  return (
    <MuiLayout>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        spacing={2}
        sx={{
          minHeight: '450px',
          // minWidth: '370px',
          maxHeight: '400px',
          margin: '0 10px',
        }}
      >
        <Typography
          variant='button'
          align='center'
          onClick={() => history.push(`/speciality=${speciality}`)}
          sx={{
            color: 'white',
            backgroundColor: '#E93541',
            padding: '2px auto',
            borderRadius: '5px 40px 0px 0px',
            display: 'block',
            width: '70%',
            fontWeight: 900,
          }}
        >
          {speciality || 'Speciality'}
        </Typography>
        <Card
          sx={{
            backgroundColor: '#F4F4F4',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px #858585',
            marginBottom: '5px',
            height: '400px',
          }}
        >
          <Grid container spacing={2} className='pa2'>
            <Grid item xs={5}>
              <Avatar
                className='slick-slider'
                alt='Doctor Image'
                src={image}
                variant='circular'
                sx={{
                  width: 130,
                  height: 130,
                  borderRadius: 50,
                  marginBottom: -4,
                  marginTop: 0,
                  border: '0px solid #E93541 !important',
                }}
              />
            </Grid>
            <Grid item xs={7} sx={{ marginTop: 2 }}>
              <Grid container spacing={2} className='mt1 pa2'>
                <Grid xs={1}>
                  <PersonIcon sx={{ color: '#f25449' }} />
                </Grid>
                <Grid xs={11}>
                  <Typography
                    marginLeft={1}
                    className='truncate  measure-narrow pl2 pl1-ns'
                    fontSize={16}
                    noWrap
                    fontWeight={900}
                    component='h4'
                  >
                    {name || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='mt1 pa2'>
                <Grid xs={1}>
                  <WorkIcon sx={{ color: '#f25449' }} />
                </Grid>
                <Grid xs={11}>
                  <Typography
                    fontSize={14}
                    marginLeft={1}
                    noWrap
                    fontWeight={900}
                    className='black-70truncate  measure-narrow pl2 pl1-ns'
                    mt={0.5}
                    variant='body1'
                    component='h6'
                  >
                    {designation || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='mt2 pa2'>
                <Grid xs={1}>
                  <CorporateFareOutlinedIcon sx={{ color: '#f25449' }} />
                </Grid>
                <Grid xs={11}>
                  <Typography
                    fontSize={13}
                    marginLeft={1}
                    noWrap
                    fontWeight={600}
                    className='black-60 truncate  measure-narrow pl2 pl1-ns'
                    mt={0.5}
                    variant='body1'
                    component='p'
                  >
                    {department || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='mt2 pa2'>
                <Grid xs={1}>
                  <LocationIcon />
                </Grid>
                <Grid xs={11}>
                  <Typography
                    marginLeft={1}
                    fontSize={isMobileView ? 12 : 13}
                    className='black-60 truncate  measure-narrow db pl2 pl1-ns'
                    fontWeight={600}
                    mt={0.5}
                    variant='body1'
                    component='p'
                  >
                    {institution || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CardContent>
            <Grid container spacing={2} className='mt1 pa2 '>
              <Grid>
                <SpecializationIcon />
              </Grid>
              <Grid>
                <Typography
                  fontSize={15}
                  fontWeight='bolder'
                  variant='body2'
                  component='p'
                >
                  Specialization
                </Typography>
              </Grid>
            </Grid>
            <Typography
              gutterBottom
              fontSize={13}
              fontWeight={600}
              noWrap
              variant='body1'
              className='pb2 black-70'
              component='p'
            >
              {specialization || 'N/A'}
            </Typography>
            <Grid
              container
              spacing={2}
              xs={12}
              sx={{ marginTop: 0.1 }}
              className='mt3 pa2'
            >
              <Grid sx={{ width: '43%', overflow: 'hidden' }}>
                <AppointmentIcon />
                <Typography
                  fontSize={13}
                  mt={0.5}
                  sx={{
                    marginTop: '4px',
                    marginLeft: '10px',
                  }}
                  variant='body1'
                  component='p'
                >
                  {toString(
                    consultationDay
                      ?.map((day: string) => moment(day, 'dddd').format('ddd'))
                      .join(', ')
                  ) || 'N/A'}
                </Typography>
              </Grid>
              <Grid sx={{ width: '57%', overflow: 'hidden' }}>
                <ExperienceIcon />
                <Typography
                  fontSize={13}
                  mt={0.5}
                  className='measure-narrow'
                  sx={{
                    marginTop: '4px',
                  }}
                  variant='body1'
                  component='p'
                >
                  {yearsOfExperience
                    ? `${yearsOfExperience} years of experience`
                    : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} className='mt2 ph2 pt3'>
              <Grid xs={1}>
                <LocationIcon />
              </Grid>
              <Grid xs={11}>
                <Typography
                  fontSize={13}
                  mt={0.5}
                  className='truncate measure-narrow'
                  sx={{
                    marginTop: '4px',
                  }}
                  variant='body1'
                  component='p'
                >
                  {chamber && (chamber[0]?.hospital || 'N/A')}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ float: 'right' }}>
            <ButtonGroup>
              <Button
                size='small'
                color='primary'
                onClick={() => history.push(`/doctor/${id}`)}
              >
                View Profile
              </Button>
              <Button
                size='small'
                color='error'
                variant='contained'
                onClick={() => history.push(`/appointment/${id}`)}
              >
                Book Appointment
              </Button>
            </ButtonGroup>
          </CardActions>
        </Card>
      </Grid>
    </MuiLayout>
  );
}
