import { ForProviders } from 'ForProviders/ForProviders';
import { PracticeProOverview } from 'ForProviders/PracticeProOverview';
import { Pricing } from 'ForProviders/Pricing';
import { NewLandingPage } from 'NewLandingPage/NewLandingPage';
import { Speciality } from 'NewLandingPage/Speciality';
import Amplify from 'aws-amplify';
import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Appointment } from './AppointmentPages/Appointment';
import { ConfirmAppointment } from './AppointmentPages/AppointmentConfirm';
import { AppointmentList } from './AppointmentShowPS/ShowList';
import { SignIn } from './Authentication/SignIn';
import { SignUp } from './Authentication/SignUp';
import { VerifyAccount } from './Authentication/VerifyPage';
import { Career } from './CareerPage/Career';
import { CareerOpening } from './CareerPage/CareerOpening';
import { JobDetails } from './CareerPage/JobDetails';
import { DoctorList } from './DoctorList/DoctorList';
import { DoctorProfile } from './DoctorProfile/DoctorProfile';
import { DoctorRegistration } from './DoctorRegistration/RegisteredDoctors';
import { ErrorPage } from './ErrorPage/ErrorPage';
import { DigitalRxDetails } from './ForProviders/DigitalRxDetails';
import { DigitalRxOverview } from './ForProviders/DigitalRxOverview';
import { AboutUs } from './LegalPolicies/AboutUs';
import { ContactUs } from './LegalPolicies/ContactUs';
import { DataSecurity } from './LegalPolicies/DataSecurity';
import { Privacy } from './LegalPolicies/Privacy';
import { RefundPolicy } from './LegalPolicies/RefundPolicy';
import { TermsCondition } from './LegalPolicies/TermsCondition';
import { Footer } from './NewLandingPage/Footer';
import { Navbar } from './NewLandingPage/Navbar';
import { PaymentFailure } from './PaymentPages/PaymentFailure';
import { PaymentSuccess } from './PaymentPages/PaymentSuccess';
import { Store } from './Store';
import awsConfig from './aws-exports';
import { LoginDigitalRx } from './digitalrx/Login';

Amplify.configure(awsConfig);

function Content(props: { styles: any }) {
  const contentStyle = {
    paddingRight: 2,
    paddingLeft: 2,
  };

  return (
    <div style={contentStyle}>
      <Switch>
        {/* <React.Suspense fallback={<div>loading...</div>}> */}
        <Route path='/doctor/:id' exact={true} component={DoctorProfile} />
        <Route path='/signup' exact={true} component={SignUp} />
        <Route path='/signin' exact={true} component={SignIn} />
        <Route path='/digitalrx' exact={true} component={DigitalRxOverview} />
        <Route
          path='/practicepro'
          exact={true}
          component={PracticeProOverview}
        />
        <Route
          path='/forproviders'
          exact={true}
          component={ForProviders}
        />
        <Route
          path='/pricing'
          exact={true}
          component={Pricing}
        />
        <Route path='/datasecurity' exact={true} component={DataSecurity} />
        <Route path='/contactdoctorkoi' exact={true} component={ContactUs} />
        <Route
          path='/confirmappointment'
          exact={true}
          component={ConfirmAppointment}
        />
        <Route
          path='/verification/:id'
          exact={true}
          component={VerifyAccount}
        />
        <Route
          path='/appointmentlist'
          exact={true}
          component={AppointmentList}
        />
        <Route path='/speciality=:id' exact={true} component={DoctorList} />
        <Route path='/appointment/:id' exact={true} component={Appointment} />
        <Route path='/jobdetails/:id' exact={true} component={JobDetails} />
        <Route path='/aboutCompany' exact={true} component={AboutUs} />
        <Route path='/privacypolicy' exact={true} component={Privacy} />
        <Route path='/refundpolicy' exact={true} component={RefundPolicy} />
        <Route path='/career/:id' exact={true} component={Career} />
        <Route path='/careers' exact={true} component={CareerOpening} />
        <Route
          path='/termsandconditions'
          exact={true}
          component={TermsCondition}
        />
        <Route path='/paymentsuccess' exact={true} component={PaymentSuccess} />
        <Route path='/paymentfailure' exact={true} component={PaymentFailure} />
        <Route path='/' exact={true} component={NewLandingPage} />
        <Route path='/speciality' exact={true} component={Speciality} />
        <Route
          path='/freeregistration'
          exact={true}
          component={DoctorRegistration}
        />
        <Route
          path='/digitalrxdetails'
          exact={true}
          component={DigitalRxDetails}
        />
        <Route
          path='/digitalrx/login'
          exact={true}
          component={LoginDigitalRx}
        />
        <Redirect from='/auth/login' to='/practice' />
        <Route path='*' exact={true} component={ErrorPage} />
        {/* </React.Suspense> */}
      </Switch>
    </div>
  );
}

function AppComponent() {
  const styles = {
    topBarHeight: 60,
    footerMenuHeight: 15,
  };

  return (
    <HelmetProvider>
      <Helmet>
        <link rel='canonical' href='https://doctorkoi.com/' />
        <meta
          name='description'
          content='“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, the best prescription writing software of the country that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally or the online consultation. 
                    DoctorKoi also provides the best online consultation services in Bangladesh for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. 
                    Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception RX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. 
                    During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online.'
        />
        <meta property='og:title' content='DoctorKoi' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://doctorkoi.com/' />
      </Helmet>
      <div
        style={{
          backgroundColor: `rgba(255, 255, 255, 0.05)`,
          minHeight: '88vh',
          position: 'relative',
        }}
      >
        <Navbar />
        <Content styles={styles} />
        <Footer />
      </div>
    </HelmetProvider>
  );
}

function App() {
  const globalState = useContext(Store);
  localStorage.setItem(
    'env',
    awsConfig.aws_user_files_s3_bucket.split('-')[1] || 'dev'
  );
  useEffect(() => {
    if (
      localStorage.getItem('signedIn') === 'true' &&
      globalState.state.signedIn === false
    ) {
      const { dispatch } = globalState;
      dispatch({ type: 'SIGN_IN' });
    }
  }, [globalState]);

  return <AppComponent />;
}

export default App;
