import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { createUser } from '../graphql/mutations';
import { getUser } from '../graphql/queries';
import { Toaster, Position, Button } from '@blueprintjs/core';
import { validatePhoneNumber } from '@doctorkoi/utils';
import a6 from '../resources/a6.png';

export const AppToaster = Toaster.create({
  position: Position.TOP,
  className: 'mt5-ns mt5',
});

export function SignUp() {
  let history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');

  const signup = async (name: string, phone: string) => {
    try {
      const result: any = await API.graphql(
        graphqlOperation(createUser, {
          input: {
            id: phone,
            name: name,
            verified: 'FALSE',
          },
        })
      );
      return result.data.createUser;
    } catch (e) {
      throw new Error(`error creating user: ${e}`);
    }
  };

  const checkUserExist = async (phone: string) => {
    try {
      const result: any = await API.graphql(
        graphqlOperation(getUser, {
          id: phone,
        })
      );
      return result.data.getUser;
    } catch (e) {
      throw new Error(`${JSON.stringify(e)}`);
    }
  };

  return (
    <div
      className='pb7'
      style={{
        backgroundImage: `url(${a6})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
      }}
    >
      <div
        className='flex justify-center center-ns tc w-90-ns w-80'
        style={{ marginLeft: '40px', marginRight: '40px' }}
      >
        <div className='br2 pb3 flex-column shadow-1 mt5-ns mt4 mt0 bg-white w-90 w-30-l w-50-m'>
          <h1
            className='tc f3 '
            style={{
              color: '#3b3b3b',
              fontFamily: 'GothamBold',
              listStyle: 'none',
            }}
          >
            Sign Up
          </h1>
          <form
            className='pa2'
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            <div className='flex flex-column mb3 mr1 w-100 w-100-ns'>
              <label className='pl2 mb2 f5 tl'> Name </label>
              <input
                className='pl3 br3 pa2'
                type='text'
                placeholder='Name'
                required
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='flex flex-column mb4 mr1 w-100 w-100-ns'>
              <label className='pl2 mb2 f5 tl'> Mobile </label>
              <input
                className='pl3 br3 pa2'
                type='text'
                placeholder='01XXXXXXXXX'
                name='mobile'
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>

            <div className=' tc'>
              <Button
                className=' w-90 w-100-ns f4 f4-ns no-underline bg-lightest-blue black bg-animate 
                 inline-flex items-center pa3 br3 ba border-box b mt1 '
                disabled={
                  validatePhoneNumber(mobile) && name.length ? false : true
                }
                text={'Sign Up'}
                style={{
                  width: '50%',
                  // backgroundColor: "#cdecfe",
                  color: 'white',
                  // height: "55px",
                  borderRadius: '8px',
                  fontSize: '1.25rem',
                  fontWeight: 700,
                }}
                loading={loading}
                // fill={true}
                large={true}
                type='submit'
                intent={'danger'}
                onClick={(e: any) => {
                  e.preventDefault();
                  setLoading(true);
                  let isValidPhone = validatePhoneNumber(mobile);
                  if (isValidPhone === false) return;

                  checkUserExist(mobile).then((res) => {
                    if (res === null) {
                      signup(name, mobile)
                        .then((res) => {
                          setLoading(false);
                          AppToaster.show({
                            message: `Signed Up Successfully`,
                            icon: 'tick-circle',
                            intent: 'success',
                            timeout: 5000,
                          });
                          history.push('/signin');
                        })
                        .catch((err) => {
                          setLoading(false);
                          AppToaster.show({
                            message: `Error signing up`,
                            intent: 'danger',
                            timeout: 5000,
                          });
                        });
                    } else {
                      if (res) {
                        if (res.id === mobile) {
                          setLoading(false);
                          AppToaster.show({
                            message: `User Already Exists!`,
                            intent: 'warning',
                            timeout: 5000,
                          });
                          history.push('/signin');
                        } else {
                          AppToaster.show({
                            message: `Something went wrong!`,
                            intent: 'danger',
                            timeout: 5000,
                          });
                        }
                      }
                    }
                  });
                }}
              />
            </div>
          </form>
        </div>
      </div>
      {/* <SocialMedia /> */}
    </div>
  );
}
