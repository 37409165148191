import React, { useEffect } from 'react';
import { Divider, Icon } from '@blueprintjs/core';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';

import './DataSecurity.css'

export function DataSecurity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTags>
        <meta name="description" content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, a prescription writing software that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally. The company also provides online consultation services for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception DRX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online." />
        <meta property="og:title" content="DoctorKoi" />
        <meta property="og:image" content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg" />
      </MetaTags>
      <h1 className="tc f3-l f3-m f4 underline pt4-l pt4-m pt3">How much SECURE you are with our system:</h1>

      {/* <div className="dt flex flex-row-ns flex-column-reverse items-center">
      <div className="fl w-100 w-10-l w-10-m tl dtc-l dtc-m "> </div>
      <div className="fl w-100 w-40-l w-10-m tc dtc-l dtc-m "> 
        <div className="tl mt5-l mt5-m mt0 pl3 pl0-m pl0-l f3-l f3-m f5 items-center">
          <li>Safeguard Data Privacy Policies</li>
          <li>Software Up to Date</li>
          <li>Safe Computing</li>
          <li>Instant Data Bakcup</li>
          <li>Offline-online both supported</li>
        </div>
      </div>
      <div className="fl w-100 w-50-l w-10-m tc dtc-l dtc-m "> 
        <img
          className="pa2-l pa2-m pa3"
          alt="your data is secured with us"
          src={require('../resources/datasecurity.jpg')}
        />
      </div>
    </div> */}

      <section className="_bulletSection_ mb3">
        <div className="dt flex-l flex-m flex-row-l flex-row-m flex-column-reverse items-center ">
          <div className="fl w-100 w-50-l w-100-m tl">
            <div className="cf">
              <div className="fl w-100 w-20-l w-100-m tc white"> .</div>
              <div className="fl w-100 w-80-l w-100-m tl pl3 pl3-m pl0-l f3-l f4-m f5 pt6-l pt5-m pt2 items-center">
                <li>Safeguard Data Privacy Policies</li>
                <li>Software Up to Date</li>
                <li>Safe Computing</li>
                <li>Instant Data Bakcup</li>
                <li>Offline-online both supported</li>
              </div>
            </div>
          </div>
          <div className="fl w-100 w-50-l w-100-m  tc">
            <img
              className="pa4-l pa4-m pa3"
              alt="your data is secured with Doctorkoi.com system"
              src={require('../resources/datasecurity.jpg')}
            />
          </div>
        </div>
      </section>

      <section className="_dataSecurity_ mb3">
        <div className="cf">
          <div className="fl pa3 w-100 w-50-l w-100-m tc">
            <div className="w-80-l w-80-m w-100  h5-m center-l center-m justify-center tl bp3-card mb3" >
              <span className="tl f3 b">Data Security for Patients:</span>
              <div className="_texts_ cf">
                <div className="w-100 tl f5 fw3">
                  <Divider className="bw4 bg-red" /> <br />
                  <span>- </span> <span>{'    '}</span> <span>We do not share your data with any third Party</span><br />
                  <span>- </span> <span>{'    '}</span> <span>Each patient can see their service status only ( E.g: Their Particular appointment  info details)</span><br />
                  <span>- </span> <span>{'    '}</span> <span>Patient’s cannot  see  each other’s data.</span><br />
                  <span>- </span> <span>{'    '}</span> <span>We follow the 2FA (Two Factor Authentication) for the ‘Sign-In Process’ with your Phone number to detect the original you to Sign in.</span><br />
                </div>
              </div>
            </div>
            <br />
          </div>
          <div className="fl pa3 w-100 w-50-l w-100-m tc">
            <div className="w-80-l w-80-m w-100 h5-m center-l center-m justify-center tl bp3-card mb3" >
              <span className="tl f3 b">Data Security for Doctors:</span>
              <div className="_texts_ cf">
                <div className="w-100 tl f5 fw3">
                  <Divider className="bw4 bg-red" /> <br />
                  <span>- </span> <span>{'    '}</span> <span>Data is not shared with any third Party</span><br />
                  <span>- </span> <span>{'    '}</span> <span>Each of the doctor’s has the access to their data only</span><br />
                  <span>- </span> <span>{'    '}</span> <span>We follow the most secured data policies in terms protecting the doctor’s data.</span><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="_sevenPointSection_ mb3">
        <div className="cf">
          <div className="fl w-100 w-100-l w-100-m tc pa4">
            <span className="f3 b tc  underline"> All of our products are designed to protect data security and privacy</span>
            {/* <Divider className="bw4 bg-red _divider_" /> <br/> */}
          </div>
          <div className="flex justify-center  fl w-100 w-50-l w-100-m pa3 tc">
            <div className="fl pa3 w-90-l justify-center bp3-card mb3  ">
              <img
                src={require('../resources/server.png')}
                alt="Doctorkoi.com servers are protected"
                className="mw4 _sevenImg_"
              />
              <div className="tc f5 b">
                Securely Hosted on Amazon Cloud Server
              </div>
              <br />
              <div className="tl f5 lh-copy">
                <li>Biggest Environment of Security Accomplices and Solutions</li>
                <li>Inherit the Most Comprehensive Security and Compliance Controls</li>
                <li>Built with the Most Noteworthy Measures for Security and Data Security</li>
              </div>
            </div>
          </div>

          <div className="flex justify-center  fl w-100 w-50-l w-100-m pa3 tc">
            <div className="fl pa3 w-90-l justify-center  bp3-card mb3  ">
              <img
                src={require('../resources/passwordprotected.jpeg')}
                alt="pass protection of Doctorkoi.com is very secure"
                className="mw4 _sevenImg_"
              />
              <div className="tc f5 b">
                Password is protected using RSA signature with SHA-256 Algorithm
              </div>
              <br />
              <div className="tl f5 lh-copy">
                <li>It doesn’t have any known vulnerabilities that make it vulnerable</li>
                <li>It’s a very much secured and trusted industry standard</li>
                <li>The avalanche effect</li>
                <li>Collisions are highly unlikely</li>
              </div>
            </div>
          </div>

          <div className="flex justify-center  fl w-100 w-50-l w-100-m pa3 tc">
            <div className="fl pa3 w-90-l justify-center  bp3-card mb3  ">
              <Icon
                icon="database"
                iconSize={60}
                color="#E93541"
              />
              <div className="tc f5 b">
                Protected Database by Amazon
              </div>
              <br />
              <div className="tl f5 lh-copy">
                <li>Password, Kerberos, and IAM database authentication use different methods of authenticating
                to the database.
                </li>
                <li>In this way, a particular user can log in to a database using only one authentication method.</li>
              </div>
            </div>
          </div>

          <div className="flex justify-center  fl w-100 w-50-l w-100-m pa3 tc">
            <div className="fl pa3 w-90-l justify-center  bp3-card mb3  ">
              <img
                src={require('../resources/bckup.png')}
                alt="Data is always backed up by the server of Doctorkoi.com"
                className="mw4 _sevenImg_"
              />
              <div className="tc f5 b">
                Regular Backup by Amazon
              </div>
              <br />
              <div className="tl f5 lh-copy">
                <li>Data durability</li>
                <li>Back up all types of data</li>
                <li>Manage, access, detect irregular activities, encrypt data, and perform audits. </li>
              </div>
            </div>
          </div>

          <div className="flex justify-center  fl w-100 w-50-l w-100-m pa3 tc">
            <div className="fl pa3 w-90-l justify-center  bp3-card mb3  ">
              <Icon
                icon="automatic-updates"
                iconSize={60}
                color="#E93541"
              />
              <div className="tc f5 b">
                Software is up to date with cutting edge technologies
              </div>
              <br />
              <div className="tl f5 lh-copy">
                <li>According to the industry practices, the software depends on other libraries, so does our system is.
                  We ensure always up-to-date with all the dependencies and diminish any blemishes</li>
                <li>Inherit the Most Comprehensive Security and Compliance Controls</li>
                <li>Built with the Highest Standards for Privacy and Data Security</li>
              </div>
            </div>
          </div>

          <div className="flex justify-center  fl w-100 w-50-l w-100-m pa3 tc">
            <div className="fl pa3 w-90-l justify-center  bp3-card mb3  ">
              <Icon
                icon="data-lineage"
                iconSize={60}
                color="#E93540"
                className=" mb2"
              />
              <div className="tc f5 b">
                Use Base64-encoded JSON to call the server from end-user
              </div>
              <br />
              <div className="tl f5 lh-copy">
                <li>We are using the Base64-encoded JSON token to make any request from the end-user to the remote
                   database which guarantees no one else can get your data.</li>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="_seventhdiv_ flex justify-center mw6 mw7-l mw7-m center bp3-card pa3 mb5">
        <div className="pa3 w-100  mb3 h-auto justify-center flex flex-column ">
          <Icon
            icon="cloud-upload"
            iconSize={60}
            color="#E93541"
            className="center-l center-m mb2 center"
          />
          <div className="tc f5 b">
            Following MicroService architecture to Scale the system and ensure 99.99% uptime
          </div>
          <br />
          <div className="tl f5 lh-copy">
            <li>We developed our system following microservice architecture, where the industry is looking for.</li>
            <li>No matter how many requests we are getting per second. Our system can automatically scale when it needs to handle more requests which ensure 0 downtimes of the server.</li>
          </div>
        </div>
      </section>
    </>
  )
}