import React from 'react';
import { createTheme } from '@mui/material/styles';
import { CssBaseline, ThemeProvider } from '@mui/material';
const HindiSiliguriRegular = require('../Fonts/HindSiliguri-Regular.ttf');

//@ts-ignore
export default function MuiLayout({ children }) {
    const theme = createTheme({
        typography: {
          fontFamily: 'HindiSiliguriRegular, Arial',
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: `
              @font-face {
                font-family: 'HindiSiliguriRegular';
                font-style: normal;
                font-display: swap;
                font-weight: 200;
                src: src: local('HindiSiliguriRegular'), local('Regular'), url(${HindiSiliguriRegular}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
              }
            `,
          },
        },
      });
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
    </ThemeProvider>
  )
}
