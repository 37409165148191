import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@blueprintjs/core";

import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

import "./DigitalRXoverview.css";
import { DigitalRxDetails } from "./DigitalRxDetails";
import { Grid, Typography } from "@mui/material";

const buttonStyle = {
  background: "linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)",
  width: "180px",
  height: "40px",
  borderRadius: "15px",
  transition: "background 0.3s ease",
};

export function DigitalRxOverview() {
  const featuredDoctors = [
    {
      name: "Dr. Abdus Shakur Khan",
      quote:
        "It often gets difficult to understand the manual handwriting prescription by the patients, considering the fact , I think Printed prescription (Digital Prescription)  is far better than manual handwriting prescription.",
      image: require(`../resources/quoteDoctor/quoteDoctor1.jpg`),
    },
  ];

  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    slidesToShow: 1,
    speed: 5000,
    adaptiveHeight: true,
    autoplay: true,
    arrows: false,
    dots: true,
    arrow: false,
  };

  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pb2">
      <section
        className="_rxMainDiv_ dt dt--fixed w-100 db mb3"
        style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
      >
        <div className="db dtc-l dtc-m dn w-100-l w-100-m w-100 ">
          <div className="dt dt--fixed">
            <img
              src={require("../resources/digitalRXoverview/rxlandingImg.png")}
              alt="DigitalRx Smart Prescription"
              className="_landingImageDigitalrx_ db"
            />
            <img
              src={require("../resources/mobileBanner.png")}
              alt="DigitalRx Smart Prescription: Country's no 1 prescription writing software"
              className="_landingImageDigitalrxMobile_ db"
            />
          </div>
          <Grid container justifyContent="center" pb={5}>
            <Button
              style={buttonStyle}
              onClick={() => history.push(`/freeregistration`)}
            >
              <Typography
                variant="h1"
                fontSize="18px"
                fontFamily="HindiSiliguriRegular"
                color="#fff"
                height="20px"
              >
                Register Now!
              </Typography>
            </Button>
          </Grid>
          <DigitalRxDetails />
        </div>
      </section>

      <section className="_docQuote_ w-100 db dt dt--fixed">
        <div className=" tc dtc-l dtc-m dn w-100-l w-100-m w-100  pv4-l pv4-m pv4">
          <div className="dt tc dt--fixed w-100-l w-100-m w-100">
            {/* tab and laptop */}
            <div className="cf">
              <div className=" dt w-100 tc black ">
                <div className="dtc v-top">
                  <div className="mb2 "></div>
                  <div
                    className="f2-l f2-m mb3-l mb3-m mb2"
                    style={{ fontFamily: "GothamLight", color: "#f3f3f3" }}
                  >
                    Doctor's <span>{"   "} </span>
                    <span
                      style={{
                        fontFamily: "GothamBlack",
                        color: "#f3f3f3",
                        fontWeight: 400,
                      }}
                    >
                      Feedback
                    </span>
                  </div>
                  <div
                    className="_doctorsQuote_ cf tc justify-center center-l center-m w-90-l w-90-m ba 
                    b--white-50 bw1-ns br2-ns"
                  >
                    <div className="mb2 justify-center center">
                      <div className="w-50-l w-50-m center-l center-m center v-mid pl2-l ml3">
                        <img
                          src={featuredDoctors[0].image}
                          alt={featuredDoctors[0].name}
                          className="mr--100 bn ma-auto"
                          style={{
                            height: "130px",
                            border: "none",
                            marginTop: "1rem",
                            width: "130px",
                            borderRadius: "50%",
                            boxSizing: "border-box",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                      <div className="w-100-l w-100-m w-100 mt3-ns">
                        <span
                          className="f3-l f3-m f3 white tc"
                          style={{
                            fontFamily: "GothamBlack",
                            textAlign: "center",
                          }}
                        >
                          {featuredDoctors[0].name}
                        </span>
                      </div>
                      <div className="center-l center-m w-80-l w-100-m w-100 tc mt3-ns white">
                        <span
                          className="f4-l f4-m f4 tc b"
                          style={{ fontFamily: "GothamBook" }}
                        >
                          {featuredDoctors[0].quote}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" dn-l dn-m dtc w-30-l w-30-m w-100 bg-black-10">
          <div className="dt-ns dt--fixed-ns tc white ">
            <div className="_mobileDocQuoteView_ w-100 tc v-mid"></div>
            <div className="_text_ tc justify-center mb4-l mb3-m mb0 pt3 pt0-l pt0-m">
              <span
                className="f3 "
                style={{ fontFamily: "GothamLight", color: "#f3f3f3" }}
              >
                Doctor's
              </span>
              <span>{"   "} </span>
              <span
                className="f3 "
                style={{
                  fontFamily: "GothamBlack",
                  color: "#f3f3f3",
                  fontWeight: 400,
                }}
              >
                Feedback
              </span>
            </div>
            <div
              className="_slider_ tc flex flex-column justify-center w-100 "
              style={{}}
            >
              <Slider {...settings} className="w-100">
                {featuredDoctors.map((doctor, index) => (
                  <>
                    <div
                      key={index}
                      className="justify-center"
                      style={{ height: "400px" }}
                    >
                      <div className="w-100 v-mid center">
                        <img
                          src={doctor.image}
                          alt={doctor.name}
                          className="mr--100 bn w-100 tc flex justify  center "
                          style={{
                            height: "130px",
                            border: "none",
                            width: "130px",
                            borderRadius: "50%",
                            boxSizing: "border-box",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                      <div className="w-100 mt3">
                        <span
                          className="f3 flex justify-center white tc "
                          style={{
                            fontFamily: "GothamBlack",
                            textAlign: "center",
                          }}
                        >
                          {doctor.name}
                        </span>
                      </div>
                      <div className="w-100 mt2 tc ">
                        <span
                          className=" w-100-l w-100-m flex tc mt3 white f6 b"
                          style={{
                            fontFamily: "GothamBook",
                            marginLeft: "10%",
                            marginRight: "10%",
                          }}
                        >
                          {doctor.quote}
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="_features_ dt dt--fixed w-100 db ">
        <article className="dt dt--fixed w-100 tc">
          <div className="fl dtc-l dtc-m dtc v-mid w-100 w-50-l w-50-m tc db">
            <img
              src={require("../resources/digitalRXoverview/laptopTyping.jpg")}
              alt="digital RX"
              className="w-100 db"
              style={{}}
            />
          </div>
          <div className="fl dtc-l dtc-m dtc v-mid w-100 w-50-l w-50-m tl">
            <img
              src={require("../resources/digitalRXoverview/featuresLeftAlign.jpg")}
              alt="digital RX"
              className="w-100 db"
              style={{}}
            />
          </div>
        </article>
      </section>

      <section className="_homePractice_ dt dt--fixed w-100 db pb2">
        <div className="img-holder">
          <img
            src={require("../resources/digitalRXoverview/RXhomePractice.jpg")}
            alt="Home Practice Features"
          />
        </div>
      </section>
    </div>
  );
}
