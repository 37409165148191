import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Toaster, Position, Button } from "@blueprintjs/core";
import { Store } from "../Store";

export const AppToaster = Toaster.create({
  position: Position.TOP,
  className: "mt5-ns mt5",
});

export function PaymentFailure() {
  let history = useHistory();
  const globalState = useContext(Store);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (globalState.state.signedIn === false) {
      if (localStorage.getItem("signedIn") === "true") {
        const { dispatch } = globalState;
        dispatch({ type: "SIGN_IN" });
        localStorage.setItem("signedIn", "true");
      } else {
        history.push("/signin");
      }
    }
    // eslint-disable-next-line
  }, [globalState]);

  return (
    <div className="dt w-100" style={{backgroundColor: "#f8f8f8", opacity: .875, height: "90vh"}} >
      <div className="dtc v-mid tc white ph3 ph4-l center-l center-m center">
        <span className="f2-l f2-m f4 f-subheadline-l fw6 tc red">Sorry, Your Payment wasn't Successful!</span>
        <br/>
        <span className="f5-l f4-m f5 "> 
          <Button
            className="white b f5"
            text="Try Again"
            intent="danger"
            style={{ backgroundColor: "#E93541", borderRadius: '8rem', border: "none" }}
            onClick={() => history.push('/confirmappointment')}
          />
        </span>
      </div>
    </div>
  );
}
