import {
  Button,
  FileInput, InputGroup, Spinner
} from "@blueprintjs/core";
import { validatePhoneNumber } from "@doctorkoi/utils";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as validation from "../common/validation";
import { emailValidation } from "../common/validation";
import { createCvForm, s3PutObject, updateCvForm } from "../graphql/mutations";
import { listCirculars, listCvForms } from "../graphql/queries";
import { fileValidation, randomUUID, showToast, toBase64 } from "../util";
import "./Career.css";

export type APPLICANT_CREATE_FORM = {
  pdf?: string;
  pdfType?: string;
  name?: string;
};

interface CVProps {
  id: string;
  name: string;
  phone: string;
  email: string;
  position: string;
  createdAt: string;
  updatedAt: string;
  info: any;
}
export function Career(props: CVProps) {
  let history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [position, setPostion] = useState<string>("");

  const env = localStorage.getItem("env");
  const [formData, setFormData] = useState<APPLICANT_CREATE_FORM>({});
  const [selectedFileName, setSelectedFileName] = useState<string | undefined>(
    undefined
  );
  const [errorMsg, setErrorMsg] = useState<any>({});
  const [uploadError, setUploadError] = useState<string | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(true);

  async function updateApplicantImage(params: { id: string; info: any }) {
    try {
      const result: any = await API.graphql(
        graphqlOperation(updateCvForm, {
          input: params,
        })
      );
      if (result.errors) {
        throw new Error(`error in mutation: ${JSON.stringify(result.errors)}`);
      }
      return result.data.updateCVForm;
    } catch (e) {
      // @ts-ignore
      throw new Error(`${JSON.stringify(e.errors[0].message)}`);
    }
  }
  const cv = async (cvData: CVProps) => {
    try {
      const result: any = await API.graphql(
        graphqlOperation(createCvForm, {
          input: cvData,
        })
      );
      return result.data.createCVForm;
    } catch (e) {
      throw new Error(`${JSON.stringify(e)}`);
    }
  };
  function formValidation() {
    setErrorMsg({
      name: validation.stringValidation(name) ? "" : "Name is Empty",
      email: validation.emailValidation(email as string)
        ? ""
        : "Email is Not Valid",
      phone: validation.emailValidation(phone as string)
        ? ""
        : "Phone Number is Not Valid",
    });
  }
  const processFile = async (file: any) => {
    if (file.size > 2 * 1024 * 1024) {
      alert("file size too big ");
      return;
    } else {
      let encodedFile: any = await toBase64(file);
      setUploadError(
        fileValidation({
          type: file.type,
          size: file.size,
        })
      );
      if (file.size > 2 * 1024 * 1024) {
        setUploadError(errorMsg);
        console.log(file.size);
        console.log("error msg", uploadError);
      }
      var regex = /^data:.+\/(.+);base64,(.*)$/;
      var matches = encodedFile.result.match(regex);
      var data = matches[2];
      setFormData({
        ...formData,
        pdf: data,
        pdfType: file.type,
        name: file.name,
      });
    }
  };

  function submitData() {
    if (
      validation.stringValidation(name) &&
      validation.emailValidation(email as string) &&
      validation.numberValidation(phone as string)
    ) {
      setLoading(true);
      cv({
        id: randomUUID(),
        name: name,
        email: email,
        phone: phone,
        position: position,
        info: JSON.stringify({ file: "data" }),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }).then((result: any) => {
        if (selectedFileName && !isEmpty(formData.pdf)) {
          let file = `public/cv/${phone}/${id}`;
          return (
            API.graphql(
              graphqlOperation(s3PutObject, {
                input: {
                  bucket:
                    env === "dev"
                      ? `doctorkoisitebucket151204-${env}`
                      : `doctorkoisitebucket63032-${env}`,
                  key: file,
                  body: formData.pdf,
                  type: formData.pdfType,
                },
              })
            ) as Promise<any>
          ).then(() => {
            let updateapplicantInfo: any = { };

            updateapplicantInfo.pdf =
              env === "dev"
                ? `http://doctorkoisitebucket151204-${env}.s3.amazonaws.com/${file}`
                : `http://doctorkoisitebucket63032-${env}.s3.amazonaws.com/${file}`;

            updateApplicantImage({
              id: result.id,
              info: JSON.stringify(updateapplicantInfo),
            })
              .then((res) => {
                showToast(
                  "Congratulations! Your application is successfully submitted.",
                  "tick-circle",
                  "success"
                );
                history.push("/careers");
                setLoading(false);
              })
              .catch((r) => {
                setLoading(false);
                showToast("Something wrong!", "tick-circle", "danger");
              });
          });
        }
      });
    } else {
      formValidation();
    }
  }

  const isDiasbled = () => {
    const cndtn =
      name.length &&
      phone.length &&
      emailValidation(email) &&
      validatePhoneNumber(phone) &&
      selectedFileName?.length
        ? false
        : true;
    return cndtn;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (
      API.graphql(
        graphqlOperation(listCirculars, {
          sortDirection: "DESC",
          limit: 1000,
        })
      ) as Promise<any>
    ).then((result) => {
      let CircularList = result.data.listCirculars.items;
      CircularList.forEach((circular: any) => {
        if (circular.id === id) {
          setPostion(circular.position);
        }
      });
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    (
      API.graphql(
        graphqlOperation(listCvForms, { limit: 1000 })
      ) as Promise<any>
    ).then((result) => {
      let applicantList = result.data.listCVForms.items;
      let cvlist: any = [];
      applicantList.forEach((applicant: any) => {
        cvlist.push(applicant);
      });
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Spinner size={Spinner.SIZE_LARGE} />
  ) : (
    <>
      <div className="dt flex justify-center bg-white pa4-ns mt4 items-center">
        {" "}
      </div>

      <div className="tc ttu red b mb3 f2-l f4-m f5 w-100">Submit Your CV</div>
      <div className="dt dt--fixed flex justify-center bg-white mt4 items-center pa2">
        <div className="dtc-l dn-m dn w-10-l w-10-m w-100 bg-red"></div>
        <div className="dtc-l dtc-m w-50-l w-50-m w-100 db-l db-m db ">
          <div className="cf items-center">
            <div className="cf flex items-center mb3">
              <div className="fl w-40 pt2 tl  f5">
                {" "}
                <span className="b">Position Applied </span>
              </div>
              <div className="fl w-50  tc">
                <div className="tc f5">{position}</div>
              </div>
            </div>
            <div className="fl w-40 pt2 tl  f5">
              {" "}
              <span className="b">Full Name </span>
              <span className=""> (required):</span>{" "}
              <span className="b red">*</span>
            </div>
            <div className="fl w-60  tl">
              <InputGroup
                disabled={false}
                large={false}
                fill={false}
                required={true}
                placeholder="Your Name..."
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                className="w-80-l w-100-m w-100 br4  mb3"
                style={{
                  borderRadius: "16px",
                  borderColor: "red",
                  borderWidth: "3px",
                  textAlign: "center",
                }}
              />
            </div>
          </div>
          <div className="cf items-center">
            <div className="fl w-40 pt2 tl  f5">
              {" "}
              <span className="b">Phone Number</span>
              <span className=""> (required):</span>{" "}
              <span className="b red">*</span>
            </div>
            <div className="fl w-60  tl">
              <InputGroup
                disabled={false}
                large={false}
                fill={false}
                required={true}
                type="number"
                placeholder="11 digit Phone Number..."
                value={phone}
                onChange={(e: any) => setPhone(e.target.value)}
                className="w-80-l w-100-m w-100 br4  mb3"
                style={{
                  borderRadius: "16px",
                  borderColor: "red",
                  borderWidth: "3px",
                  textAlign: "center",
                }}
              />
            </div>
          </div>
          <div className="cf items-center">
            <div className="fl w-40 pt2 tl  f5">
              {" "}
              <span className="b">Email Address</span>
              <span className=""> (required):</span>{" "}
              <span className="b red">*</span>
            </div>
            <div className="fl w-60  tl">
              <InputGroup
                disabled={false}
                large={false}
                fill={false}
                type="email"
                required={true}
                placeholder="Email Address..."
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                className="w-80-l w-100-m w-100 br4  mb3"
                style={{
                  borderRadius: "16px",
                  borderColor: "red",
                  borderWidth: "3px",
                  textAlign: "center",
                }}
              />
            </div>
          </div>
          <div className="cf  mb3">
            <div className="fl w-40 pt2 tl  f5">
              {" "}
              <span className="b">Attachment</span>
              <span className=""> (required):</span>{" "}
              <span className="b red">*</span>
            </div>
            <div className="fl w-50  tl mb4 light-gray  mt3 tc center">
              <FileInput
                text={
                  selectedFileName
                    ? selectedFileName
                    : "Choose file in word/pdf format..."
                }
                fill={true}
                inputProps={{
                  multiple: false,
                }}
                onInputChange={(e: any) => {
                  // loading={cvLoading}
                  // setCvLoading(true)
                  e.preventDefault();
                  if (e.target.files && e.target.files.length > 0) {
                    processFile(e.target.files[0]);
                    setErrorMsg({ errorMsg, pdf: "" });
                    setUploadError(undefined);
                    setSelectedFileName(
                      e.target.files[0].size > 2 * 1024 * 1024
                        ? selectedFileName
                        : e.target.files[0].name
                    );
                  }
                }}
              />
              <span className="f6 red">
                {" "}
                * File size must be less than 2MB & should be in word/pdf format
              </span>
              {uploadError || errorMsg.pdf ? (
                <div>
                  <div style={{ whiteSpace: "pre-wrap" }} className="dark-red">
                    {errorMsg.pdf}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="tc mb3-ns w-30-l w-80-m w-100 center-l center-m center">
            <Button
              onClick={(e: any) => {
                // FormData ? history.push("/career") : <Spinner size={Spinner.SIZE_SMALL} />;
                // reDirect();
                e.preventDefault();
                setLoading(true);
                submitData();
              }}
              className=" f5 f4-ns no-underline w-50-l w-50-m w-80 inline-flex items-center pa3-ns pa3 br3 ba border-box b white pointer"
              large={false}
              loading={loading}
              disabled={isDiasbled()}
              type="submit"
              fill={true}
              text="SUBMIT"
              intent="danger"
              style={{
                // backgroundColor: "#E93541",
                borderRadius: "16px",
                backgroundBlendMode: "multiply",
                color: "white",
                fontFamily: "Gotham",
                // height: "70px",
                // borderRadius: "8px",
                fontSize: "1.25rem",
                fontWeight: 700,
                //position: "sticky",
              }}
            />
          </div>
        </div>
        <div className="dtc-l dn-m dn w-40-l w-40-m ">
          <img
            src={require("../resources/career.jpg")}
            alt="career at Renssoft Solutions Ltd."
            className="pl3-m pl3-l pl0 pa3 "
          />
        </div>
      </div>
    </>
  );
}
