import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import { ButtonGroup, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MuiLayout from 'common/MuiLayout';
import { toString } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppointmentIcon,
  ExperienceIcon,
  LocationIcon,
  SpecializationIcon,
} from '../../common/SvgIcons';

export default function DoctorCard(props: any) {
  const doctor = props.doctor ? props.doctor : props;
  const {
    id,
    name,
    speciality,
    designation,
    image,
    department,
    consultationDay,
    institution,
    yearsOfExperience,
    specialization,
    chamber,
  } = doctor;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiLayout>
      <Grid
        item
        xs={12}
        sm={5.5}
        md={4}
        xl={3.8}
        sx={{
          // minHeight: '450px',
          // maxHeight: '400px',
          margin: '0 10px',
          // paddingX: '5'
        }}
        padding={1}
        paddingY={2}
      >
        <Typography
          variant="button"
          align="center"
          onClick={() => history.push(`/speciality=${speciality}`)}
          sx={{
            color: 'white',
            backgroundColor: '#E93541',
            padding: '2px auto',
            borderRadius: '5px 40px 0px 0px',
            display: 'block',
            width: {xs: '60%', sm: '160%'},
            fontWeight: 900,
          }}
        >
          {speciality || 'Speciality'}
        </Typography>
        <Card
          sx={{
            backgroundColor: '#F4F4F4',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px #f54c40',
            height: '290px',
            width: {xs: '340px', md: '400px'},
          }}
        >
          <Grid container spacing={2} className="pa2">
            <Grid item xs={5} marginLeft={2} marginRight={-2}>
              <Avatar
                className="slick-slider"
                alt="Doctor Image"
                src={image}
                variant="circular"
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                  marginBottom: -4,
                  border: '0px solid #E93541 !important',
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" spacing={1} alignItems="center">
                <PersonIcon sx={{color: '#f25449'}} />
                <Typography
                  marginLeft={1}
                  className="truncate  measure-narrow pl2 pl1-ns"
                  fontSize={16}
                  noWrap
                  fontWeight={900}
                  component="h4"
                >
                  {name || 'N/A'}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <WorkIcon sx={{color: '#f25449'}} />
                <Typography
                  fontSize={14}
                  marginLeft={1}
                  noWrap
                  fontWeight={900}
                  className="black-70truncate  measure-narrow pl2 pl1-ns"
                  mt={0.5}
                  variant="body1"
                  component="h6"
                >
                  {designation || 'N/A'}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <CorporateFareOutlinedIcon sx={{color: '#f25449'}} />
                <Typography
                  fontSize={13}
                  marginLeft={1}
                  noWrap
                  fontWeight={600}
                  className="black-60 truncate  measure-narrow pl2 pl1-ns"
                  mt={0.5}
                  variant="body1"
                  component="p"
                >
                  {department || 'N/A'}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <LocationIcon />
                <Typography
                  marginLeft={1}
                  fontSize={{xs: 12, sm: 13}}
                  className="black-60 truncate  measure-narrow db pl2 pl1-ns"
                  fontWeight={600}
                  mt={0.5}
                  variant="body1"
                  component="p"
                >
                  {institution || 'N/A'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <CardContent>
            <Stack justifyContent="center" marginTop={-2}>
              <Stack direction="row" spacing={1}>
                <SpecializationIcon />
                <Typography
                  fontSize={15}
                  fontWeight="bolder"
                  variant="body2"
                  component="p"
                >
                  Specialization
                </Typography>
              </Stack>
              <Typography
                gutterBottom
                fontSize={13}
                fontWeight={600}
                noWrap
                variant="body1"
                className="black-70"
                component="p"
                paddingLeft={3.5}
              >
                {specialization || 'N/A'}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Stack direction="row" sx={{width: '43%', overflow: 'hidden'}}>
                  <AppointmentIcon />
                  <Typography
                    fontSize={13}
                    sx={{
                      marginTop: '4px',
                    }}
                    variant="body1"
                    component="p"
                  >
                    {toString(
                      consultationDay
                        ?.map((day: string) =>
                          moment(day, 'dddd').format('ddd')
                        )
                        .join(', ')
                    ) || 'N/A'}
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{width: '57%', overflow: 'hidden'}}>
                  <ExperienceIcon />
                  <Typography
                    fontSize={13}
                    className="measure-narrow"
                    sx={{
                      marginTop: '4px',
                    }}
                    variant="body1"
                    component="p"
                  >
                    {yearsOfExperience
                      ? `${yearsOfExperience} years of experience`
                      : 'N/A'}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1}>
                <LocationIcon />
                <Typography
                  fontSize={13}
                  mt={0.5}
                  className="truncate measure-narrow"
                  sx={{
                    marginTop: '4px',
                  }}
                  variant="body1"
                  component="p"
                  lineHeight={2}
                >
                  {chamber && (chamber[0]?.hospital || 'N/A')}
                </Typography>
              </Stack>
            </Stack>
            <ButtonGroup sx={{float: 'right', pb: 0.5}}>
              <Button
                size="small"
                color="primary"
                onClick={() => history.push(`/doctor/${id}`)}
              >
                View Profile
              </Button>
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={() => history.push(`/appointment/${id}`)}
              >
                Book Appointment
              </Button>
            </ButtonGroup>
          </CardContent>
        </Card>
      </Grid>
    </MuiLayout>
  );
}
