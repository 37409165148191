import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { uniqBy } from 'lodash';
import * as React from 'react';
import { randomUUID } from '../../util';
import { useHistory } from 'react-router';
import { IDoctor } from 'model';
import MuiLayout from 'common/MuiLayout';
import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';

interface SearchDoctorComponentProps {
  doctorList: Array<IDoctor>;
}

interface IDoctorSearch {
  id: string;
  category: string;
  title: string;
}
const root = {
  fontSize: 10,
  '& :hover': {
    color: 'black',
    fontWeight: 'bold',
  },
  '& li': {
    //list item specific styling
    border: '2px solid red',
    borderRadius: 18,
    fontWeight: 'bold',
    color: 'black',
  },
};

export default function SearchDoctor(props: SearchDoctorComponentProps) {
  const history = useHistory();
  const doctors = props.doctorList?.map((doctor: IDoctor) => ({
    id: doctor.id,
    category: 'Doctors',
    title: doctor.name,
  }));
  const speciality = props.doctorList?.map((doctor: IDoctor) => ({
    id: randomUUID(),
    category: 'Speciality',
    title: doctor.speciality,
  }));
  const uniqueSpeciality = uniqBy(speciality, 'title');
  const specialityList: Array<string> = uniqueSpeciality?.map(
    (item: IDoctorSearch) => item.title
  );

  const searchHandle = (value: IDoctorSearch) => {
    const formatedLowerCaseSpeciality = specialityList?.map((item: string) =>
      item.toLowerCase()
    );
    return formatedLowerCaseSpeciality.includes(value.title.toLowerCase())
      ? history.push(`/speciality=${value.title}`)
      : history.push(`/doctor/${value.id}`);
  };
  const options = [...uniqueSpeciality, ...doctors].map((option: any) => {
    let firstLetter = '';
    if (option.category !== '') {
      firstLetter = option?.title?.toUpperCase();
    }
    return {
      firstLetter,
      ...option,
    };
  });

  return (
    <MuiLayout>
      <Grid container justifyContent='center' paddingY={{ xs: 1, ms: 2 }}>
        <Grid item xs={10.9} sm={7} xl={5} direction='row'>
          <Autocomplete
            id='grouped-demo'
            options={options.sort(
              (a: any, b: any) =>
                b.title?.toLowerCase() - a.title?.toLowerCase()
            )}
            groupBy={(option) => option.category}
            getOptionLabel={(option) => {
              return option.title;
            }}
            onChange={(_, value) => {
              _.preventDefault();
              if (value) {
                searchHandle(value);
              }
            }}
            noOptionsText='Nothing Found!!!!'
            sx={root}
            loading={props.doctorList ? false : true}
            loadingText={props.doctorList ? 'Loading...' : 'No Results Found'}
            popupIcon={<></>}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Search sx={{ marginRight: 0, color: '#EF5B5F' }} />
                    ),
                    placeholder: 'Search By Speciality or Doctor Name',
                    style: {
                      color: '#000000',
                      fontFamily: 'HindiSiliguriRegular',
                      background: '#f5f5f5',
                    },
                    sx: {
                      borderRadius: '12px',
                      height: '38px',
                    },
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </MuiLayout>
  );
}
