import { Button, NumericInput, Position, Radio, RadioGroup, Spinner, Toaster } from '@blueprintjs/core';
import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';
import { useHistory, useParams } from 'react-router-dom';
import a6 from '../../src/resources/a6.png';
import '../AppointmentPages/Appointment.css';
import { appointmentByDoctorId, getDoctor } from '../graphql/queries';
import { Store } from '../Store';
import { useCurrentDimensions } from '../useCurrentDimensions';
import { appointmentSerialID, randomUUID } from '../util';



interface DoctorItem {
  id: string;
  name: string;
  degrees: string;
  designation: string;
  image: string;
  totalSlot: string;
  shift: any;
  info?: any;
  speciality: string;
  year: string;
  month: string;
  day: string;
}

interface AppointmentProps {
  id: string;
  serialID: string;
  patientName: string;
  patientId: string;
  doctorId: string;
  doctorName: string;
  doctorUsername: string;
  dateTime: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  year: string;
  month: string;
  day: string;
  patientType: string;
}

export const AppToaster = Toaster.create({
  position: Position.TOP,
  className: "mt5-ns mt5"
});

const NumberRegex = /^\d+$/;
const numberValidation = (number: string) => {
  if (isEmpty(number) || !NumberRegex.test(number)) {
    return false
  } else {
    return true
  }
}


export function Appointment(props: AppointmentProps) {
  const patientType = ['New Patient', 'Old Patient', 'Report Patient'];
  const { id } = useParams<{id: string}>();
  let history = useHistory();
  const globalState = useContext(Store);
  const [doctor, setDoctor] = useState<DoctorItem | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<any>('');
  const [value, setValue] = useState<any>('');
  const [year, setYear] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [day, setDay] = useState<string>('');
  const [bookedSlot, setbookedSlot] = useState({ am: 0, pm: 0, time: [] });
  const [appointmentTime, setAppointmentTime] = useState<any>(null);
  const [confirmedAppointment, setConfirmedAppointment] = useState<any>([]);

  const isDiasbled = () => {
    const cndtn = appointmentTime && (year || month || day) && setValue.length ? false :
      !doctor?.info.slotDuration && selectedDate.length && (year || month || day) && setValue.length ? false : true;

    return cndtn;
  }

  const handleSlot = (visitHour: any, slotDuration: number) => {
    const slotNumber: any = [];
    let totalSlot2: number = 0;
    visitHour.forEach((visit: any) => {
      const f = new Date(visit.fromTime);
      const t = new Date(visit.toTime);
      const from = f.getHours() * 60 + f.getMinutes();
      const to = t.getHours() * 60 + t.getMinutes();
      const slot = (to - from) / slotDuration;
      slotNumber.push(slot);
      totalSlot2 += slot;
    })
    return [totalSlot2, slotNumber];
  }

  useEffect(() => {

    window.scrollTo(0, 0);
    checkSlot(selectedDate ? selectedDate : new Date());

    if (globalState.state.signedIn === false) {
      if (localStorage.getItem('signedIn') === 'true') {
        const { dispatch } = globalState;
        dispatch({ type: 'SIGN_IN' });
        localStorage.setItem('signedIn', 'true');

        (API.graphql(
          graphqlOperation(getDoctor, {
            id: id,
          })
        ) as Promise<any>).then(result => {
          let doctor = result.data.getDoctor;
          doctor.info = doctor.info ? JSON.parse(doctor.info) : doctor.info;
          return { doctor };
        }).then((res) => {
          const { doctor } = res;

          if (doctor.info.slotDuration) {
            handleSlot(doctor.info.visitHour, doctor.info.slotDuration).forEach((res: any, i: number) => {
              if (i === 0) {
                doctor.totalSlot = res
              } else {
                doctor.shift = {}
                res.forEach((a: number, i: number) => {
                  let allSlot = [];
                  for (let i = 1; i <= a; i++) {
                    allSlot.push(i);
                  }
                  doctor.shift[`shift${i + 1}`] = allSlot;

                })
              }
            })
          }

          return { doctor };
        }).then((res) => {
          const { doctor } = res;

          setDoctor(doctor);
        });
      } else {
        history.push('/signin');
      }
    } else {
      (API.graphql(
        graphqlOperation(getDoctor, {
          id: id,
        })
      ) as Promise<any>).then(result => {
        let doctor = result.data.getDoctor;
        doctor.info = doctor.info ? JSON.parse(doctor.info) : doctor.info;
        return { doctor };
      }).then((res) => {
        const { doctor } = res;
        if (doctor.info.slotDuration) {
          handleSlot(doctor.info.visitHour, doctor.info.slotDuration).forEach((res: any, i: number) => {
            if (i === 0) {
              doctor.totalSlot = res
            } else {
              doctor.shift = {}
              res.forEach((a: number, i: number) => {
                let allSlot = [];
                for (let i = 1; i <= a; i++) {
                  allSlot.push(i);
                }
                doctor.shift[`shift${i + 1}`] = allSlot;

              })
            }
          })
        }

        return { doctor };
      }).then((res) => {
        const { doctor } = res;
        setDoctor(doctor);
      });
    }
    // eslint-disable-next-line
  }, [globalState, id, selectedDate]);

  const { height, width } = useCurrentDimensions();
  const isMobileView = height < 900 && width < 500;

  const checkSlot = async (date: any) => {
    const appointments: any = await API.graphql(
      graphqlOperation(appointmentByDoctorId, {
        doctorId: id,
        dateTime: {
          beginsWith: moment(date).format('YYYY-MM-DD')
        },
        filter: {
          status: {
            ne: 'FULL_REFUND'
          }
        }
      }))
    const confirmedAppointments = appointments.data.appointmentByDoctorId.items;

    let am = 0, pm = 0, time: any = [];
    confirmedAppointments.map((a: any) => {

      if ((a.info && JSON.parse(a.info).time! && (moment(JSON.parse(a.info).time, 'h:mm A').format('A') === 'AM')) ||
        (a.info && JSON.parse(a.info).time! && (moment(JSON.parse(a.info).time, 'h:mm A').format('A') === 'PM'))
      ) {
        time.push(moment(JSON.parse(a.info).time, 'h:mm A').format('h:mm A'));
      }

      return a.info && JSON.parse(a.info).time! ? moment(JSON.parse(a.info).time, 'h:mm A').format('A') === 'AM' ? am++ : pm++ : '';

    })
    setConfirmedAppointment(confirmedAppointments);

    setbookedSlot({ am, pm, time });

    if (confirmedAppointment.length < doctor?.info.slot) {
      return true;
    }
    return false;
  }

  const consultDays = (days: string[]) => {
    let consultationDate = [];
    for (let i = 0; consultationDate.length < 6; i++) {
      let date = moment().add(i, 'days').toISOString();
      let day = moment(date).format('dddd');

      if (days.includes(day)) {
        consultationDate.push(date);
      }
    }

    return consultationDate;
  }

  return (
    <>
      <MetaTags>
        <meta name="description" content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, the best prescription writing software of the country that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally or the online consultation. 
                    DoctorKoi also provides the best online consultation services in Bangladesh for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. 
                    Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception RX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. 
                    During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online." />
        <meta property="og:title" content="DoctorKoi" />
        <meta property="og:image" content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg" />
      </MetaTags>
      {doctor ? (
        <div className="pa2 cf vh-100-ns" style={{ backgroundImage: `url(${a6})` }}>
          <header className="fn  tc">
            <h1 className="tc b  f3 mt2-ns mt-1 lh-title" style={{ color: "#3b3b3b", fontFamily: "GothamBold" }}>
              {' '}
              Book Appointment for {doctor.name}
            </h1>
          </header>

          <div className="flex items-center justify-center pa1 bg-white-80" >
            <div className="w-80 w-90-ns  bg-white-80" >
              <ul className="flex flex-row-ns flex-column center pl0" >
                {(doctor.info.consultationDay && doctor.info.consultationDay.length > 0) ?
                  consultDays(doctor.info.consultationDay).map((date: any, i: number) => (
                    <li
                      key={i}
                      className={date.substr(0, 10) !== selectedDate.substr(0, 10) ?
                        `f5 link pointer no-underline bg-white ba b--black-05 bg-animate
                        hover-bg-lightest-blue b hover-black inline-flex items-center pa3 mr2
                        mb1 bw1 br3 center-ns w-15-ns` :
                        `f5 link pointer no-underline bg-white ba b--black-05 bg-animate
                        bg-lightest-blue b hover-black inline-flex items-center pa3 mr2
                        mb1 bw1 br3 center-ns w-15-ns`}
                      onClick={() => {
                        setSelectedDate(date);
                        checkSlot(date)
                      }}
                    >
                      {moment(date).format('dddd, DD MMM')}
                    </li>
                  ))
                  :
                  [0, 1, 2, 3, 4, 5].map(i => moment().add(i, 'days')).map((date: any, i: number) => (
                    <li
                      key={i}
                      className={date.toISOString().substr(0, 10) !== selectedDate.substr(0, 10) ?
                        `f5 link pointer no-underline bg-white ba b--black-05 bg-animate
                      hover-bg-red  hover-black inline-flex items-center pa3 mr2
                      mb1 bw1 br3 center-ns w-15-ns` :
                        `f5 link pointer no-underline bg-white ba b--black-05 bg-animate
                      bg-red  hover-black inline-flex items-center pa3 mr2
                      mb1 bw1 br3 center-ns w-15-ns`}
                      style={{ fontFamily: "GothamBlack" }}

                      onClick={() => {
                        setSelectedDate(date.toISOString());
                        checkSlot(date);
                      }}
                    >
                      {date.format('dddd, DD MMM')}
                    </li>
                  ))}
              </ul>

              {/* single slot appointments start from here */}

              <ul className="flex flex-row-ns flex-column center pl0" >
                {(doctor.info.visitHour && doctor.info.slotDuration && !doctor.info?.showAllSlot) ?

                  doctor.shift && Object.keys(doctor.shift).map((shift: any, j: number) => {

                    const shiftTime = moment(doctor.info.visitHour[j].fromTime).format('A');
                    const totalSlot = shiftTime === 'AM' ?
                      doctor.shift[shift].slice(bookedSlot.am, doctor.info.visitHour[j].slot) :
                      doctor.shift[shift].slice(bookedSlot.pm, doctor.info.visitHour[j].slot);

                    let AvailableSlot = totalSlot.map((a: number, i: number) => {
                      return (moment(selectedDate, "YYYY-MM-DD").format("YYYY-MM-DD") === moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"))
                        && moment(doctor.info.visitHour[j].fromTime).add((a - 1) * doctor.info.slotDuration, 'm').format('HH:mm')
                        < moment(new Date()).format('HH:mm') ?
                        null : moment(doctor.info.visitHour[j].fromTime).add((a - 1) * doctor.info.slotDuration, 'm').format('h:mm A');

                    })
                    AvailableSlot = AvailableSlot.filter((d: any) => d);
                    return (
                      selectedDate ? <>
                        <article className="center mw5 mw5-ns hidden ba b--black-05 mv3 tc" style={{margin: '0 auto'}}>
                          <h1 className="f4 white mv0 pv2 ph3 br2" style={{ backgroundColor: 'red' }}>Available Slot(s)</h1>
                          <div className="pa3 ">
                            <p className="f6 f5-ns lh-copy measure mv3 b ">
                              {shiftTime === 'AM' ?
                                'Morning Slot : ' : 'AfterNoon Slot : '
                              }
                              {
                                (shiftTime === 'AM') && (totalSlot.length !== bookedSlot.am) && AvailableSlot.length ?
                                  AvailableSlot[0] :
                                  (shiftTime === 'PM') && (totalSlot.length !== bookedSlot.pm) && AvailableSlot.length ?
                                    AvailableSlot[0] :
                                    'No Available Slot'
                              }
                            </p>
                            <Button
                              className='f4 f4-ns lh-copy measure mv0 b'
                              disabled={appointmentTime === AvailableSlot[0]
                                || totalSlot.length < bookedSlot.am || totalSlot.length < bookedSlot.pm || !AvailableSlot.length ? true : false}
                              text={'Book Now'}
                              onClick={() =>
                                setAppointmentTime(
                                  shiftTime === 'AM' ?
                                    AvailableSlot[0] :
                                    AvailableSlot[0]
                                )
                              }
                            />
                          </div>
                        </article>
                        <br />
                      </>
                        : ''
                    )
                  })
                  : ''
                }
              </ul>
              {/* single slot appointments ends here */}

              {/* all slot appointments start from here */}
              <ul className="flex flex-column center pl0 pl4-ns" >
                {(doctor.info.visitHour && doctor.info?.slotDuration! && doctor.info?.showAllSlot) ?

                  doctor.shift && Object.keys(doctor.shift).map((shift: any, j: number) => {

                    const shiftTime = moment(doctor.info.visitHour[j].fromTime).format('A');
                    const totalSlot = doctor.shift[shift].slice(0, doctor.info.visitHour[j].slot);

                    let AvailableSlot = totalSlot.map((a: number, i: number) => {
                      return moment(selectedDate, "YYYY-MM-DD").format("YYYY-MM-DD") === moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
                        && moment(doctor.info.visitHour[j].fromTime).add(i * doctor.info.slotDuration, 'm').format('HH:mm')
                        < moment(new Date()).format('HH:mm')
                        ? null :
                        moment(doctor.info.visitHour[j].fromTime).format('A') === 'AM' ?
                          moment(doctor.info.visitHour[j].fromTime).add((i) * doctor.info.slotDuration, 'm').format('h:mm A') :
                          moment(doctor.info.visitHour[j].fromTime).add((i) * doctor.info.slotDuration, 'm').format('h:mm A');

                    })
                    AvailableSlot = AvailableSlot.filter((d: any) => d);
                    return (
                      selectedDate ?
                        <div className="flex-row-ns pa2">
                          <p className="f6 f5-ns lh-copy measure mv0 b">
                            {shiftTime === 'AM' ?
                              'Morning Slot : ' : 'AfterNoon Slot : '
                            }
                          </p>
                          {
                            AvailableSlot.length ? AvailableSlot.map((a: number, i: number) => {
                              return (
                                a &&
                                <Button
                                  key={i}
                                  className='f4 f4-ns  lh-copy measure mv0 b bg-animate pa2
                                  hover-bg-lightest-blue'
                                  disabled={
                                    (appointmentTime === a) || (bookedSlot.time.includes(a)) ? true : false}
                                  text={a}
                                  onClick={() =>
                                    setAppointmentTime(
                                      a
                                    )
                                  }
                                />
                              )
                            })
                              : 'No Avialiable Slot'
                          }
                          <br />
                        </div>
                        : ''

                    )
                  })
                  : ''
                }
              </ul>
              {/* all slot appointments start from here */}

            </div>
            <div>
            </div>
          </div>

          <div className="cf pa1 pa0-ns dt  w-90-l w-90-m w-100 mt0 tc-ns tl bg-white-80 mb2-ns mb0
            flex flex-row-ns flex-column pl0 pl4-ns center-l center-m
          ">
            <div className="dtc-ns f5 w-20-ns w-100 black pa3-ns pa2 f5 tl ml0-ns ml3"
              style={{ fontFamily: "HindiSiliguriBold" }}>
              রোগীর বয়স উল্লেখ করুন
            </div>
            <div className="dtc-ns f5 w-25-ns w-60 pa2 ml1 ml0-ns">
              <NumericInput
                placeholder="বছর...(required) "
                clampValueOnBlur={parseInt(year) > 123 || parseInt(year) < 0 ? true : false}
                value={year}
                required={true}
                onValueChange={(a: number, b: string) => {
                  if (numberValidation(b)) {
                    setYear(b)
                  }
                  else { setYear('') }
                }}
                intent="danger"
                min={0}
                max={123}
                large={true}
                className="w-60-ns w-100 center-ns tc mb0-ns mb2"
              />
            </div>
            <div className="dtc-ns f5 w-25-ns w-60 pa2 ml1 ml0-ns">
              <NumericInput
                placeholder="মাস... "
                value={month}
                clampValueOnBlur={parseInt(month) > 12 || parseInt(month) < 0 ? true : false}
                onValueChange={(a: number, b: string) => {
                  setMonth(b)
                }}
                intent="danger"
                min={0}
                max={12}

                large={true}
                className="w-60-ns w-100  center-ns tc mb0-ns mb2"
              />
            </div>
            <div className="dtc-ns f5 w-25-ns w-60 pa2 ml1 ml0-ns">
              <NumericInput
                placeholder="দিন... "
                clampValueOnBlur={parseInt(day) > 31 || parseInt(day) < 0 ? true : false}
                value={day}
                onValueChange={(a: number, b: string) => {
                  setDay(b)
                }}
                intent="danger"
                min={0}
                max={31}

                large={true}
                className="w-60-ns w-100 w2-ns center-ns tc mb0-ns mb2"
              />
            </div>
          </div>

          {/* Patient type block starts */}
          <div className="cf pa1 pa0-ns dt  w-90-l w-90-m w-100 mt0 tc-ns tl bg-white-80 mb2
            flex flex-row-ns flex-column pl0 center-l center-m
          ">
            <div className="dtc-ns w-100-l w-100-m w-60 tc pv4 pa3 pa1-ns">
              <div className="flex flex-row-l flex-row-m flex-column justify-center-ns tc-ns tl
                pa1 pa2-ns pl0 pl0-ns">
                <div className="f5 b pr4-ns pr0 mb3-ns mb1 ml0-ns ml2" style={{ color: "GothamBlack" }}>
                  Patient Type:
                </div>
                <span className="f5 mt0-ns mt3">
                  <RadioGroup
                    inline={isMobileView ? false : true}
                    onChange={(e: any) => setValue(e.currentTarget.value)}
                    name="patientType"
                    selectedValue={value}
                  >
                    {
                      patientType.map((type: string, index: number) => (
                        <Radio
                          key={index}
                          label={type}
                          value={type}
                        />
                      ))
                    }
                  </RadioGroup>
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-around mb4-ns mb4">
            <Button
              disabled={isDiasbled()}
              className=" f4 f4-ns w-40-m w-30-l w-80 no-underline bg-lightest-blue black bg-animate
              inline-flex items-center pa3 br3 ba border-box b mt1 pointer"
              large={true}
              text="Proceed to Confirm"
              intent="danger"
              style={{
                color: "white",
                borderRadius: "8px",
                fontSize: "1.25rem",
                fontWeight: 700,
                position: "sticky",
              }}
              onClick={() => {
                checkSlot(selectedDate).then(res => {
                  if (res === true) {
                    let name = localStorage.getItem('name');
                    let phone = localStorage.getItem('phone');
                    const bookAppointment = JSON.stringify({
                      info: ({
                        age: {
                          years: year,
                          months: month,
                          days: day
                        },
                        patientType: value as String,
                        time: appointmentTime,
                      }),
                      id: randomUUID(),
                      serialID: appointmentSerialID(),
                      patientName: name!,
                      patientId: phone!,
                      status: 'PENDING',
                      dateTime: selectedDate,
                      createdAt: new Date().toISOString(),
                      updatedAt: new Date().toISOString(),
                      doctorId: doctor!.id,
                      doctorName: doctor!.name,
                      doctorUsername: doctor!.info!.username!
                    })
                                       
                    history.push({
                      pathname: `/confirmappointment`,
                      state: {
                        doctor: doctor,
                        appointment: bookAppointment                      
                      },
                    });
                  } else {
                    AppToaster.show({
                      message: `No Available slot for this date!`,
                      intent: 'warning',
                      timeout: 5000
                    })
                  }
                })

              }}
            >
            </Button>
          </div>

        </div>
      ) : (
        <Spinner size={Spinner.SIZE_LARGE} />
      )
      }
    </>
  );
}
