/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      serialID
      patientId
      digitalRxPatientId
      patientName
      doctorId
      doctorName
      doctorUsername
      dateTime
      status
      info
      createdFrom
      createdAt
      updatedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serialID
        patientId
        digitalRxPatientId
        patientName
        doctorId
        doctorName
        doctorUsername
        dateTime
        status
        info
        createdFrom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      name
      speciality
      score
      status
      info
      file
      payment {
        appointmentId
        amount
        date
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        speciality
        score
        status
        info
        file
        payment {
          appointmentId
          amount
          date
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCircular = /* GraphQL */ `
  query GetCircular($id: ID!) {
    getCircular(id: $id) {
      id
      position
      deadline
      status
      jobDescription
      createdAt
      updatedAt
    }
  }
`;
export const listCirculars = /* GraphQL */ `
  query ListCirculars(
    $filter: ModelCircularFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCirculars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        position
        deadline
        status
        jobDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      code
      verified
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        verified
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegistrationForm = /* GraphQL */ `
  query GetRegistrationForm($id: ID!) {
    getRegistrationForm(id: $id) {
      id
      name
      phone
      bmdc
      info
      createdAt
      updatedAt
    }
  }
`;
export const listRegistrationForms = /* GraphQL */ `
  query ListRegistrationForms(
    $filter: ModelRegistrationFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrationForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phone
        bmdc
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCvForm = /* GraphQL */ `
  query GetCvForm($id: ID!) {
    getCVForm(id: $id) {
      id
      name
      phone
      email
      position
      info
      createdAt
      updatedAt
    }
  }
`;
export const listCvForms = /* GraphQL */ `
  query ListCvForms(
    $filter: ModelCVFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCVForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        email
        position
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPublicQuery = /* GraphQL */ `
  query GetPublicQuery($id: ID!) {
    getPublicQuery(id: $id) {
      id
      name
      phone
      email
      message
      info
      createdAt
      updatedAt
    }
  }
`;
export const listPublicQuerys = /* GraphQL */ `
  query ListPublicQuerys(
    $filter: ModelPublicQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicQuerys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        email
        message
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      transactionId
      appointmentId
      status
      info
      createdAt
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        transactionId
        appointmentId
        status
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentByCreatedAt = /* GraphQL */ `
  query AppointmentByCreatedAt(
    $status: AppointmentStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialID
        patientId
        digitalRxPatientId
        patientName
        doctorId
        doctorName
        doctorUsername
        dateTime
        status
        info
        createdFrom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentByDatetime = /* GraphQL */ `
  query AppointmentByDatetime(
    $status: AppointmentStatus
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentByDatetime(
      status: $status
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialID
        patientId
        digitalRxPatientId
        patientName
        doctorId
        doctorName
        doctorUsername
        dateTime
        status
        info
        createdFrom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentByPatienId = /* GraphQL */ `
  query AppointmentByPatienId(
    $patientId: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentByPatienId(
      patientId: $patientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialID
        patientId
        digitalRxPatientId
        patientName
        doctorId
        doctorName
        doctorUsername
        dateTime
        status
        info
        createdFrom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentByDoctorId = /* GraphQL */ `
  query AppointmentByDoctorId(
    $doctorId: String
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentByDoctorId(
      doctorId: $doctorId
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialID
        patientId
        digitalRxPatientId
        patientName
        doctorId
        doctorName
        doctorUsername
        dateTime
        status
        info
        createdFrom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentByDoctorUserName = /* GraphQL */ `
  query AppointmentByDoctorUserName(
    $doctorUsername: String
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentByDoctorUserName(
      doctorUsername: $doctorUsername
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialID
        patientId
        digitalRxPatientId
        patientName
        doctorId
        doctorName
        doctorUsername
        dateTime
        status
        info
        createdFrom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const doctorByStatus = /* GraphQL */ `
  query DoctorByStatus(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        speciality
        score
        status
        info
        file
        payment {
          appointmentId
          amount
          date
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const doctorBySpeciality = /* GraphQL */ `
  query DoctorBySpeciality(
    $speciality: String
    $score: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorBySpeciality(
      speciality: $speciality
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        speciality
        score
        status
        info
        file
        payment {
          appointmentId
          amount
          date
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const circularByPosition = /* GraphQL */ `
  query CircularByPosition(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCircularFilterInput
    $limit: Int
    $nextToken: String
  ) {
    circularByPosition(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        deadline
        status
        jobDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const registrationByCreatedAt = /* GraphQL */ `
  query RegistrationByCreatedAt(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phone
        bmdc
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cvByCreatedAt = /* GraphQL */ `
  query CvByCreatedAt(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelCVFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cvByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phone
        email
        position
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const publicQueryByCreatedAt = /* GraphQL */ `
  query PublicQueryByCreatedAt(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelPublicQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicQueryByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phone
        email
        message
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const publicQueryByPhone = /* GraphQL */ `
  query PublicQueryByPhone(
    $phone: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicQueryByPhone(
      phone: $phone
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phone
        email
        message
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionByTransactionId = /* GraphQL */ `
  query TransactionByTransactionId(
    $transactionId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionByTransactionId(
      transactionId: $transactionId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionId
        appointmentId
        status
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionByAppointmentId = /* GraphQL */ `
  query TransactionByAppointmentId(
    $appointmentId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionByAppointmentId(
      appointmentId: $appointmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionId
        appointmentId
        status
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
