import React, { useState } from "react";

import {
  FormGroup,
  InputGroup,
  Button,
  Toaster,
  Position,
} from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { graphqlOperation, API } from "aws-amplify";
import { createRegistrationForm } from "../graphql/mutations";
// @ts-ignore
import { MetaTags } from "react-meta-tags";
import "./RegisteredDoctorsMV.css";

export const AppToaster = Toaster.create({
  position: Position.TOP,
  className: "mt6-ns mt5 z-max",
});
interface RegisteredDocProps {
  name: string;
  phone: string;
  bmdc: string;
  createdAt: string;
  updatedAt: string;
}

export function DoctorRegistrationMV() {
  let history = useHistory();

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [bmdc, setBmdc] = useState<string>("");

  const registration = async (registrationData: RegisteredDocProps) => {
    try {
      const result: any = await API.graphql(
        graphqlOperation(createRegistrationForm, {
          input: registrationData,
        })
      );
      return result.data.getRegistration;
    } catch (e) {
      throw new Error(`${JSON.stringify(e)}`);
    }
  };

  return (
    <>
      <MetaTags>
        <meta
          name="description"
          content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, the best prescription writing software of the country that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally or the online consultation. 
                    DoctorKoi also provides the best online consultation services in Bangladesh for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. 
                    Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception RX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. 
                    During the pandemic DoctorKoi launched its online consultation service through which they have served more than 12000 patients online which is obviously an remarkable achievment for one of the best online consultation healthcare success in Bangladesh."
        />
        <meta property="og:title" content="DoctorKoi" />
        <meta
          property="og:image"
          content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg"
        />
      </MetaTags>
      <section className="_freeRegistration_ dt w-100 ">
        <div className="dtc v-mid tc white ph3 ph4-l">
          <div className="_text_ w-100 tc ">
            <span
              className="f4 black tc"
              style={{ color: "#3b3b3b", fontFamily: "GothamLight" }}
            >
              Join Thousands of{" "}
            </span>

            <span
              className="b f4 black tc"
              style={{
                fontFamily: "GothamBlack",
                color: "#3b3b3b",
                fontWeight: 200,
              }}
            >
              Digital Doctors
            </span>
          </div>

          <div className="mt2 _text_ w-100 ba b--red tc flex flex-column justify-center b--gray br3-ns">
            <div className="w-100 tc b mt3">
              <span
                className="f2-ns pa3-ns ttu "
                style={{ color: "#E93541", fontFamily: "GothamMedium" }}
              >
                free Registration
              </span>
            </div>
            <FormGroup className=" w-100 tc pa2">
              <div
                className=" tc justify-center flex flex-column mb3-ns"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  width: "90%",
                }}
              >
                <InputGroup
                  className="mb2 w-80-ns br4 mr3-ns justify-center flex"
                  disabled={false}
                  large={true}
                  placeholder="Your Name..."
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                  style={{
                    borderRadius: "16px",
                    borderColor: "red",
                    borderWidth: "3px",
                    textAlign: "center",
                  }}
                />
                <InputGroup
                  className="mb2 w-80-ns br4 mr3-ns justify-center flex"
                  disabled={false}
                  large={true}
                  placeholder="Mobile Number..."
                  type="text"
                  inputMode="numeric"
                  value={phone}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setPhone(numericValue);
                  }}
                  style={{
                    borderRadius: "16px",
                    borderColor: "red",
                    borderWidth: "3px",
                    textAlign: "center",
                  }}
                />
                <InputGroup
                  className="mb2 w-80-ns br4 mr3-ns justify-center flex"
                  disabled={false}
                  large={true}
                  placeholder="BMDC number..."
                  value={bmdc}
                  onChange={(e: any) => setBmdc(e.target.value)}
                  style={{
                    borderRadius: "16px",
                    borderColor: "red",
                    borderWidth: "3px",
                    textAlign: "center",
                  }}
                />
              </div>
              <div className="w-90 tc justify-center flex flex-column mb3-ns">
                <Button
                  disabled={
                    name.length >= 3 && phone.length > 10 ? false : true
                  }
                  onClick={() => {
                    registration({
                      name: name,
                      bmdc: bmdc,
                      phone: phone,
                      createdAt: new Date().toISOString(),
                      updatedAt: new Date().toISOString(),
                    }).then((result) => {
                      AppToaster.show({
                        message: "Registration successful",
                        timeout: 3000,
                        intent: "success",
                        icon: "tick-circle",
                      });
                    });
                    history.push("/");
                  }}
                  className="center f5 f4-ns no-underline  inline-flex 
                      items-center pa3-ns pa2 br3 ba border-box b white pointer"
                  large={true}
                  type="submit"
                  fill={true}
                  text="SUBMIT"
                  intent="danger"
                  style={{
                    borderRadius: "16px",
                    color: "white",
                    width: "100%",
                    fontFamily: "Gotham",
                    fontSize: "1.25rem",
                    fontWeight: 700,
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                />
              </div>
            </FormGroup>
          </div>
        </div>
      </section>

      {/* <section className="_registration_ w-100 justify-center mt4">
        <div className="_text_ w-100 tc mt6 justify-center">
            <span className="f4 black tc mt5 mb0" 
                style={{color: "#3b3b3b", fontFamily: "GothamLight"}}>
                Join Thousands of {' '}
            </span>
            
            <span className="b f4 black tc mt5 mb0" 
                style={{fontFamily: "GothamBlack", color: "#3b3b3b", fontWeight: 200}}>
                Digital Doctors
            </span> 
        </div>
        <div className="mt2 _text_ w-100 ba b--red tc flex flex-column justify-center b--gray br3-ns">
            <div className="w-100 tc b mt3">
                <span className="f2-ns pa3-ns ttu " 
                    style={{color: "#E93541", fontFamily: "GothamMedium"}}> 
                    free Registration
                </span>
            </div>
          <FormGroup className=" w-100 tc pa2">
            <div className=" tc justify-center flex flex-column mb3-ns"
                style={{ marginLeft: "20px", marginRight: "20px", width: "90%"}}
            >
              <InputGroup
                  className="mb2 w-80-ns br4 mr3-ns justify-center flex"
                  disabled={false}
                  large={true}
                  //leftIcon="new-person"
                  //onChange={this.handleFilterChange}
                  placeholder="Your Name..."
                  value={name}
                  onChange={ (e:any) => setName(e.target.value)}
                  style={{borderRadius: "16px", borderColor: "red", 
                      borderWidth: "3px", textAlign: "center"}}
              />
              <InputGroup
                  className="mb2 w-80-ns br4 mr3-ns justify-center flex"
                  disabled={false}
                  large={true}
                  //leftIcon="new-person"
                  //onChange={this.handleFilterChange}
                  placeholder="Mobile Number..."
                  value={phone}
                  onChange={(e:any) => {setPhone(e.target.value); setLoading(false)}}                                
                  style={{borderRadius: "16px", borderColor: "red", 
                      borderWidth: "3px", textAlign: "center"}}
              />
              <InputGroup
                  className="mb2 w-80-ns br4 mr3-ns justify-center flex"
                  disabled={false}
                  large={true}
                  //leftIcon="new-person"
                  //onChange={this.handleFilterChange}
                  placeholder="BMDC number..."
                  value={bmdc}
                  onChange={(e:any) => setBmdc(e.target.value)}
                  style={{borderRadius: "16px", borderColor: "red", 
                      borderWidth: "3px", textAlign: "center"}}
              />
            </div>
            <div className="w-90 tc justify-center flex flex-column mb3-ns">
              <Button
                  disabled={isDiasbled()}
                  onClick={() => {
                      setLoading(true);
                      registration({
                          name: name,
                          bmdc: bmdc,
                          phone: phone,
                          createdAt: new Date().toISOString(),
                          updatedAt: new Date().toISOString(),
                      }).then((result) => {
                          //console.log(result)
                          AppToaster.show({
                              message: 'Registration successfull',
                              timeout: 10000,
                              intent: 'success',
                              icon: 'tick-circle'
                          })
                      });
                  history.push('/');
                  }
                  }
                  className="center f5 f4-ns no-underline  inline-flex 
                      items-center pa3-ns pa2 br3 ba border-box b white pointer"
                  large={true}
                  type="submit"
                  fill={true}
                  text="SUBMIT"
                  intent="danger"
                  style={{
                      //backgroundColor: "#E93541",
                      borderRadius: "16px",
                      //backgroundBlendMode: "multiply",
                      color: "white",
                      width: "100%",
                      fontFamily: "Gotham",
                      // height: "70px",
                      // borderRadius: "8px",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                      marginLeft: "20px", 
                      marginRight: "20px"
                      //position: "sticky",
                  }}
              />
            </div>
          </FormGroup>
        </div>
      </section> */}
    </>
  );
}
