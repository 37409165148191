import React, {createContext, useReducer} from 'react';

const initialState:any = {
  signedIn: false
};

const Store = createContext(initialState);
const { Provider } = Store;

// @ts-ignore
const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state:any, action:any) => {
    switch(action.type) {
      case 'SIGN_IN': {
        const newState = {
          ...state,
          signedIn: true
        };// do something with the action
        return newState;
      }
      case 'SIGN_OUT': {
        const newState = {
          ...state,
          signedIn: false
        };// do something with the action
        return newState;
      }
      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { Store, StateProvider }