import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { Store } from '../Store';
import React from 'react';
import {
  Alert,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CallCenterIcon from '../assets/images/CallCenterIcon.png';
import Dklogo from '../assets/images/doctorkoi_logo.png';
import { Call, Close } from '@mui/icons-material';
import MedicineDeliveryIcon from '../assets/images/MedicineDelivery_icon.png';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme: Theme) => ({
  topToolBar: {
    backgroundColor: '#E93541',
    BoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: 10,
    minHeight: '30px !important',
    justifyContent: 'center',
  },
  listbox: {
    '& li': {
      fontFamily: 'HindiSiliguriRegular',
      borderRadius: 18,
      fontWeight: 'bold',
      color: 'black',
    },
  },
}));

export function Navbar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showMedicineDialog, setShowMedicineDialog] = useState(false);
  const globalState = useContext(Store);
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const profileMenuOpen = Boolean(profileAnchorEl);

  const profileMenuHandleClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const profileMenuHandleClose = () => {
    setProfileAnchorEl(null);
  };

  const [userName, setUserName] = useState<string | undefined>(
    localStorage.name
  );

  const handleButtonClick = () => {
    setShowSnackbar(true);
    setShowMedicineDialog(false);
  };

  const handleMedicineClick = () => {
    setShowMedicineDialog(true);
    setShowSnackbar(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const MedicineServiceDialog = () => {
    const phoneNumber = '09666777711';
    const handleClose = () => {
      setShowMedicineDialog(false);
    };
    const handleCall = () => {
      window.location.href = 'tel:09666777711';
    };

    return (
      <>
        <Dialog
          open={showMedicineDialog}
          keepMounted
          onClose={() => {}}
          PaperProps={{
            style: {
              backgroundColor: 'rgba(253, 253, 253, 0.842)',
              backdropFilter: 'blur(5px)',
              borderRadius: '13px',
            },
          }}
        >
          <DialogContent>
            <Stack justifyContent='center' alignItems='center'>
              <img src={MedicineDeliveryIcon} alt='cart' width='50%' />
              <Typography
                fontFamily='HindiSiliguriRegular'
                textAlign='center'
                fontWeight='400'
                fontSize={{ xs: '15px', sm: '21px' }}
              >
                To Order Medicine
                <br />
                Contact Our Helpline (9am - 8pm)&nbsp;
                <a
                  href={`tel:${phoneNumber}`}
                  style={{ color: 'black', fontWeight: 700 }}
                >
                  {phoneNumber}
                </a>
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(180deg, #239614 0%, #55b430 100%)',
                width: '70px',
                height: '30px',
                borderRadius: '13px',
              }}
              onClick={handleCall}
              startIcon={<Call sx={{ color: '#fff', marginRight: -1 }} />}
            >
              <Typography
                variant='h1'
                fontWeight='700'
                fontSize='14px'
                fontFamily='HindiSiliguriRegular'
                color='#fff'
              >
                Call
              </Typography>
            </Button>
            <Button
              sx={{
                background: 'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
                width: '70px',
                height: '30px',
                borderRadius: '13px',
              }}
              onClick={handleClose}
              startIcon={<Close sx={{ color: '#fff', marginRight: -1 }} />}
            >
              <Typography
                variant='h1'
                fontWeight='700'
                fontSize='14px'
                fontFamily='HindiSiliguriRegular'
                color='#fff'
              >
                Close
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const CustomSnackbar = ({ message }: { message: string }) => {
    const handleClose = () => {
      setShowSnackbar(false);
    };
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowSnackbar(false)}
          message={message}
        >
          <Alert
            variant='filled'
            severity='info'
            sx={{ width: '100%', fontWeight: 'bold' }}
            action={
              <IconButton size='small' color='inherit' onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </IconButton>
            }
          >
            <Typography fontFamily='HindiSiliguriRegular' letterSpacing={1}>
              {message}
            </Typography>
          </Alert>
        </Snackbar>
      </>
    );
  };

  useEffect(() => {
    if (localStorage.name) {
      setUserName(localStorage.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.name]);

  return (
    <>
      <Box sx={{ pb: 12 }}>
        <AppBar sx={{ backgroundColor: '#fff', mb: 15 }}>
          <Toolbar
            className={classes.topToolBar}
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          >
            <Stack direction='row' spacing={1} paddingRight={5}>
              <img src={CallCenterIcon} alt='none' width='24px' />
              <a style={{ color: 'white' }} href='tel:+8809666777711'>
                <Typography fontFamily='HindiSiliguriRegular' letterSpacing={1}>
                  09666777711
                </Typography>
              </a>
              <a style={{ color: 'white' }} href='tel:+8809666788988'>
                <Typography fontFamily='HindiSiliguriRegular' letterSpacing={1}>
                  09666788988
                </Typography>
              </a>
            </Stack>
            <Stack direction='row' spacing={1}>
              <EmailIcon />
              <a style={{ color: 'white' }} href='mailto:info@doctorkoi.com'>
                <Typography fontFamily='HindiSiliguriRegular' letterSpacing={1}>
                  info@Doctorkoi.com
                </Typography>
              </a>
            </Stack>
          </Toolbar>
          <Toolbar
            sx={{
              backgroundColor: '#ffffff',
              position: 'sticky',
              zIndex: 99,
              top: 10,
            }}
          >
            <Grid container justifyContent='flex-start'>
              <Grid item xs={12} sm={3} md={2}>
                <Button
                  sx={{
                    transition: 'background-color 0.2s ease-in-out',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                    },
                  }}
                  onClick={() => history.push(`/`)}
                >
                  <img src={Dklogo} alt='DoktorKoi Logo' width='100px' />
                </Button>
              </Grid>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Stack direction='row' spacing={3} paddingTop={0.3}>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={() => history.push(`/speciality=Medicine`)}
                  >
                    <Stack>
                      <Typography
                        color='#000000'
                        variant='button'
                        fontWeight='700'
                        fontSize='18px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        height='24px'
                      >
                        Doctors
                      </Typography>
                      <Typography
                        color='#000000'
                        fontWeight='700'
                        fontSize='10px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                      >
                        Book Appointments
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={handleMedicineClick}
                  >
                    <Stack>
                      <Typography
                        color='#000000'
                        variant='button'
                        fontWeight='700'
                        fontSize='18px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        height='24px'
                      >
                        Pharmacy
                      </Typography>
                      <Typography
                        color='#000000'
                        fontWeight='700'
                        fontSize='10px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                      >
                        Order Medicines
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={handleButtonClick}
                  >
                    <Stack>
                      <Typography
                        color='#000000'
                        variant='button'
                        fontWeight='700'
                        fontSize='18px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        height='24px'
                      >
                        Test & Checkups
                      </Typography>
                      <Typography
                        color='#000000'
                        fontSize='10px'
                        fontWeight='700'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                      >
                        Coming Soon
                      </Typography>
                    </Stack>
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              item
              md={1}
              paddingRight={3}
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              <Button
                sx={{
                  transition: 'background-color 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                  minWidth: '160px',
                }}
                onClick={() => history.push(`/forproviders`)}
              >
                <Typography
                  fontWeight='700'
                  fontFamily='HindiSiliguriRegular'
                  color='#000000'
                  fontSize='18px'
                  height='24px'
                  textTransform='capitalize'
                >
                  For Providers
                </Typography>
              </Button>
            </Grid>

            {globalState.state.signedIn ? (
              <>
                <Button
                  sx={{
                    transition: 'background-color 0.2s ease-in-out',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                    },
                    display: { xs: 'none', md: 'block' },
                  }}
                  aria-controls={profileMenuOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={profileMenuOpen ? 'true' : undefined}
                  onClick={profileMenuHandleClick}
                >
                  <Stack
                    direction='row'
                    justifyContent='center'
                    spacing={1}
                    alignItems='center'
                    padding={0.5}
                  >
                    <AccountCircleIcon sx={{ color: '#666666' }} />
                    <Typography
                      fontWeight='700'
                      fontFamily='HindiSiliguriRegular'
                      color='#000000'
                      fontSize='14px'
                    >
                      {userName}
                    </Typography>
                  </Stack>
                </Button>
                <Menu
                  anchorEl={profileAnchorEl}
                  open={profileMenuOpen}
                  onClose={profileMenuHandleClose}
                >
                  <MenuItem onClick={() => history.push('/appointmentlist')}>
                    <CalendarMonthIcon sx={{ paddingRight: 1 }} />
                    <Typography
                      fontWeight='700'
                      fontFamily='HindiSiliguriRegular'
                      color='#000000'
                      fontSize='14px'
                    >
                      Appointments
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      const { dispatch } = globalState;
                      dispatch({ type: 'SIGN_OUT' });
                      localStorage.removeItem('signedIn');
                      localStorage.removeItem('name');
                      localStorage.removeItem('phone');
                      localStorage.removeItem('Appointment');
                      localStorage.removeItem('doctor');
                    }}
                  >
                    <LogoutIcon sx={{ paddingRight: 1 }} />
                    <Typography
                      fontWeight='700'
                      fontFamily='HindiSiliguriRegular'
                      color='#000000'
                      fontSize='14px'
                    >
                      Sign Out
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                sx={{
                  background:
                    'linear-gradient(180deg, #EC1E24 0%, #F05D61 100%)',
                  width: '180px',
                  height: '35px',
                  borderRadius: '16px',
                  display: { xs: 'none', md: 'block' },
                }}
                onClick={() => history.push(`/signin`)}
              >
                <Typography
                  fontWeight='700'
                  fontFamily='HindiSiliguriRegular'
                  color='#fff'
                  fontSize='14px'
                >
                  Sign In or Sign Up
                </Typography>
              </Button>
            )}
            <IconButton
              onClick={handleDrawerOpen}
              size='large'
              edge='start'
              aria-label='menu'
              sx={{
                display: { xs: 'block', md: 'none' },
                paddingTop: 3,
              }}
            >
              <MenuOpenIcon
                sx={{
                  transform: 'rotateY(180deg)',
                  color: '#EC1E24',
                }}
              />
            </IconButton>
            <Drawer
              anchor='right'
              open={open}
              onClose={handleDrawerClose}
              PaperProps={{
                sx: {
                  backgroundColor: 'rgba(253, 253, 253, 0.829)',
                  backdropFilter: 'blur(10px)',
                },
              }}
            >
              <List sx={{ width: '180px', paddingTop: 5 }} dense>
                {globalState.state.signedIn && (
                  <>
                    <ListItem>
                      <Box pb={2}>
                        <Stack
                          direction='row'
                          justifyContent='center'
                          spacing={1}
                          alignItems='center'
                        >
                          <AccountCircleIcon
                            fontSize='large'
                            sx={{ color: '#666666' }}
                          />
                          <Typography
                            fontWeight='700'
                            fontFamily='HindiSiliguriRegular'
                            color='#000000'
                            fontSize='15px'
                            textTransform='uppercase'
                          >
                            {userName}
                          </Typography>
                        </Stack>
                      </Box>
                    </ListItem>
                    <Divider />
                  </>
                )}
                <ListItem>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={() => {
                      history.push(`/speciality=Medicine`);
                      handleDrawerClose();
                    }}
                  >
                    <Stack alignItems='flex-start'>
                      <Typography
                        color='#000000'
                        variant='button'
                        fontWeight='700'
                        fontSize='15px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        height='24px'
                      >
                        Doctors
                      </Typography>
                      <Typography
                        color='#000000'
                        fontWeight='700'
                        fontSize='10px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                      >
                        Book Appointments
                      </Typography>
                    </Stack>
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={() => {
                      handleMedicineClick();
                      handleDrawerClose();
                    }}
                  >
                    <Stack alignItems='flex-start'>
                      <Typography
                        color='#000000'
                        variant='button'
                        fontWeight='700'
                        fontSize='15px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        height='24px'
                      >
                        Pharmacy
                      </Typography>
                      <Typography
                        color='#000000'
                        fontWeight='700'
                        fontSize='10px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                      >
                        Order Medicines
                      </Typography>
                    </Stack>
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={() => {
                      handleButtonClick();
                      handleDrawerClose();
                    }}
                  >
                    <Stack alignItems='flex-start'>
                      <Typography
                        color='#000000'
                        variant='button'
                        fontWeight='700'
                        fontSize='15px'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                        height='24px'
                      >
                        Test & Checkups
                      </Typography>
                      <Typography
                        color='#000000'
                        fontSize='10px'
                        fontWeight='700'
                        fontFamily='HindiSiliguriRegular'
                        textTransform='capitalize'
                      >
                        Coming Soon
                      </Typography>
                    </Stack>
                  </Button>
                </ListItem>
                <Divider />
                <ListItem>
                  <Typography
                    color={grey[900]}
                    fontWeight='700'
                    fontSize='19px'
                    fontFamily='HindiSiliguriRegular'
                    textTransform='capitalize'
                    height='24px'
                    pl={1}
                  >
                    For Providers
                  </Typography>
                </ListItem>
                <ListItem>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={() => {
                      history.push(`/practicepro`);
                      handleDrawerClose();
                    }}
                  >
                    <Typography
                      color={grey[900]}
                      variant='button'
                      fontWeight='700'
                      fontSize='15px'
                      fontFamily='HindiSiliguriRegular'
                      textTransform='capitalize'
                      height='24px'
                    >
                      Practice Pro
                    </Typography>
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={() => {
                      history.push(`/digitalrx`);
                      handleDrawerClose();
                    }}
                  >
                    <Typography
                      color={grey[900]}
                      variant='button'
                      fontWeight='700'
                      fontSize='15px'
                      fontFamily='HindiSiliguriRegular'
                      textTransform='capitalize'
                      height='24px'
                    >
                      Digital Rx
                    </Typography>
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    sx={{
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    onClick={() => {
                      history.push(`/pricing`);
                      handleDrawerClose();
                    }}
                  >
                    <Typography
                      color={grey[900]}
                      variant='button'
                      fontWeight='700'
                      fontSize='15px'
                      fontFamily='HindiSiliguriRegular'
                      textTransform='capitalize'
                      height='24px'
                    >
                      Pricing
                    </Typography>
                  </Button>
                </ListItem>
                <Divider />
                {globalState.state.signedIn && (
                  <ListItem>
                    <Button
                      onClick={() => {
                        history.push('/appointmentlist');
                        handleDrawerClose();
                      }}
                    >
                      <CalendarMonthIcon
                        sx={{ paddingRight: 1, color: '#000' }}
                      />
                      <Typography
                        fontWeight='700'
                        fontFamily='HindiSiliguriRegular'
                        color='#000000'
                        fontSize='14px'
                        textTransform='capitalize'
                      >
                        Appointments
                      </Typography>
                    </Button>
                  </ListItem>
                )}
                <ListItem>
                  {globalState.state.signedIn ? (
                    <Button
                      onClick={() => {
                        const { dispatch } = globalState;
                        dispatch({ type: 'SIGN_OUT' });
                        localStorage.removeItem('signedIn');
                        localStorage.removeItem('name');
                        localStorage.removeItem('phone');
                        localStorage.removeItem('Appointment');
                        localStorage.removeItem('doctor');
                        handleDrawerClose();
                      }}
                    >
                      <LogoutIcon sx={{ paddingRight: 1, color: '#000' }} />
                      <Typography
                        fontWeight='700'
                        fontFamily='HindiSiliguriRegular'
                        color='#000000'
                        fontSize='14px'
                        textTransform='capitalize'
                      >
                        Sign Out
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        history.push(`/signin`);
                        handleDrawerClose();
                      }}
                    >
                      <LoginIcon sx={{ paddingRight: 1, color: '#000' }} />
                      <Typography
                        fontWeight='700'
                        fontFamily='HindiSiliguriRegular'
                        color='#000000'
                        fontSize='13px'
                        height='17px'
                        textTransform='capitalize'
                      >
                        Sign In / Sign Up
                      </Typography>
                    </Button>
                  )}
                </ListItem>
                <Divider />
                <ListItem>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Call sx={{ color: '#E93541' }} />
                    <Stack>
                      <a style={{ color: '#E93541' }} href='tel:+8809666777711'>
                        <Typography
                          fontFamily='HindiSiliguriRegular'
                          fontWeight='700'
                        >
                          09666777711
                        </Typography>
                      </a>
                      <a style={{ color: '#E93541' }} href='tel:+8809666788988'>
                        <Typography
                          fontFamily='HindiSiliguriRegular'
                          fontWeight='700'
                        >
                          09666788988
                        </Typography>
                      </a>
                    </Stack>
                  </Stack>
                </ListItem>
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
      </Box>
      {showSnackbar && <CustomSnackbar message='Service Is Coming Soon...' />}
      {showMedicineDialog && <MedicineServiceDialog />}
    </>
  );
}
