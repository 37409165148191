import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { createTransaction, createAppointment, updateAppointment } from "./../graphql/mutations";
import { Store } from "./../Store";
import { randomUUID } from './../util';
import { isEqual, isEmpty } from "lodash";

export function PaymentSuccess() {
  let history = useHistory();
  const globalState = useContext(Store);
  const saveAppointmentAndTransaction = async () => {
    const paymentDetails = localStorage.getItem("paymentDetails");
    const appointment = localStorage.getItem("Appointment");
    const payLaterAppointment = localStorage.getItem("payLaterAppointment");
    if (isEmpty(paymentDetails) === false && isEmpty(appointment) === false) {
      const parsedPaymenDetails = JSON.parse(paymentDetails!);
      const parsedAppointment = JSON.parse(appointment!);
      parsedAppointment.info = isEqual(typeof parsedAppointment.info, "string") ? JSON.parse(parsedAppointment.info)
        : parsedAppointment.info

      try {
         await API.graphql(
          graphqlOperation(createAppointment, {
            input: {
              ...parsedAppointment,
              status: "PENDING",
              info: JSON.stringify({
                ...parsedAppointment.info,
                fee: parsedPaymenDetails.amount,
                paymentFromSite: "Yes",
                paymentInfortmation: true,
                paymentComplete: true
              })
            }
          })
        );

        await API.graphql(
          graphqlOperation(createTransaction, {
            input: {
              id: randomUUID(),
              transactionId: parsedPaymenDetails.tran_id,
              appointmentId: parsedAppointment.id,
              status: "SUCCESS",
              info: JSON.stringify({
                paymentDetails: parsedPaymenDetails,
                appointmentInfo: parsedAppointment
              }),
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            }
          })
        );

        return;
      } catch (e) {
        throw new Error(`${JSON.stringify(e)}`);
      }
    } else if (isEmpty(paymentDetails) === false && isEmpty(payLaterAppointment) === false) {
      try {
        const parsedAppointment = JSON.parse(payLaterAppointment!);
        const parsedPaymenDetails = JSON.parse(paymentDetails!);
        parsedAppointment.info = isEqual(typeof parsedAppointment.info, "string") ? JSON.parse(parsedAppointment.info)
          : parsedAppointment.info

         await API.graphql(
          graphqlOperation(updateAppointment, {
            input: {
              id: parsedAppointment.id,
              status: "PENDING",
              info: JSON.stringify({
                ...parsedAppointment.info,
                paymentFromSite: "Yes",
                paymentInfortmation: true,
                fee: parsedPaymenDetails.amount,
              })
            }
          })
        );

       await API.graphql(
          graphqlOperation(createTransaction, {
            input: {
              id: randomUUID(),
              transactionId: parsedPaymenDetails.tran_id,
              appointmentId: parsedAppointment.id,
              status: "SUCCESS",
              info: JSON.stringify({
                paymentDetails: parsedPaymenDetails,
                appointmentInfo: parsedAppointment
              }),
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            }
          })
        );

        return;
      } catch (error) {
        throw new Error(`${JSON.stringify(error)}`);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (globalState.state.signedIn === false) {
      if (localStorage.getItem("signedIn") === "true") {
        const { dispatch } = globalState;
        dispatch({ type: "SIGN_IN" });
        localStorage.setItem("signedIn", "true");
      } else {
        history.push("/signin");
      }
    } else {
      saveAppointmentAndTransaction().then(res => {
        localStorage.removeItem("Appointment");
        localStorage.removeItem("paymentDetails");
        localStorage.removeItem("payLaterAppointment");
      });
    }
    // eslint-disable-next-line
  }, [globalState]);

  return (
    <div className="dt w-100" style={{backgroundColor: "#E93541", opacity: .875, height: "90vh"}} >
      <div className="dtc v-mid tc white ph3 ph4-l">
        <span className="f2-l f2-m f4 f-subheadline-l fw6 tc white">Congratulations, Your Payment is Successfully done!</span>
        <br/>
        <span className="f5-l f4-m f5">You can check your appointment details from
        <Link className="f4 b white" to='/appointmentlist' style={{ textDecoration: "none" }}> here</Link>
      </span>
      </div>
    </div>
  );
}