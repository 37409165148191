import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";


export function Google() { 
  // eslint-disable-next-line
  const [lat, setLat] = useState(0);
  // eslint-disable-next-line   
  const [long, setLong] = useState(0);    
  const [hidden, setHidden] = useState(true);


  useEffect(() => {        
    navigator.geolocation.getCurrentPosition((position) => {            
      setLat(position.coords.latitude)            
      setLong(position.coords.longitude)        
    })    
  }, [])

  const MapWithMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{lat: 23.744945, lng: 90.383993}}
    >
      <Marker
        draggable={true}
        position={{lat: 23.744945, lng: 90.383993}}
        onClick={ ()=> setHidden(!hidden)}
      >
      </Marker>
    </GoogleMap>  
  ))

  return (
    <div>
      
      <MapWithMarker
        // @ts-ignore 
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAcw_a17snQc7qDPycA3z538rihLJOeJHU&v=3.exp&libraries=geometry,drawing,places"
        // @ts-ignore
        loadingElement={<div style={{ height: `160%` }} />}                
        // @ts-ignore
        containerElement={<div style={{ height: `160px` }} />}                
        // @ts-ignore
        mapElement={<div style={{ height: `160%` }} />}
      />
    </div>
  )
}