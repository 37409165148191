/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://p6sk3jekijcmnl7a3hpn6xcv7m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-aenosqw5tfgzxgpll2tsfek6oa",
    "aws_cloud_logic_custom": [
        {
            "name": "doctorkoisiteRestAPI",
            "endpoint": "https://z25c1u45gh.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "sendSMSAPI",
            "endpoint": "https://umn6wmnfq6.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:3e927cd0-6ac3-42ec-b382-0c1db021dbbc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0ckvGVKoz",
    "aws_user_pools_web_client_id": "25f8146mpci06l4lemv1525324",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "doctorkoisitebucket63032-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
