import { Button, Checkbox, Position, Spinner, Toaster } from "@blueprintjs/core";
import axios from "axios";
import { isEmpty, isEqual } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BkashPolicy } from "../bKashPolicy/bKashPolicy";
import { Store } from "../Store";
import { randomUUID } from '../util';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';
interface AppointmentProps {
  id: string;
  serialID: string;
  patientName: string;
  patientId: string;
  doctorId: string;
  doctorName: string;
  doctorUsername: string;
  dateTime: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  info: any;
}

export const AppToaster = Toaster.create({
  position: Position.TOP,
  className: "mt5-ns mt5"
});

const SERVICE_CHARGE_DOCTORS = [
  "617dbd62-0c9e-419e-aba7-f85f53a2839f",
  "4f44fdd5-382f-49aa-9ab2-7aca2faf5a50",
  "f26a67cf-c828-41dd-b0a5-8255a67d3207",
  "b3bf534b-7e6e-481b-8cb5-87eacd6fde2c",
  "89ef189a-c81d-4dbd-a975-a48ba64903e3",
  "3bb0330e-dc1b-4bbf-bf0a-9c48a7208482",
  "0468a8eb-f24e-4928-bcfe-cf4b2d074030",
  "43397b81-f811-4fab-bcae-fcf8914ace41"
]

export function ConfirmAppointment(props: AppointmentProps) {
  let history = useHistory();
  const doctor = history.location.state ? history.location.state['doctor'] : null;
  const appointment = history.location.state ? history.location.state['appointment'] : null;
  const globalState = useContext(Store);
  const [loading, setLoading] = useState<boolean>(false);
  const [option, setOption] = useState<any>(false);
  const [appointmentDetails, setAppoinmentDetails] = useState<any>(undefined);
  const [fee, setFee] = useState<string | undefined>(undefined);
  const env = localStorage.getItem("env");


  const initiatePayment = async () => {
    try {
      const apiUrl = isEqual(env, "dev") ? `https://afhm3tjzu9.execute-api.us-east-1.amazonaws.com/dev/api/initiatepayment`
        : `https://z25c1u45gh.execute-api.us-east-1.amazonaws.com/prod/api/initiatepayment`
      const data = {
        totalAmount: fee!,
        tranID: randomUUID(),
        clientName: appointmentDetails.patientName,
        clientPhone: appointmentDetails.patientId,
      };

      const res = await axios.post(
        apiUrl,
        {
          bodyData: data,
          env: env,
          url: window.location.href
        }
      );
      return res;
    } catch (error) {
      throw new Error(`${JSON.stringify(error)}`);
    }
  };

  const validateOrder = async (valId: string) => {
    setLoading(true);
    const apiUrl = isEqual(env, "dev") ? `https://afhm3tjzu9.execute-api.us-east-1.amazonaws.com/dev/api/validateorder`
      : `https://z25c1u45gh.execute-api.us-east-1.amazonaws.com/prod/api/validateorder`
    const res = await axios.post(
      apiUrl,
      {
        bodyData: {
          valId: valId
        },
        env: env
      }
    );

    if (isEqual(res.data.data, "INVALID_TRANSACTION")) {
      history.push("/paymentfailure");
    } else {
      localStorage.setItem("paymentDetails", JSON.stringify(res.data.data));
      history.push("/paymentsuccess");
    }
  };

  const process = () => {
    let appointmentData = isEmpty(appointment) ? localStorage.getItem("payLaterAppointment") : appointment;
    if (isEmpty(appointmentData) === false) {
      const parsedAppointment: AppointmentProps = JSON.parse(appointmentData!);

      parsedAppointment.info = isEqual(typeof parsedAppointment.info, "string") ?
        JSON.parse(parsedAppointment.info) : parsedAppointment.info;
      const doctorInfo = doctor.info;
      const processingFee = parseInt(doctorInfo.processingFee);
      const serviceCharge = [
        parseInt(doctorInfo.serviceCharge),
        parseInt(doctorInfo.oldPatientServiceCharge),
        parseInt(doctorInfo.newPatientServiceCharge),
        parseInt(doctorInfo.reportShowPatientServiceCharge)
      ];
      let selectedServiceCharge = 0;
      const patientType = isEqual(
        parsedAppointment.info.patientType,
        "Report Patient"
      )
        ? "Report"
        : parsedAppointment.info.patientType;

      let visitFee = doctorInfo.visitFee.find((fee: any) => {
        return fee.type?.includes(patientType) ? fee.type.includes(patientType) : undefined;
      });
      visitFee = isEmpty(visitFee) ? doctorInfo.visitFee[0] : visitFee;

      if (isEqual(patientType, 'New Patient')) {
        selectedServiceCharge = serviceCharge[2] ? serviceCharge[2] : 0
      } else if (isEqual(patientType, 'Old Patient')) {
        selectedServiceCharge = serviceCharge[1] ? serviceCharge[1] : 0
      } else if (patientType.includes('Report')) {
        selectedServiceCharge = serviceCharge[3] ? serviceCharge[3] : 0
      } else {
        selectedServiceCharge = serviceCharge[0] ? serviceCharge[0] : 0
      }

      const vat = Math.round((processingFee + selectedServiceCharge) * 0.15);
      let totalFee = parseInt(isEmpty(visitFee.fee) ? 0 : visitFee.fee) + vat + processingFee;
      if (SERVICE_CHARGE_DOCTORS.includes(doctor.id)) {
        totalFee = totalFee + selectedServiceCharge;
      }
      localStorage.setItem('Appointment', JSON.stringify(parsedAppointment));
      setAppoinmentDetails(parsedAppointment);
      setFee(totalFee.toString());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const valId = params.get("valId");

    if (isEmpty(valId) === false) {
      if (isEqual(valId, "FAILED")) {
        history.push("/paymentfailure");
      } else if (isEqual(valId, "CANCEL")) {
        process();
      } else {
        validateOrder(valId!);
      }
    } else {
      if (globalState.state.signedIn === false) {
        if (localStorage.getItem("signedIn") === "true") {
          const { dispatch } = globalState;
          dispatch({ type: "SIGN_IN" });
          localStorage.setItem("signedIn", "true");
        } else {
          history.push("/signin");
          process();
        }
      } else {
        process();
      }
    }
    // eslint-disable-next-line
  }, [globalState, doctor]);

  return (
    <>
      <MetaTags>
        <meta name="description" content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, the best prescription writing software of the country that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally or the online consultation. 
                    DoctorKoi also provides the best online consultation services in Bangladesh for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. 
                    Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception RX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. 
                    During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online." />
        <meta property="og:title" content="DoctorKoi" />
        <meta property="og:image" content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg" />
      </MetaTags>
      {appointmentDetails ? (
        <div>
          <BkashPolicy />
          <div className=" cf bg-white-90">

            <div className="dt flex flex-row-ns flex-column-reverse bg-white-90 mt3-ns mt2">
              <div className="fl w-100 w-100-ns pl3 pl0-ns red db pa2 dtc-ns v-mid-ns center tc">
                <Checkbox
                  //label="dialysis report"
                  inline
                  value={option ? "true" : "false"}
                  onChange={(e: any) => {
                    setOption(isEqual(e.target.value, "true") ? false : true);
                  }}
                  large={true}
                />
                <span className=" b black f4">
                  I accept the above set of instructions.
                </span>
              </div>
            </div>
          </div>
          <div className="mb4 tc">
            <h2>Appointment Fee: {fee} BDT</h2>
          </div>
         <div className="mb4 pb2">
         <Button
            disabled={isEqual(loading, false) && isEqual(option, true) ? false : true}
            text="Pay now"
            className="pointer w-30 mb0-ns center "
            large={true}
            fill
            intent="danger"
            style={{
              // backgroundColor: "red",
              color: "white",
              // height: "55px",
              borderRadius: "8rem",
              fontSize: "1.25rem",
              fontWeight: 700,
              width: '200px',
              margin: '0px auto',
            }}
            onClick={async () => {
              const result = await initiatePayment();
              const { status, GatewayPageURL } = result.data.data;
              if (isEqual(status, "SUCCESS")) {
                window.location.assign(GatewayPageURL);
              } else {
                AppToaster.show({
                  message: `Payment Initiation Failed! Please try again.`,
                  icon: "cross",
                  intent: "danger",
                  timeout: 2000
                });
              }
            }}
          />
         </div>
        </div>
      ) : (
        <Spinner size={Spinner.SIZE_LARGE} />
      )}
    </>
  );
}
