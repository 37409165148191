import React, { useEffect, useState } from "react";
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { graphqlOperation, API } from "aws-amplify";
import { createRegistrationForm } from "../graphql/mutations";
import { useCurrentDimensions } from "../useCurrentDimensions";
import { AppToaster, DoctorRegistrationMV } from "./RegisteredDoctorsMV";

interface RegisteredDocProps {
  name: string;
  phone: string;
  bmdc: string;
  createdAt: string;
  updatedAt: string;
}

export function DoctorRegistration() {
  let history = useHistory();

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [bmdc, setBmdc] = useState<string>("");

  const registration = async (registrationData: RegisteredDocProps) => {
    try {
      const result: any = await API.graphql(
        graphqlOperation(createRegistrationForm, {
          input: registrationData,
        })
      );
      return result.data.getRegistration;
    } catch (e) {
      throw new Error(`${JSON.stringify(e)}`);
    }
  };

  const { height, width } = useCurrentDimensions();
  const isMobileView = height < 900 && width < 500;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isMobileView ? (
    <DoctorRegistrationMV />
  ) : (
    <>
      <div className="dt flex flex-column bg-white pa4-ns mt4">
        <div className="w-80-ns tc center-l center-m justify-center mb4-ns ">
          <span
            className="f2-ns black tc mt5-ns mb0-ns"
            style={{ color: "#3b3b3b", fontFamily: "GothamLight" }}
          >
            Join Thousands of{" "}
          </span>

          <span
            className="b f2-ns black tc mt5-ns mb0-ns"
            style={{
              fontFamily: "GothamBlack",
              color: "#3b3b3b",
              fontWeight: 200,
            }}
          >
            Digital Doctors
          </span>
        </div>
        <div
          className="ba flex-column b--gray br3-ns black w-80-ns flex justify-center tc 
                center-l center-m"
        >
          {/* form fillup */}
          <div className="w-80-ns tc mt3-ns center-l center-m justify-center mb4-ns pa3-ns">
            <span
              className="f2-ns pa3-ns ttu "
              style={{ color: "#E93541", fontFamily: "GothamMedium" }}
            >
              free Registration
            </span>
          </div>
          <FormGroup className=" w-100-ns tc flex flex-column center-l center-m mb4-ns pa3-ns">
            <div className="w-100-ns tc justify-center flex dib mb3-ns">
              <InputGroup
                className="w-50-ns br4-ns mr3-ns"
                disabled={false}
                large={true}
                required={true}
                placeholder="Your Name..."
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                style={{
                  borderRadius: "16px",
                  borderColor: "red",
                  borderWidth: "3px",
                  textAlign: "center",
                  height: "60px",
                }}
              />
              <InputGroup
                className="w-50-ns"
                disabled={false}
                large={true}
                required={true}
                type="text"
                inputMode="numeric"
                placeholder="Mobile Number..."
                value={phone}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  setPhone(numericValue);
                }}
                style={{
                  borderRadius: "16px",
                  borderColor: "red",
                  borderWidth: "3px",
                  textAlign: "center",
                  height: "60px",
                }}
              />
            </div>
            <div className="w-100-ns justify-center mb3-ns">
              <InputGroup
                className="w-80-ns justify-center"
                disabled={false}
                large={true}
                fill={true}
                placeholder="BMDC number"
                value={bmdc}
                onChange={(e: any) => setBmdc(e.target.value)}
                style={{
                  borderRadius: "16px",
                  borderColor: "red",
                  borderWidth: "3px",
                  textAlign: "center",
                  height: "60px",
                }}
              />
            </div>
            <div className="tc center-l center-m justify-center mb3-ns w-100-ns">
              <Button
                onClick={() => {
                  registration({
                    name: name,
                    bmdc: bmdc,
                    phone: phone,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                  }).then((result) => {
                    AppToaster.show({
                      message: "Registration successful",
                      timeout: 3000,
                      intent: "success",
                      icon: "tick-circle",
                    });
                  });
                  history.push("/");
                }}
                large={true}
                disabled={name.length >= 2 && phone.length > 10 ? false : true}
                type="submit"
                fill={true}
                text="SUBMIT"
                intent="danger"
                style={{
                  borderRadius: "16px",
                  borderColor: "white",
                  color: "white",
                  width: "100%",
                  fontFamily: "Gotham",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </>
  );
}
