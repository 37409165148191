import { Box, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { PracticeProOverview } from './PracticeProOverview';
import React, { useEffect } from 'react';
import { DigitalRxOverview } from './DigitalRxOverview';
import { Pricing } from './Pricing';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: { md: 6 } }}>{children}</Box>}
    </div>
  );
}

export function ForProviders() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            float: 'right',
            display: { xs: 'none', md: 'block' },
            pr: 2
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: grey[900],
              },
            }}
          >
            <Tab
              label={
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontFamily: 'HindiSiliguriRegular',
                    fontSize: '18px',
                    textTransform: 'capitalize',
                    color: grey[900],
                  }}
                >
                  Practice Pro
                </Typography>
              }
            />
            <Tab
              label={
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontFamily: 'HindiSiliguriRegular',
                    fontSize: '18px',
                    textTransform: 'capitalize',
                    color: grey[900],
                  }}
                >
                  Digital Rx
                </Typography>
              }
            />
            <Tab
              label={
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontFamily: 'HindiSiliguriRegular',
                    fontSize: '18px',
                    textTransform: 'capitalize',
                    color: grey[900],
                  }}
                >
                  Pricing
                </Typography>
              }
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PracticeProOverview />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DigitalRxOverview />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Pricing/>
        </TabPanel>
      </Box>
    </>
  );
}
