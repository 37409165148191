import React from 'react';
// @ts-ignore
import { MetaTags } from 'react-meta-tags';

export function ErrorPage() {
    return (
        <>
            <MetaTags>
                <meta name="description" content="“Doctor Koi” is a healthcare start-up founded in 2017 with a vision to digitize the health sector of Bangladesh. The company owns its flagship product Digital RX, the best prescription writing software of the country that enables doctors to write a prescription within just few clicks and also manage their medical practice digitally or the online consultation. 
                    DoctorKoi also provides the best online consultation services in Bangladesh for specialized and selective industry leading Doctors to the patients through its platform called Doctorkoi.com. Together, the company aims to digitize the communications between doctors & patient & impose technology where it’s needed the most, the entire Healthcare eco-system of Bangladesh. 
                    Digital RX has over 1250 clients with a 450 plus daily active users who are generating almost a thousand prescriptions per day, since its inception RX generated over 4 lacs prescriptions. Pharmaceutical giants like ESKAYEF, ACI, Healthcare, Incepta are also working with Doctor Koi to make this transformation happen. 
                    During the pandemic Doctor Koi launched its online consultation service through which they have served more than 12000 patients online." />
                <meta property="og:title" content="DoctorKoi" />
                <meta property="og:image" content="https://doctorkoi.com/doctorkoiWebsiteThumbnail.jpg" />
            </MetaTags>
            <div className="center tc bg-white-70 v-mid pv6">
                <div className="heading_ center tc"
                    style={{
                        color: "#E8545C",
                        fontSize: "1.5rem",
                    }}
                >
                    <p className="f1 b fw7">
                        404
                </p>
                </div>
                <div className="heading_ center tc"
                    style={{
                        color: "#356CAD",

                    }}
                >
                    <p className="f3 b">
                        Ooops! Page not found.
                </p>
                </div>
                <div className="heading_ center tc" style={{ color: "#356CAD" }}>
                    <p className="f5 b">
                        Sorry, but the page you're looking for is not found. Please make sure you've typed the correct URL.
                </p>
                </div>

            </div>
        </>
    )
}